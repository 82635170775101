import React from 'react';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { BASE_COLOR } from 'theme/variables';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import MenuItem from '@material-ui/core/MenuItem';
import FieldWrapper from './FieldWrapper';
import Text from '../Text';

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: BASE_COLOR.text.placeholder,
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export const SelectField = ({
  hint,
  label,
  name,
  value,
  disabled,
  onChange,
  options,
  error,
  onBlur,
  required,
  labelStyle,
  emptyMenu,
  emptyMenuText = 'All',
  emptyMenuDisabled,
  menuItemStyle = {},
  menuItemCustomStyle = {},
  labelTextColor,
  wrapperPadding,
  customHintIcon = false,
  customEmptyMenu = false,
  info,
  placeholder = null,
  ...props
}) => (
  <FieldWrapper
    hint={hint}
    required={required}
    label={label}
    name={name}
    error={error}
    styles={labelStyle}
    disabled={disabled}
    labelTextColor={labelTextColor}
    wrapperPadding={wrapperPadding}
    customHintIcon={customHintIcon}
    info={info}
  >
    <Select
      id={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      displayEmpty
      name={name}
      disabled={disabled}
      {...props}
      renderValue={value => {
        if (value === '' && emptyMenu) return emptyMenuText;
        if (placeholder && !emptyMenu && !value)
          return <Placeholder>{placeholder}</Placeholder>;

        const option = (options || []).find(item => {
          if (isNumber(get(item, 'label')) || isNumber(value))
            return parseInt(get(item, 'label'), 10) === parseInt(value, 10);
          return get(item, 'label') === value;
        });
        return get(option, 'description');
      }}
    >
      {emptyMenu && (
        <MenuItem key="" value="" disabled={emptyMenuDisabled}>
          {customEmptyMenu ? (
            <Text color="gridBox">{emptyMenuText}</Text>
          ) : (
            emptyMenuText
          )}
        </MenuItem>
      )}
      {options.map(eachOption => (
        <MenuItem
          key={eachOption.ID}
          value={
            eachOption.secondDescription
              ? {
                  label: eachOption.label,
                  secondDescription: eachOption.secondDescription,
                }
              : eachOption.label
          }
          disabled={eachOption.disabled}
          style={
            eachOption.secondDescription ? menuItemStyle : menuItemCustomStyle
          }
        >
          {eachOption.description}
          {eachOption.secondDescription && (
            <Text fontSize={14} color="label">
              {eachOption.secondDescription}
            </Text>
          )}
        </MenuItem>
      ))}
    </Select>
  </FieldWrapper>
);

export default SelectField;
