/* eslint-disable import/prefer-default-export */
import { get } from 'lodash';

import { LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS } from '../models/enrolmentPlanningModel';
import {
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_LOADING,
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_SUCCESS,
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_ERROR,
} from './actionTypes';
import { SkApolloRequest } from '../../utils/apolloUtils';

const getListEnrollmentPlanningRequestsLoading = () => ({
  type: GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_LOADING,
  value: {
    inProgress: true,
    error: null,
    data: [],
  },
});

const getListEnrollmentPlanningRequestsSuccess = data => ({
  type: GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_SUCCESS,
  value: {
    inProgress: false,
    data,
  },
});

const getListEnrollmentPlanningRequestsError = error => ({
  type: GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_ERROR,
  value: {
    inProgress: false,
    error,
  },
});

export const getListEnrollmentPlanningRequests = filter => async dispatch => {
  dispatch(getListEnrollmentPlanningRequestsLoading());
  try {
    const res = await SkApolloRequest({
      params: {
        query: LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS,
        variables: { filter },
      },
      type: 'query',
    });
    if (res.success) {
      const data = get(res, 'data.listEnrollmentPlanningChangeRequests');
      dispatch(getListEnrollmentPlanningRequestsSuccess(data));
    } else {
      dispatch(
        getListEnrollmentPlanningRequestsError(get(res, 'error.0.message'))
      );
    }
  } catch (ex) {
    dispatch(getListEnrollmentPlanningRequestsError(ex.message));
  }
};
