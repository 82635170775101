import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, filter } from 'lodash';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import { Divider, Grid } from '@material-ui/core';
import ExpansionPanel from 'components/common/ExpansionPanel';
import { withStyles, createStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import localStore from '../../../utils/localStorage';
import { constructFullName } from '../../../utils';
import { LOGIN } from '../../../routes/constants';
import AvatarWrapper, { ListWrapper, LogoutWraper } from './style';
import mobileNavigation from '../../../redux/actions/MobileNavigation';
import Text from '../Text';

const styleSheet = createStyles({
  paper: {
    height: '100%',
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  avatarDiv: {
    marginBottom: '20px',
  },
});

const MobileNavigation = ({
  userDetails,
  getLogo,
  theme,
  pathname,
  classes,
  getClassDecisionLabelVendor,
  goToLink,
  hasEpReportsAccess,
  hasEpVendorManagementAccess,
  hasEpChildPerformanceAccess,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const state = useSelector(state => state);
  const isOpenMobileNavigation = get(state, 'mobileNavigation.isOpen', '');
  const cdnUrl = get(state, 'cdnUrl');
  const userRole = get(userDetails, 'userRoleRelations.data[0].role');
  const isEnrichment = process.env.REACT_APP_ENRICHMENT_PLATFORM === 'true';
  let sidemenu = filter(
    get(state, 'sidemenu.data'),
    menu => menu.active && menu.path !== 'settings'
  );
  if (isEnrichment && !userRole.isStaff) {
    sidemenu = sidemenu.filter(menu => menu.path !== 'mydownloads');
  }
  if (!get(userDetails, 'isHQStaff')) {
    const homeMenu = sidemenu.find(item => item.path === 'overview');
    const index = sidemenu.indexOf(homeMenu);
    sidemenu.splice(index, 1);
  }
  const logoutUser = () => {
    localStore.setValue('userToken', null);
    localStore.setValue('selectedChild', null);
    localStore.setValue('themeData', null);
    localStore.setValue('schoolID', null);
    history.push(LOGIN);
    window.location.reload();
  };

  const menuItem = menuItem => {
    const isActivePath = pathname.includes(menuItem.path);
    const isEP = menuItem.label === 'Enrichment Programme';
    if (menuItem.submenu) {
      return (
        <ExpansionPanel
          fullWidth
          expansionPanelSummary={
            <Grid container alignItems="center">
              <Grid>
                <img
                  width="23"
                  alt=""
                  height="100%"
                  src={menuItem.icon}
                  style={{ marginRight: '10px' }}
                />
              </Grid>
              <Grid>
                <Text className="w3-text w3-left-align">{menuItem.label}</Text>
              </Grid>
            </Grid>
          }
          expansionPanelDetails={
            <ListWrapper subMenu className="s7t-menu-list expand">
              {menuItem.submenu.map(sub => {
                if (isEP) {
                  if (!hasEpReportsAccess && sub.label.includes('Reports')) {
                    return null;
                  }
                  if (
                    !hasEpVendorManagementAccess &&
                    sub.label.includes('Vendor Management')
                  ) {
                    return null;
                  }
                  if (
                    !hasEpChildPerformanceAccess &&
                    sub.label.includes('Child Performance')
                  ) {
                    return null;
                  }
                  if (
                    get(userRole, 'label') === 'HQ' &&
                    menuItem.label.includes('Users')
                  ) {
                    return null;
                  }
                  if (
                    get(userRole, 'label') === 'HQ' &&
                    menuItem.label.includes('Class Decisions')
                  ) {
                    return null;
                  }
                }
                return (
                  <ListItem
                    key={sub.label}
                    onClick={() => {
                      history.push(`/${sub.path}`);
                      dispatch(mobileNavigation(false));
                    }}
                    alignItems="center"
                    className="s7t-menu-item"
                    button
                    style={{
                      color: isActivePath && theme.palette.primary.main,
                    }}
                  >
                    <span className="w3-text w3-left-align">
                      {getClassDecisionLabelVendor(sub.label, sub.path)}
                    </span>
                  </ListItem>
                );
              })}
            </ListWrapper>
          }
          summaryRootPadding="0 16px"
        />
      );
    }
    return (
      <ListItem
        key={menuItem.label}
        onClick={() => {
          goToLink(menuItem.path);
          dispatch(mobileNavigation(false));
        }}
        alignItems="center"
        className="s7t-menu-item"
        button
        style={{ color: isActivePath && theme.palette.primary.main }}
      >
        <img
          width="23"
          alt=""
          src={menuItem.icon}
          className="s7t-side-menu-img-in-link"
        />
        <span className="w3-text w3-left-align">{menuItem.label}</span>
      </ListItem>
    );
  };
  const username = `${constructFullName(
    userDetails.firstname,
    userDetails.lastname
  ).toUpperCase()}`;

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.paper }}
      open={isOpenMobileNavigation}
      onClose={() => dispatch(mobileNavigation(false))}
    >
      <Grid item>
        <div style={{ padding: '10px' }}>
          <img width="100" alt="" src={getLogo()} />
          <span
            onClick={() => dispatch(mobileNavigation(false))}
            style={{ float: 'right' }}
          >
            <CloseIcon />
          </span>
        </div>
        <div className="s7t-sidemenu">
          <ListWrapper
            className="s7t-menu-list expand"
            style={{ width: '100%' }}
          >
            <div
              className={`s7t-display-flex s7t-center-items ${classes.avatarDiv}`}
            >
              <AvatarWrapper
                src={`${cdnUrl}/ChildProfile/profile_pic_default.png`}
                alt={username}
              />
              <Text
                smallTitle
                bold
                style={{ color: theme.palette.primary.main }}
              >
                {username}
              </Text>
            </div>
            <Divider />
            {sidemenu.map(item => menuItem(item))}
          </ListWrapper>
        </div>
      </Grid>
      <LogoutWraper>
        <Divider />
        <div
          className="s7t-text-align w3-padding-16"
          style={{ color: theme.palette.primary.main }}
          onClick={() => logoutUser()}
        >
          <span style={{ verticalAlign: 'sub' }}>Log out</span>
        </div>
      </LogoutWraper>
    </Drawer>
  );
};

export default withStyles(styleSheet)(MobileNavigation);
