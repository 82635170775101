/* eslint-disable no-bitwise */
import { AbilityBuilder, Ability } from '@casl/ability';
import get from 'lodash/get';
import {
  READ_ACCESS,
  WRITE_ACCESS,
  APPROVE_ACCESS,
  VERIFYING_ROLE_OPTIONS,
  VENDOR_ROLE_OPTIONS,
  HQ_SUPER_ADMIN_ROLE_OPTIONS,
  SUPER_ADMIN_HQ_FINANCE_ROLE_OPTIONS,
  SUPER_ADMIN_ROLE,
  HQ_ROLE,
  ADMIN_ROLE,
  VENDOR_ADMIN,
  VENDOR_TRAINER,
  PRINCIPAL_ROLE,
} from '../constants';

// eslint-disable-next-line import/prefer-default-export
export const defineAbilityFor = role => {
  const { rules, can } = AbilityBuilder.extract();

  const ruleAccess = get(role, 'roleTemplates.data');
  const roleLabel = role.label;
  const canReadAccess = [];
  const canWriteAccess = [];
  const canApproveAccess = [];
  if (get(ruleAccess, 'length') && !get(rules, 'length')) {
    ruleAccess.forEach(rule => {
      if (rule.access & READ_ACCESS) {
        canReadAccess.push(rule.fkModuleCategory);
      }
      if (rule.access & WRITE_ACCESS) {
        canWriteAccess.push(rule.fkModuleCategory);
      }
      if (rule.access & APPROVE_ACCESS) {
        canApproveAccess.push(rule.fkModuleCategory);
      }
    });
    const verifyAttendanceAccess = VERIFYING_ROLE_OPTIONS.includes(roleLabel)
      ? roleLabel
      : '';
    const markingAttendanceAccess = VENDOR_ROLE_OPTIONS.includes(roleLabel)
      ? roleLabel
      : '';
    const unverifyingAttendanceAccess = HQ_SUPER_ADMIN_ROLE_OPTIONS.includes(
      roleLabel
    )
      ? roleLabel
      : '';
    const viewVendorClassManagement = VENDOR_ROLE_OPTIONS.includes(roleLabel)
      ? roleLabel
      : '';
    const viewReportsSection = SUPER_ADMIN_HQ_FINANCE_ROLE_OPTIONS.includes(
      roleLabel
    )
      ? roleLabel
      : '';
    const viewVendorManagementSection = [SUPER_ADMIN_ROLE, HQ_ROLE].includes(
      roleLabel
    )
      ? roleLabel
      : '';

    const viewEnrichmentChildPerformanceSection = [
      SUPER_ADMIN_ROLE,
      HQ_ROLE,
      ADMIN_ROLE,
      VENDOR_ADMIN,
      VENDOR_TRAINER,
      PRINCIPAL_ROLE,
    ].includes(roleLabel)
      ? roleLabel
      : '';

    can('read', canReadAccess);
    can('write', canWriteAccess);
    can('approve', canApproveAccess);
    can('viewVendorClassManagement', viewVendorClassManagement);
    can('canVerifyAttendance', verifyAttendanceAccess);
    can('canMarkAttendance', markingAttendanceAccess);
    can('canUnverifyAttendance', unverifyingAttendanceAccess);
    can('viewReportsSection', viewReportsSection);
    can('viewVendorManagementSection', viewVendorManagementSection);
    can(
      'viewEnrichmentChildPerformanceSection',
      viewEnrichmentChildPerformanceSection
    );
    return new Ability(rules);
  }
};
