import { get, cloneDeep } from 'lodash';
import moment from 'moment';
import {
  FETCH_ALL_PURCHASE_ITEMS,
  FETCH_ALL_INVOICE_BY_FKCHILD,
  FETCH_CHILD_BALANCE,
  FETCH_CHILD_BANK_ACCOUNTS,
  FETCH_ALL_BANKS,
  FETCH_ALL_INVOICES_FOR_ALL_CENTRES,
  FETCH_FINANCE,
  FETCH_ALL_REFUND,
  FETCH_ALL_UPLOADED_SUBSIDY_FILES,
  FETCH_ALL_DOWNLOADED_GIRO_FILES,
  FETCH_PAYMENT_TYPES,
  BANK_ACC_STATUS_CHANGE_REASON_BY_STATUS,
  EMPTY_REASONS_GIRO,
  ALL_CUSTOM_SUBSIDY_TYPE,
  ACTIVE_SUBSIDIES,
  SUBSIDY_DEBIT_AMOUNT,
  FETCH_ADVANCE_PAYMENT_RECEIPTS,
  ALL_ABSENT_CHILD_VOID_SUBSIDY,
  FETCH_SEARCH_INVOICE,
  FETCH_CHILD_REFUNDS,
  FETCH_CHILD_BALANCE_IN_CENTRE,
  FETCH_ENROLLED_CENTRES,
  FETCH_ADVANCE_PAYMENT_ITEM_AMOUNT,
  FETCH_CHILD_OUTSTANDING_AMOUNT_LOADING,
  FETCH_CHILD_OUTSTANDING_AMOUNT_SUCCESS,
  FETCH_CHILD_OUTSTANDING_AMOUNT_ERROR,
  CLEAR_CHILD_OUTSTANDING_AMOUNT,
  FETCH_ADVANCE_PAYMENT_RECEIPTS_FOR_REGISTRATION,
  FETCH_CHILD_FINANCIAL_ASSISTANCE_STATUS,
  FETCH_STATEMENT_OF_ACCOUNT_LOADING,
  FETCH_STATEMENT_OF_ACCOUNT_SUCCESS,
  FETCH_STATEMENT_OF_ACCOUNT_ERROR,
  FETCH_HISTORY_SUBSIDIES_LOADING,
  FETCH_HISTORY_SUBSIDIES_SUCCESS,
  FETCH_HISTORY_SUBSIDIES_ERROR,
  FETCH_ALL_BULK_INVOICES_LOADING,
  FETCH_ALL_BULK_INVOICES_SUCCESS,
  FETCH_ALL_BULK_INVOICES_ERROR,
  FETCH_DEBT_WRITE_OFF_LOADING,
  FETCH_DEBT_WRITE_OFF_SUCCESS,
  FETCH_DEBT_WRITE_OFF_ERROR,
  FETCH_ALL_PGM_BILLING_LOADING,
  FETCH_ALL_PGM_BILLING_SUCCESS,
  FETCH_ALL_PGM_BILLING_ERROR,
  FETCH_CREDIT_DEBIT_NOTE_LOADING,
  FETCH_CREDIT_DEBIT_NOTE_SUCCESS,
  FETCH_CREDIT_DEBIT_NOTE_ERROR,
  FETCH_ALL_REG_CHILD_BALANCE_LOADING,
  FETCH_ALL_REG_CHILD_BALANCE_SUCCESS,
  FETCH_ALL_REG_CHILD_BALANCE_ERROR,
  FETCH_ALL_REG_CHILD_OUTSTANDING_LOADING,
  FETCH_ALL_REG_CHILD_OUTSTANDING_SUCCESS,
  FETCH_ALL_REG_CHILD_OUTSTANDING_ERROR,
  FETCH_ALL_REG_CHILD_REFUNDS_LOADING,
  FETCH_ALL_REG_CHILD_REFUNDS_SUCCESS,
  FETCH_ALL_REG_CHILD_REFUNDS_ERROR,
  FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_LOADING,
  FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_SUCCESS,
  FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_ERROR,
  FETCH_ADJUST_INVOICE_AMT_LOADING,
  FETCH_ADJUST_INVOICE_AMT_SUCCESS,
  FETCH_ADJUST_INVOICE_AMT_ERROR,
  FETCH_ALL_INVOICES_BY_CHILD,
  FETCH_SUBSIDIES_LOADING,
  FETCH_SUBSIDIES_SUCCESS,
  FETCH_SUBSIDIES_ERROR,
  FETCH_TRADE_DEBT_NOTIFICATION_LOADING,
  FETCH_TRADE_DEBT_NOTIFICATION_SUCCESS,
  FETCH_TRADE_DEBT_NOTIFICATION_ERROR,
  FETCH_TAX_RATE_AND_AMOUNT,
  CLEAR_TAX_RATE_AND_AMOUNT,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};
/**
 *
 * Example data
 *
 * {
 *  "data": {
 *    "findAllPurchaseItem": {
 *      "totalCount": 43,
 *      "data": [
 *        {
 *
 *          "ID": 51,
 *          "label": "Sling Bag",
 *          "unitPrice": 15,
 *          "taxable": true
 *        },
 *      ]
 *    }
 *  }
 * }
 *
 */

export const fetchAllPurchaseItems = (state = {}, action) => {
  if (action.type === FETCH_ALL_PURCHASE_ITEMS) {
    const data = get(action, 'value.data.data.findAllPurchaseItem.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example Data
 *
 * {
 *   "data": {
 *     "getChildCurrentBalance": {
 *       "CDABalance": 0,
 *       "nonCDABalance": 0
 *     }
 *   }
 * }
 */

export const fetchChildBalance = (state = {}, action) => {
  if (action.type === FETCH_CHILD_BALANCE) {
    const data = get(action, 'value.data.data.childBalance');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example Data
 *
 * {
 *   "data": {
 *     "getChildCurrentBalanceInCentre": {
 *       "CDABalance": 0,
 *       "nonCDABalance": 0
 *     }
 *   }
 * }
 *
 */

export const fetchChildBalanceInCentre = (state = {}, action) => {
  if (action.type === FETCH_CHILD_BALANCE_IN_CENTRE) {
    const data = get(action, 'value.data.getChildCurrentBalanceInCentre');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example Data
 *
 * {
 *   "data": {
 *     "getChildEnrolledCentreList": {
 *       "data": [
 *         {
 *           ID: 89,
 *           label: "schoolname"
 *         }
 *       ]
 *     }
 *   }
 * }
 */

export const allEnrolledCentres = (state = {}, action) => {
  if (action.type === FETCH_ENROLLED_CENTRES) {
    const data = get(action, 'value.data.data.fetchAllEnrolledCentres');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example Data
 *
 * {
 *   "data": {
 *     "invoicesByFkChild": {
 *       "totalCount": 0,
 *       "data": [
 *     {
          "ID": 8139,
          "fkChild": 8,
          "invoiceNo": "INV-TC1-1703-000121",
          "totalTaxAmount": 7,
          "totalAmount": 100,
          "outstandingAmount": 0,
          "grandTotal": 107,
          "label": "Trial Period Fee for March 2017",
          "status": "completed",
          "invoiceType": "trial_period",
          "pdfUrl": "",
          "createdAt": "2017-03-22 10:31:55",
          "invoiceItems": {
            "totalCount": 1,
            "data": [
              {
                "totalAmount": 107,
                "displayTotalAmount": "$107.00",
                "quantity": 1,
                "taxAmount": 7,
                "billableItem": {
                  "ID": 66,
                  "label": "Registration Fees (Trial)",
                  "unitPrice": 100,
                  "taxable": true
                }
              }
            ]
          },
          "receipts": {
            "totalCount": 1,
            "data": [
              {
                "ID": 8255,
                "receiptNo": "OR-TC1-1703-500034",
                "amount": 107,
                "amountReceived": 107,
                "createdAt": "2017-03-22 10:32:19",
                "paymentType": "cash",
                "pdfUrl": "",
                "documentNo": "",
                "cancelationReason": "",
                "status": "approved",
                "remarks": null,
                "creditAmountUsed": 0,
                "cancelledDate": null
              }
            ]
          },
          "creditNotes": {
            "totalCount": 0,
            "data": []
          },
          "debitNotes": {
            "totalCount": 0,
            "data": []
          },
          "invoiceVoidNotes": {
            "totalCount": 0,
            "data": []
          }
        }]
 *     }
 *   }
 * }
 */

export const fetchAllInvoicesByFkChild = (state = {}, action) => {
  if (action.type === FETCH_ALL_INVOICE_BY_FKCHILD) {
    const data = get(action, 'value.data.data.allInvoiceByFkChild.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const allInvoicesByChild = (state = {}, action) => {
  if (action.type === FETCH_ALL_INVOICES_BY_CHILD) {
    const data = get(action, 'value.data.data.allInvoicesByChild.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 * Example Data
 *
 * {
 *  "data": {
 *    "bankAccountInfoByIDChild": [
 *      {
 *        "BankAccount": {
 *          "ID": 725,
 *          "childName": null,
 *          "childBirthCertificate": null,
 *          "child": {
 *            "firstname": "",
 *            "lastname": "Alson Lim Yu Feng(Lin Yufeng)",
 *            "birthCertificate": "T1528672A"
 *          },
 *          "payerAccountNumber": "690356597001",
 *          "payerAccountName": "Alson Lim Yu Feng(Lin Yufeng)",
 *          "bankBicCode": {
 *            "bankName": "OVERSEA-CHINESE BANKING CORPN LTD",
 *            "ID": 27
 *          },
 *          "source": "offline",
 *          "fkBankBicCode": 27,
 *          "status": "approved",
 *          "remarks": null,
 *          "billReferenceNumber": "T1528672A-CDA",
 *          "isCda": true,
 *          "effectiveFrom": "2017-04-10 00:00:00",
 *          "documentTypes": {
 *            "data": [
 *              {
 *                "ID": 725,
 *                "label": null,
 *                "documentTypeConfig": {
 *                  "ID": 101
 *                },
 *                "documentTags": {
 *                  "data": []
 *                }
 *              }
 *            ]
 *          }
 *        },
 *        "GiroApplication": {
 *          "BankAccountNumber": "690356597001",
 *          "IsCDA": true,
 *          "NameOfAccountHolders": [
 *            "Alson Lim Yu Feng(Lin Yufeng)"
 *          ],
 *          "MobilePhoneNumber": "91548355",
 *          "MobilePhoneCountryCode": "+65",
 *          "ApplicationDate": "2017-04-10 00:00:00"
 *        }
 *      },
 *      {
 *        "BankAccount": {
 *          "ID": 788,
 *          "childName": null,
 *          "childBirthCertificate": null,
 *          "child": {
 *            "firstname": "",
 *            "lastname": "Alson Lim Yu Feng(Lin Yufeng)",
 *            "birthCertificate": "T1528672A"
 *          },
 *          "payerAccountNumber": "063404381",
 *          "payerAccountName": "Tan Wei Nee (Chen Weini)",
 *          "bankBicCode": {
 *            "bankName": "DBS BANK LTD/POSB",
 *            "ID": 34
 *          },
 *          "source": "offline",
 *          "fkBankBicCode": 34,
 *          "status": "approved",
 *          "remarks": null,
 *          "billReferenceNumber": "T1528672A",
 *          "isCda": false,
 *          "effectiveFrom": "2017-11-23 00:00:00",
 *          "documentTypes": {
 *            "data": [
 *              {
 *                "ID": 788,
 *                "label": null,
 *                "documentTypeConfig": {
 *                  "ID": 101
 *                },
 *                "documentTags": {
 *                  "data": []
 *                }
 *              }
 *            ]
 *          }
 *        },
 *        "GiroApplication": {
 *          "BankAccountNumber": "063404381",
 *          "IsCDA": false,
 *          "NameOfAccountHolders": [
 *            "Tan Wei Nee (Chen Weini)"
 *          ],
 *          "MobilePhoneNumber": "91548355",
 *          "MobilePhoneCountryCode": "+65",
 *          "ApplicationDate": "2017-11-23 00:00:00"
 *        }
 *      }
 *    ]
 *  }
 *}
 */

export const childBankAccounts = (state = {}, action) => {
  if (action.type === FETCH_CHILD_BANK_ACCOUNTS) {
    const data = get(action, 'value.data.bankAccountInfoByIDChild');
    let newState = cloneDeep(state);
    newState = { ...action.value };
    if (data) {
      newState = { ...newState, data };
    }

    return newState;
  }

  return state;
};

/**
 *
 * Example Data
 *
 * {
 *   "data": {
 *     "findAllConsolidatedRefund": {
 *       "totalCount": 1006,
 *       "data": [
 *         {
 *           "ID": 2114,
 *           "date": "2020-11-26 01:47:31",
 *           "refundCompletionDate": "2020-11-26 01:47:32",
 *           "label": "CN-TC2-2011-000005",
 *           "IDChild": 44,
 *           "childFirstname": "",
 *           "childLastname": "Leow Shin Yue ((Liao Xinyue)",
 *           "associatedItems": [
 *             "INV-TC2-1708-000135"
 *           ],
 *           "amount": 205.44,
 *           "type": "Credit Note",
 *           "baseType": "credit_debit_note",
 *           "status": "Complete"
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const fetchAllInvoicesWithRefund = (state = {}, action) => {
  if (action.type === FETCH_ALL_REFUND) {
    const data = get(action, 'value.data.data.allInvoicesWithRefund.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example Data
 *
 * {
 *   "data": {
 *     "findAllBankBicCode": {
 *       "data": [
 *         {
 *           "ID": 7,
 *           "bankCode": "8527",
 *           "bankName": "SKANDINAVISKA ENSKILDA BANKEN AB",
 *           "bicCode": "ESSESGSGXXX",
 *           "displayOrder": 8
 *         }
 *       ]
 *     }
 *   }
 * }
 */

export const banksList = (state = {}, action) => {
  if (action.type === FETCH_ALL_BANKS) {
    const data = get(action, 'value.data.findAllBankBicCode.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example Data
 *
 * {
 *   "data": {
 *     "searchFinance": {
 *       "data": [
 *         {
 *           "ID": 14,
 *           "type": "invoice",
 *           "label": "INV-TC1-1407-000355",
 *           "score": 0.00004015275408164598,
 *           "linkedInvoiceID": [
 *             14
 *           ]
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const searchFinance = (state = {}, action) => {
  if (action.type === FETCH_FINANCE) {
    const data = get(action, 'value.data.data.invoiceSearchResult.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

/**
 *
 * Example Data
 *
 *  {
 *    "data": {
 *      "searchInvoice": {
 *        "data": [
 *          {
 *            "ID": 21597,
 *            "invoiceNo": "INV-TC2-2011-000036",
 *            "label": "Sling Bag for November 2020"
 *          },
 *        ]
 *      }
 *    }
 *  }
 */

export const searchInvoice = (state = {}, action) => {
  if (action.type === FETCH_SEARCH_INVOICE) {
    const data = get(action, 'value.data.data.invoiceSearchResult.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const fetchAllInvoicesForAllCenters = (state = {}, action) => {
  if (action.type === FETCH_ALL_INVOICES_FOR_ALL_CENTRES) {
    const data = get(action, 'value.data.data.allInvoicesForAllCenters.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const allRefundsForChild = (state = {}, action) => {
  if (action.type === FETCH_CHILD_REFUNDS) {
    const data = get(action, 'value.data.data.getRefundChildBalance.data');
    if (data) {
      return Object.assign({}, state, {
        [action.key]: {
          ...action.value,
          data,
        },
      });
    }

    return Object.assign({}, state, { [action.key]: action.value });
  }

  return state;
};

export const fetchAllUploadedSubsidyFiles = (state = {}, action) => {
  if (action.type === FETCH_ALL_UPLOADED_SUBSIDY_FILES) {
    const data = get(action, 'value.data.findAllUploadedSubsidyFiles');
    if (data) {
      const mappedToMonthData = data.data.reduce((arr, item) => {
        const date = moment(item.createdAt).format('MMM YYYY');
        if (!arr[date]) arr[date] = [];

        arr[date].push(item);
        return arr;
      }, {});

      return Object.assign({}, state, {
        ...action.value,
        data,
        mappedToMonthData,
      });
    }

    return Object.assign({}, state, action.value);
  }

  return state;
};

export const allDownloadedGiroFiles = (state = {}, action) => {
  if (action.type === FETCH_ALL_DOWNLOADED_GIRO_FILES) {
    const data = get(action, 'value.data.findAllDownloadedGiroFiles');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }

    return Object.assign({}, state, action.value);
  }
  return state;
};

export const taxRateAndAmountDetails = (state = {}, action) => {
  if (action.type === FETCH_TAX_RATE_AND_AMOUNT) {
    const data = get(action, 'value.data.getTaxRateAndAmount');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  if (action.type === CLEAR_TAX_RATE_AND_AMOUNT) {
    return Object.assign({}, state, {
      data: {},
    });
  }
  return state;
};

export const allPaymentTypes = (state = {}, action) => {
  if (action.type === FETCH_PAYMENT_TYPES) {
    const data = get(action, 'value.data.getOfflinePaymentModes.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const allReasonsForStatusChange = (state = {}, action) => {
  if (action.type === BANK_ACC_STATUS_CHANGE_REASON_BY_STATUS) {
    const data = get(
      action,
      'value.data.getBankAccountStatusChangeReasonsByStatus.data'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  if (action.type === EMPTY_REASONS_GIRO) {
    return {
      ...action.value,
    };
  }
  return state;
};

export const allAdvancePaymentReceipts = (state = {}, action) => {
  if (action.type === FETCH_ADVANCE_PAYMENT_RECEIPTS) {
    const data = get(action, 'value.data.getAdvancePaymentReceipts');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const allAdvancePaymentReceiptsForRegistration = (
  state = {},
  action
) => {
  if (action.type === FETCH_ADVANCE_PAYMENT_RECEIPTS_FOR_REGISTRATION) {
    const data = get(
      action,
      'value.data.getAdvancePaymentReceiptsForRegistration'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const allCustomSubsidyTypes = (state = {}, action) => {
  if (action.type === ALL_CUSTOM_SUBSIDY_TYPE) {
    const data = get(action, 'value.data.findAllCustomSubsidies.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const allActiveSubsidies = (state = {}, action) => {
  if (action.type === ACTIVE_SUBSIDIES) {
    const data = get(action, 'value.data.findAllChildActiveSubsidies');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const subsidyDebitAmount = (state = {}, action) => {
  if (action.type === SUBSIDY_DEBIT_AMOUNT) {
    const data = get(action, 'value.data');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const absentChildVoidSubsidy = (state = {}, action) => {
  if (action.type === ALL_ABSENT_CHILD_VOID_SUBSIDY) {
    const data = get(
      action,
      'value.data.findAllAbsentForVoidingSubsidyByChild'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const allAdvancePaymentItemAmount = (state = {}, action) => {
  if (action.type === FETCH_ADVANCE_PAYMENT_ITEM_AMOUNT) {
    const data = get(
      action,
      'value.data.getAdvancePaymentItemAmountForRegistration'
    );
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const childOutstandingAmt = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_CHILD_OUTSTANDING_AMOUNT_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_CHILD_OUTSTANDING_AMOUNT_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getChildOutStanding'),
      };
    case FETCH_CHILD_OUTSTANDING_AMOUNT_ERROR:
      return {
        ...state,
        ...action.value,
      };
    case CLEAR_CHILD_OUTSTANDING_AMOUNT:
      return {
        ...DEFAULT_STATE,
      };
    default:
      return state;
  }
};

export const fetchChildFinancialAssistanceStatus = (state = {}, action) => {
  if (action.type === FETCH_CHILD_FINANCIAL_ASSISTANCE_STATUS) {
    const data = get(action, 'value.data.getChildFinancialAssistanceStatus');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const statementOfAccount = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_STATEMENT_OF_ACCOUNT_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_STATEMENT_OF_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getChildStatementOfAccount'),
      };
    case FETCH_STATEMENT_OF_ACCOUNT_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allHistorySubsidies = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_HISTORY_SUBSIDIES_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_HISTORY_SUBSIDIES_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(
          action,
          'value.data.findAllChildHistorySubsidiesForBillingAdjustment'
        ),
      };
    case FETCH_HISTORY_SUBSIDIES_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allBulkInvoices = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_BULK_INVOICES_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ALL_BULK_INVOICES_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.listBulkInvoiceRequest'),
      };
    case FETCH_ALL_BULK_INVOICES_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const debtWriteOffReasons = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_DEBT_WRITE_OFF_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_DEBT_WRITE_OFF_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getDebtWriteOffReasonCodes'),
      };
    case FETCH_DEBT_WRITE_OFF_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allProgramBillings = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_PGM_BILLING_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ALL_PGM_BILLING_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.findAllProgramBillingUpload'),
      };
    case FETCH_ALL_PGM_BILLING_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allCreditDebitNotes = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_CREDIT_DEBIT_NOTE_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_CREDIT_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.findAllCreditDebitNotes'),
      };
    case FETCH_CREDIT_DEBIT_NOTE_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allRegChildBalance = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_REG_CHILD_BALANCE_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ALL_REG_CHILD_BALANCE_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getRegistrationChildBalance'),
      };
    case FETCH_ALL_REG_CHILD_BALANCE_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allRegChildOutStanding = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_REG_CHILD_OUTSTANDING_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ALL_REG_CHILD_OUTSTANDING_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getRegistrationChildOutStanding'),
      };
    case FETCH_ALL_REG_CHILD_OUTSTANDING_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allRegChildRefunds = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_REG_CHILD_REFUNDS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ALL_REG_CHILD_REFUNDS_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getRegistrationRefundChildBalance'),
      };
    case FETCH_ALL_REG_CHILD_REFUNDS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allRegTotalAdvancePayments = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(
          action,
          'value.data.getRegistrationChildTotalAdvancePaymentAmount'
        ),
      };
    case FETCH_ALL_REG_CHILD_TOTAL_ADVANCE_PAYMENTS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allAdjustInvoiceAmts = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_ADJUST_INVOICE_AMT_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_ADJUST_INVOICE_AMT_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(
          action,
          'value.data.adjustInvoiceAmountByInvoiceItemsPreview'
        ),
      };
    case FETCH_ADJUST_INVOICE_AMT_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const allSubsidies = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_SUBSIDIES_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_SUBSIDIES_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.findAllChildHistorySubsidies'),
      };
    case FETCH_SUBSIDIES_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const tradeDebtNotifications = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_TRADE_DEBT_NOTIFICATION_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case FETCH_TRADE_DEBT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        ...action.value,
        data: get(action, 'value.data.getTradeDebtNotifications'),
      };
    case FETCH_TRADE_DEBT_NOTIFICATION_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};
