import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import compose from 'recompose/compose';
import { get } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Grid, Menu, MenuItem, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/MoreVert';
import Loader from '../../../common/Loader';
import {
  fetchClassList,
  listAllBusChildren,
  fetchAllCentreLevels,
  assignChildToBus,
  updateChildInBus,
  showSnackBarMessage,
  deleteChildFromBus,
} from '../../../../redux/actions';
import { getQueryParams, parseObjFields } from '../../../../utils';
import { SelectField } from '../../../common/InputFields';
import Text from '../../../common/Text';
import UrlPersistenceHOC from '../../HOC/UrlPersistenceHOC';
import Button from '../../../common/Button';
import CommonTable from '../../../common/Table';
import { DATE_FORMAT, DEFAULT_ERROR } from '../../../../utils/constants';
import Pagination from '../../../common/Pagination';
import ExpansionPanel from '../../../common/ExpansionPanel';
import Modal from '../../../common/Modal';
import BusChildSearch from './ChildSearch';
import BusChildDetails from './BusChildDetails';
import { AbilityContext } from '../../../../utils/ability/abilityContext';

const parseQuery = () => {
  const fields = {
    serviceLevelID: 'int',
    classID: 'int',
    page: 'int',
    perPage: 'int',
    activeSort: 'int',
  };

  const obj = parseObjFields(getQueryParams(), fields);
  return Object.assign(
    {
      serviceLevelID: '',
      classID: '',
      page: 1,
      perPage: 10,
      activeSort: 10,
    },
    obj
  );
};

class BusChildrenListing extends Component {
  static modelChildData(eachResult) {
    const childClass = get(
      eachResult,
      'child.currentClass.class.label',
      get(eachResult, 'child.upcomingClass.class.label', '-')
    );

    const bc = get(eachResult, 'child.birthCertificate', '-');
    const name = `${get(eachResult, 'child.firstname', '')} ${get(
      eachResult,
      'child.lastname'
    )}`;

    return {
      id: get(eachResult, 'ID', 0),
      childId: get(eachResult, 'child.ID', 0),
      bc,
      name,
      childClass,
      imageKey: get(eachResult, 'child.imageKey', null),
      serviceStartDate: eachResult.serviceStartDate
        ? moment(eachResult.serviceStartDate)
        : null,
      serviceEndDate: eachResult.serviceEndDate
        ? moment(eachResult.serviceEndDate)
        : null,
      pickup: get(eachResult, 'locationText', ''),
      remarks: get(eachResult, 'remarks', ''),
      days: get(eachResult, 'days', ''),
    };
  }

  constructor(props) {
    super(props);

    this.editChild = null;
    this.state = {
      showAddChildModal: false,
      selectedChild: null,
      isDelete: false,
      anchorEl: null,
      isEdit: null,
      deleteErr: '',
    };

    this.handleMenuSelect = this.handleMenuSelect.bind(this);
    this.handleChildSelect = this.handleChildSelect.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.fetchBusChildren = this.fetchBusChildren.bind(this);
    this.handlePagination = this.handlePagination.bind(this);
  }

  componentDidMount() {
    const {
      fetchClasses,
      fkSchool,
      fetchLevels,
      centreID,
      urlParams,
    } = this.props;

    const { serviceLevelID } = urlParams;

    fetchLevels({
      filter: {
        fkCentre: centreID,
      },
    });

    const classFilter = {
      fkCentre: centreID,
    };

    if (serviceLevelID) {
      classFilter.fkLevel = serviceLevelID;
    }

    fetchClasses({
      fkSchool,
      filter: classFilter,
    });

    this.fetchBusChildren();
  }

  handleFilterChange(e) {
    const { name, value } = e.target;
    const { changeParam, fetchClasses, fkSchool, centreID } = this.props;
    const page = 1;
    const perPage = 10;

    if (name === 'serviceLevelID') {
      fetchClasses({
        fkSchool,
        filter: {
          fkLevel: value,
          fkCentre: centreID,
        },
      });
    }

    changeParam(
      {
        [name]: value,
        page,
        perPage,
      },
      this.fetchBusChildren
    );
  }

  fetchBusChildren() {
    const {
      urlParams: { serviceLevelID, classID, page, perPage },
      centreID,
      fetchAllBusChildren,
      busID,
    } = this.props;

    const reqParams = {
      busID,
      pagination: {
        page,
        perPage,
        sort: ['-createdAt'],
      },
    };

    if (centreID) {
      reqParams.centreID = centreID;
    }

    if (serviceLevelID) {
      reqParams.levelID = serviceLevelID;
    }

    if (classID) {
      reqParams.classID = classID;
    }

    fetchAllBusChildren(reqParams);
  }

  handlePagination(page, perPage) {
    const { changeParam } = this.props;
    changeParam({ page, perPage }, this.fetchBusChildren);
  }

  handleChildSelect(data) {
    this.setState({
      selectedChild: data,
    });
  }

  handleMenuSelect(e) {
    e.stopPropagation();
    this.setState({
      anchorEl: e.currentTarget,
    });
  }

  render() {
    const { context } = this;
    const canWrite = context.can('write', 'bus_management');
    const {
      deleteAssignedChild,
      snackBarMessage,
      levels,
      updateChild,
      urlParams,
      assignChild,
      busChildren,
      cdnUrl,
      classes,
      centreID,
      busID,
      t,
    } = this.props;

    const {
      isEdit,
      isDelete,
      deleteErr,
      anchorEl,
      selectedChild,
      showAddChildModal,
    } = this.state;

    if (get(levels, 'inProgress', false) || get(classes, 'inProgress', false)) {
      return <Loader />;
    }

    let serviceLevelOptions = [];
    if (get(levels, 'data.length')) {
      serviceLevelOptions = levels.data.map(eachLevel => ({
        description: get(eachLevel, 'level.label', '-'),
        label: get(eachLevel, 'level.ID'),
      }));
    }

    let classOptions = [];
    if (get(classes, 'data.getAllClassInfo.data.length')) {
      classOptions = classes.data.getAllClassInfo.data.map(eachClass => ({
        description: eachClass.label,
        label: eachClass.ID,
      }));
    }

    const headLabels = [
      {
        title: t('registration.childName'),
      },
      {
        title: t('common.childBC'),
      },
      {
        title: t('common.classLabel'),
      },
      {
        title: t('common.effectiveStartDate'),
      },
      {
        title: t('common.effectiveEndDate'),
      },
      {
        title: '',
      },
    ];

    let tableData = null;

    if (get(busChildren, 'inProgress', false)) {
      tableData = null;
    } else if (get(busChildren, 'data.data', false)) {
      tableData = busChildren.data.data.map(eachChild => {
        const childClass = get(
          eachChild,
          'child.currentClass.class.label',
          get(eachChild, 'child.upcomingClass.class.label', '-')
        );

        let startDate = '-';
        if (get(eachChild, 'serviceStartDate', false)) {
          startDate = moment(get(eachChild, 'serviceStartDate')).format(
            DATE_FORMAT
          );
        }

        let endDate = '-';
        if (get(eachChild, 'serviceEndDate', false)) {
          endDate = moment(get(eachChild, 'serviceEndDate')).format(
            DATE_FORMAT
          );
        }

        return [
          `${get(eachChild, 'child.firstname', '')} ${get(
            eachChild,
            'child.lastname',
            ''
          )}`,
          get(eachChild, 'child.birthCertificate', ''),
          childClass,
          startDate,
          endDate,
          canWrite && (
            <IconButton
              onClick={e => {
                this.editChild = eachChild;
                this.handleMenuSelect(e);
              }}
              className="w3-right"
            >
              <MenuIcon />
            </IconButton>
          ),
        ];
      });
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <ExpansionPanel
            fullWidth
            defaultExpanded
            expansionPanelSummary={
              <Text h2 bold>
                {t('common.membersLabel')}
              </Text>
            }
            expansionPanelDetails={
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                      <SelectField
                        emptyMenu
                        label={t('common.serviceLevel')}
                        options={serviceLevelOptions}
                        value={get(urlParams, 'serviceLevelID')}
                        name="serviceLevelID"
                        onChange={this.handleFilterChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <SelectField
                        emptyMenu
                        label={t('common.classLabel')}
                        options={classOptions}
                        value={get(urlParams, 'classID')}
                        name="classID"
                        onChange={this.handleFilterChange}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      className="s7t-auto-margin w3-right-align"
                    >
                      {canWrite && (
                        <Button
                          button="secondary"
                          onClick={() => {
                            this.setState({
                              showAddChildModal: true,
                            });
                          }}
                        >
                          {t('common.addChildLabel')}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <CommonTable
                    headerstyle="s7t-feelist-headerstyle"
                    contentstyle="s7t-feelist-contentstyle"
                    noContentText={t('bus_management.noChildrenFound')}
                    labels={headLabels}
                    tableData={tableData}
                  />
                  <Grid container className="w3-padding-16">
                    <Pagination
                      onChange={this.handlePagination}
                      perPage={get(urlParams, 'perPage')}
                      currentPage={get(urlParams, 'page')}
                      defaultPerPage={get(urlParams, 'perPage')}
                      data={{
                        totalCount: get(busChildren, 'data.totalCount', false),
                        totalLabel: t('common.total'),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
          />
          {showAddChildModal && (
            <Modal
              fullWidth
              maxWidth="md"
              buttonSize="medium"
              isOpen={showAddChildModal}
              dialogTitle={`${t('common.add')} ${t('common.childLabel')}`}
              contentStyle="s7t-bus-child-add"
              hideBtns={selectedChild}
              dialogContent={
                <Grid container>
                  <Grid item xs={12}>
                    <BusChildSearch
                      cdnUrl={cdnUrl}
                      centreID={centreID}
                      modelChildData={BusChildrenListing.modelChildData}
                      handleChildSelect={this.handleChildSelect}
                    />
                  </Grid>
                  {selectedChild && (
                    <Grid item xs={12}>
                      <BusChildDetails
                        t={t}
                        cdnUrl={cdnUrl}
                        data={{
                          fkBus: busID,
                          ...selectedChild,
                        }}
                        closeModal={isSuccess => {
                          this.setState(
                            {
                              selectedChild: null,
                              showAddChildModal: false,
                            },
                            () => {
                              if (isSuccess) {
                                this.fetchBusChildren();
                                snackBarMessage(
                                  t('bus_management.childAssigned')
                                );
                              }
                            }
                          );
                        }}
                        assignChild={assignChild}
                      />
                    </Grid>
                  )}
                </Grid>
              }
              buttonPrimaryName={t('common.submitButton')}
              buttonSecondaryName={t('common.cancelButton')}
              actionContainerClass="w3-padding w3-padding-24"
              primaryButtonDisabled
              onPrimaryClick={() => {
                this.setState({
                  selectedChild: null,
                  showAddChildModal: false,
                });
              }}
              onSecondaryClick={() => {
                this.setState({
                  selectedChild: null,
                  showAddChildModal: false,
                });
              }}
            />
          )}
          {isEdit && (
            <Modal
              hideBtns
              fullWidth
              maxWidth="md"
              buttonSize="medium"
              isOpen={isEdit}
              dialogTitle={`${t('common.edit')} ${t('common.childLabel')}`}
              contentStyle="s7t-bus-child-add"
              dialogContent={
                <Grid container>
                  <Grid item xs={12}>
                    <BusChildDetails
                      t={t}
                      cdnUrl={cdnUrl}
                      isEdit={isEdit}
                      updateChild={updateChild}
                      data={{
                        fkBus: busID,
                        ...isEdit,
                      }}
                      closeModal={isSuccess => {
                        this.setState(
                          {
                            isEdit: null,
                            selectedChild: null,
                            showAddChildModal: false,
                          },
                          () => {
                            if (isSuccess) {
                              this.fetchBusChildren();
                              snackBarMessage(
                                t('bus_management.childUpdatedInBus')
                              );
                            }
                          }
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
          )}
          {isDelete && (
            <Modal
              fullWidth
              maxWidth="sm"
              buttonSize="medium"
              isOpen={isDelete}
              error={deleteErr}
              dialogTitle={`${t('common.delete')} ${t('common.childLabel')}`}
              dialogContent={t('bus_management.confirmDeleteChildAssignment')}
              buttonPrimaryName={t('common.confirmButton')}
              buttonSecondaryName={t('common.cancelButton')}
              onPrimaryClick={async () => {
                try {
                  const resp = await deleteAssignedChild(isDelete);

                  if (resp.success) {
                    this.setState(
                      {
                        isDelete: null,
                      },
                      () => {
                        this.fetchBusChildren();
                        snackBarMessage(
                          t('bus_management.assignedChildDeleted')
                        );
                      }
                    );
                  } else {
                    get(resp, 'error[0].message', DEFAULT_ERROR);
                  }
                } catch (ex) {
                  this.setState({
                    deleteErr: ex.message,
                  });
                }
              }}
              onSecondaryClick={() => {
                this.setState({
                  isDelete: null,
                });
              }}
            />
          )}
        </Grid>
        <Grid container>
          <Menu
            id="simple-popper"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => this.setState({ anchorEl: null })}
          >
            <MenuItem
              onClick={() => {
                this.setState({
                  anchorEl: null,
                  isDelete: this.editChild.ID,
                });
              }}
            >
              {t('common.delete')}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    );
  }
}

BusChildrenListing.contextType = AbilityContext;

const mapStateToProps = state => ({
  levels: get(state, 'centreLevels'),
  busChildren: get(state, 'allBusChildren'),
  fkSchool: get(state, 'schoolID.data'),
  classes: get(state, 'listAllClasses'),
  cdnUrl: get(state, 'cdnUrl.data'),
});

const mapDispatchToProps = {
  fetchAllBusChildren: listAllBusChildren,
  fetchLevels: fetchAllCentreLevels,
  fetchClasses: fetchClassList,
  assignChild: assignChildToBus,
  updateChild: updateChildInBus,
  snackBarMessage: showSnackBarMessage,
  deleteAssignedChild: deleteChildFromBus,
};

export default compose(withTranslation())(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(UrlPersistenceHOC(BusChildrenListing, parseQuery))
  )
);
