import LatoBlack from './fonts/Lato-Black.ttf';
import LatoSemiBold from './fonts/Lato-Semibold.ttf';
import LatoRegular from './fonts/Lato-Regular.ttf';
import LatoBold from './fonts/Lato-Bold.ttf';
import DKSugaryPancake from './fonts/DK-Sugary-Pancake.otf';

const FONT_FACES = [
  {
    fontFamily: 'Lato-Black',
    src: `url(${LatoBlack}) format('truetype')`,
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
  {
    fontFamily: 'Lato-Semibold',
    src: `url(${LatoSemiBold}) format('truetype')`,
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
  {
    fontFamily: 'Lato-Regular',
    src: `url(${LatoRegular}) format('truetype')`,
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
  {
    fontFamily: 'Lato-Bold',
    src: `url(${LatoBold}) format('truetype')`,
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
  {
    fontFamily: 'DK Sugary Pancake',
    src: `url(${DKSugaryPancake}) format('truetype')`,
    fontStyle: 'normal',
    fontDisplay: 'swap',
  },
];

export default FONT_FACES;
