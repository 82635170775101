import SkRequest from './BaseRequest';
import { MutationBuilder, QueryBuilder } from '../queryBuilder';
import {
  FETCH_ALL_TRANSFER_DRAFTS,
  IS_VACANCY_AVAILABLE,
  FETCH_ALL_COMPLETED_DRAFTS,
  FETCH_TRANSFER_DRAFTS_LOADING,
  FETCH_TRANSFER_DRAFTS_SUCCESS,
  FETCH_TRANSFER_DRAFTS_ERROR,
  FETCH_ALL_VACANCY,
  FETCH_CENTRE_VACANCY,
  HAS_LEAD_OFFERED,
  FEE_TIERS_BY_CHILD_CENTRE,
} from './actionTypes';
import {
  GET_ALL_TRANSFER_DRAFTS,
  GET_ALL_AVAILABLE_VACANCY,
  GET_CENTRE_AVAILABLE_VACANCY,
  GET_LEAD_OFFERED_FOR_TRANSFER,
  GET_FEE_TIERS_BY_CHILD_CENTRE,
  SUBMIT_NEW_TRANSFER_REQUEST,
} from '../models/transferModel';
import { SkApolloRequest } from '../../utils/apolloUtils';

const _mMutationBuilder = new MutationBuilder();
const _mQueryBuilder = new QueryBuilder();

_mMutationBuilder.setNewMutation('approveTransferRequestBySourceCentreStaff', {
  ID: 'number',
});
_mMutationBuilder.setNewMutation(
  'approveTransferRequestByDestinationCentreStaff',
  {
    ID: 'number',
  }
);
_mMutationBuilder.setNewMutation('cancelTransferRequestByUser', {});

_mMutationBuilder.setNewMutation(
  'rejectTransferRequestBySourceCentreStaff',
  {}
);

_mMutationBuilder.setNewMutation(
  'rejectTransferRequestByDestinationCentreStaff',
  {}
);

_mMutationBuilder.setNewMutation('completeTransferRequest', {});

_mQueryBuilder.setNewQuery('getTransferDrafts', {
  totalCount: 'number',
  data: {
    ID: 'number',
    vacancyAvailableForTransferRequest: 'boolean',
    status: 'string',
    additionalStatus: 'string',
    withdrawalEConsentCompleted: 'boolean',
    destinationCentreEnrollmentCompleted: 'boolean',
    destinationCentreFkFeeTier: 'number',
    centreByFkCentre: {
      ID: 'number',
      label: 'string',
    },
    centreByDestinationFkCentre: {
      ID: 'number',
      label: 'string',
    },
    userBySourceCentreFkApprover: {
      ID: 'number',
      firstname: 'string',
      lastname: 'string',
    },
    userByDestinationCentreFkApprover: {
      ID: 'number',
      firstname: 'string',
      lastname: 'string',
    },
    forms: {
      data: {
        formParticipants: {
          data: {
            ID: 'number',
            status: 'string',
            email: 'string',
            createdAt: 'datetime',
            updatedAt: 'datetime',
            fkParent: 'number',
            user: {
              firstname: 'string',
              lastname: 'string',
              getRole: {
                label: 'string',
              },
            },
          },
        },
      },
    },
    getTransferDraftFeeTier: {
      label: 'string',
    },
    transferDraftItem: {
      ID: 'number',
      fkRegistration: 'number',
      registration: {
        registrationChildMappings: {
          data: {
            ID: 'number',
            fkRegistrationChild: 'number',
          },
        },
      },
      fkDraft: 'number',
      otherReason: 'string',
      level: {
        ID: 'number',
        label: 'string',
      },
      program: {
        ID: 'number',
        label: 'string',
      },
      child: {
        ID: 'number',
        firstname: 'string',
        lastname: 'string',
        currentFee: {
          amount: 'number',
        },
      },
      user: {
        ID: 'number',
        firstname: 'string',
        lastname: 'string',
      },
      centreBySourceCentre: {
        ID: 'number',
        label: 'string',
      },
      centreByDestinationCentre: {
        ID: 'number',
        label: 'string',
      },
      refundFeeAmount: 'number',
      refundDepositAmount: 'number',
      effectiveDate: 'datetime',
      code: {
        ID: 'number',
        label: 'string',
        description: 'string',
      },
      fkEnquiry: 'number',
      remark: 'string',
      createdAt: 'datetime',
    },
  },
});
_mQueryBuilder.setNewQuery('getCompletedTransferRequests', {
  totalCount: 'number',
  data: {
    ID: 'number',
    child: {
      ID: 'number',
      firstname: 'string',
      lastname: 'string',
    },
    user: {
      ID: 'number',
      firstname: 'string',
      lastname: 'string',
    },
    centreBySourceCentre: {
      ID: 'number',
      label: 'string',
    },
    centreByDestinationCentre: {
      ID: 'number',
      label: 'string',
    },
    refundFeeAmount: 'number',
    refundDepositAmount: 'number',
    effectiveDate: 'timestamp',
    createdAt: 'datetime',
    code: {
      ID: 'number',
      label: 'string',
      description: 'string',
    },
    fkEnquiry: 'number',
    remark: 'string',
  },
});

_mQueryBuilder.setNewQuery('vacancyAvailableInCentreForLevel', {});

const transferDraftsLoading = () => ({
  type: FETCH_TRANSFER_DRAFTS_LOADING,
  key: 'allTransferDrafts',
  value: {
    inProgress: true,
  },
});

const transferDraftsSuccess = data => ({
  type: FETCH_TRANSFER_DRAFTS_SUCCESS,
  key: 'allTransferDrafts',
  value: {
    inProgress: false,
    data,
  },
});

const transferDraftsError = error => ({
  type: FETCH_TRANSFER_DRAFTS_ERROR,
  key: 'allTransferDrafts',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const dispatchFetchTransferDrafts = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_TRANSFER_DRAFTS,
    key: 'findAllTransferDrafts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchCompletedDrafts = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_COMPLETED_DRAFTS,
    key: 'findAllCompletedDrafts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCheckVacancyAvailable = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: IS_VACANCY_AVAILABLE,
    key: 'checkVacancyAvailable',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllAvailableVacancy = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_VACANCY,
    key: 'allVacancy',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCentreAvailableVacancy = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CENTRE_VACANCY,
    key: 'allCentreVacancy',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchLeadOfferedForTransfer = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: HAS_LEAD_OFFERED,
    key: 'hasLeadOffered',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFeeTiersByChildByCentre = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FEE_TIERS_BY_CHILD_CENTRE,
    key: 'feeTiersByChildByCentre',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const newTransferRequest = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SUBMIT_NEW_TRANSFER_REQUEST,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const approveTransferRequestSource = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'approveTransferRequestBySourceCentreStaff',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const rejectTransferRequestSource = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'rejectTransferRequestBySourceCentreStaff',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const approveTransferRequestDestination = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'approveTransferRequestByDestinationCentreStaff',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const rejectTransferRequestDestination = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'rejectTransferRequestByDestinationCentreStaff',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const cancelTransferRequestByUser = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'cancelTransferRequestByUser',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const completedTransferRequest = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'completeTransferRequest',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchAllTransferRequests = reqData => async dispatch => {
  dispatchFetchTransferDrafts(dispatch);
  const query = _mQueryBuilder.getQueryAsString('getTransferDrafts', reqData);
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchFetchTransferDrafts(dispatch, false, { data: data.data });
    } else {
      dispatchFetchTransferDrafts(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFetchTransferDrafts(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchAllCompletedTransferRequests = reqData => async dispatch => {
  dispatchFetchCompletedDrafts(dispatch);
  const query = _mQueryBuilder.getQueryAsString(
    'getCompletedTransferRequests',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchFetchCompletedDrafts(dispatch, false, { data: data.data });
    } else {
      dispatchFetchCompletedDrafts(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFetchCompletedDrafts(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const checkAllVacancyAvailable = reqData => async dispatch => {
  dispatchCheckVacancyAvailable(dispatch);
  const query = _mQueryBuilder.getQueryAsString(
    'vacancyAvailableInCentreForLevel',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchCheckVacancyAvailable(dispatch, false, { data: data.data });
    } else {
      dispatchCheckVacancyAvailable(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchCheckVacancyAvailable(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const getAllTransferDrafts = reqData => async dispatch => {
  dispatch(transferDraftsLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ALL_TRANSFER_DRAFTS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(transferDraftsSuccess(data.data));
    } else {
      dispatch(transferDraftsError(data.error));
    }
  } catch (ex) {
    dispatch(transferDraftsError(ex.ex.message));
  }
};

export const getAllAvailableVacancy = reqData => async dispatch => {
  dispatchAllAvailableVacancy(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ALL_AVAILABLE_VACANCY,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchAllAvailableVacancy(dispatch, false, { data: data.data });
    } else {
      dispatchAllAvailableVacancy(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchAllAvailableVacancy(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const getCentreAvailableVacancy = reqData => async dispatch => {
  dispatchCentreAvailableVacancy(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_CENTRE_AVAILABLE_VACANCY,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchCentreAvailableVacancy(dispatch, false, { data: data.data });
    } else {
      dispatchCentreAvailableVacancy(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchCentreAvailableVacancy(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const getLeadOfferedForTransfer = reqData => async dispatch => {
  dispatchLeadOfferedForTransfer(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_LEAD_OFFERED_FOR_TRANSFER,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchLeadOfferedForTransfer(dispatch, false, { data: data.data });
    } else {
      dispatchLeadOfferedForTransfer(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchLeadOfferedForTransfer(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const getFeeTiersByLevelByCentre = reqData => async dispatch => {
  dispatchFeeTiersByChildByCentre(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_FEE_TIERS_BY_CHILD_CENTRE,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFeeTiersByChildByCentre(dispatch, false, { data: data.data });
    } else {
      dispatchFeeTiersByChildByCentre(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFeeTiersByChildByCentre(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export default newTransferRequest;
