import SkRequest from './BaseRequest';
import { QueryBuilder, MutationBuilder } from '../queryBuilder';
import {
  ALL_WITHDRAWAL_REQUEST,
  ALL_WITHDRAWAL,
  ALL_REFUNDS,
  FETCH_WITHDRAWAL_DRAFTS_LOADING,
  FETCH_WITHDRAWAL_DRAFTS_SUCCESS,
  FETCH_WITHDRAWAL_DRAFTS_ERROR,
} from './actionTypes';
import {
  GET_ALL_WITHDRAWAL_DRAFTS,
  CANCEL_COMPLETED_WITHDRAWAL,
  CHANGE_WITHDRAWAL_DATE,
  WITHDRAWAL_MANUAL_FORM_COMPLETE,
} from '../models/withdrawalModel';
import { SkApolloRequest } from '../../utils/apolloUtils';

const _mQueryBuilder = new QueryBuilder();
const _mMutationEnquiryBuilder = new MutationBuilder();

_mMutationEnquiryBuilder.setNewMutation(
  'approveWithdrawalRequestByCentreStaff',
  {
    ID: 'number',
  }
);

_mMutationEnquiryBuilder.setNewMutation(
  'rejectWithdrawalRequestByCentreStaff',
  {}
);

_mMutationEnquiryBuilder.setNewMutation(
  'onAllWithdrawalDocumentedVerified',
  {}
);

_mMutationEnquiryBuilder.setNewMutation('approveWithdrawalRequestByHQ', {
  ID: 'number',
});

_mMutationEnquiryBuilder.setNewMutation('rejectWithdrawalRequestByHQ', {});

_mMutationEnquiryBuilder.setNewMutation('cancelWithdrawal', {});

_mMutationEnquiryBuilder.setNewMutation('cancelWithdrawalRequest', {});

_mQueryBuilder.setNewQuery('findAllWithdrawalDraft', {
  totalCount: 'number',
  data: {
    ID: 'number',
    fkSchool: 'number',
    fkCentre: 'number',
    status: 'string',
    eConsentStatus: 'string',
    updatedAt: 'Datetime',
    additionalStatus: 'string',
    forms: {
      data: {
        formParticipants: {
          data: {
            ID: 'number',
            status: 'string',
            email: 'string',
            createdAt: 'datetime',
            updatedAt: 'datetime',
            fkParent: 'number',
            user: {
              firstname: 'string',
              lastname: 'string',
              getRole: {
                label: 'string',
              },
            },
          },
        },
      },
    },
    estimateRefundAmount: {
      defaultDepositRefundAmount: 'number',
      defaultFeeRefundAmount: 'number',
      monthlyFee: 'number',
      depositAmount: 'number',
    },
    withdrawalDraftItem: {
      refundFeeAmount: 'number',
      refundDepositAmount: 'number',
      effectiveDate: 'Datetime',
      createdAt: 'Datetime',
      submissionDate: 'Datetime',
      otherReason: 'string',
      child: {
        ID: 'number',
        firstname: 'string',
        lastname: 'string',
      },
      codeByReason: {
        ID: 'number',
        label: 'string',
        description: 'string',
      },
      codeByParentReason: {
        ID: 'number',
        label: 'string',
        description: 'string',
      },
    },
    withdrawalDraftActivityLogs: {
      data: {
        ID: 'int',
        remark: 'string',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('findAllWithdrawal', {
  totalCount: 'number',
  data: {
    ID: 'number',
    refundFeeAmount: 'number',
    refundDepositAmount: 'number',
    effectiveDate: 'Datetime',
    child: {
      ID: 'number',
      firstname: 'string',
      lastname: 'string',
    },
  },
});

_mQueryBuilder.setNewQuery('getWithdrawalDefaultRefundAmount', {
  depositAmount: 'number',
  monthlyFee: 'number',
  defaultFeeRefundAmount: 'number',
  defaultDepositRefundAmount: 'number',
});

const withdrawalDraftsLoading = () => ({
  type: FETCH_WITHDRAWAL_DRAFTS_LOADING,
  key: 'allWithdrawalDrafts',
  value: {
    inProgress: true,
  },
});

const withdrawalDraftsSuccess = data => ({
  type: FETCH_WITHDRAWAL_DRAFTS_SUCCESS,
  key: 'allWithdrawalDrafts',
  value: {
    inProgress: false,
    data,
  },
});

const withdrawalDraftsError = error => ({
  type: FETCH_WITHDRAWAL_DRAFTS_ERROR,
  key: 'allWithdrawalDrafts',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const dispatchFindAllWithDrawalDraft = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: ALL_WITHDRAWAL_REQUEST,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFindAllWithDrawal = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: ALL_WITHDRAWAL,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllRefunds = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: ALL_REFUNDS,
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchAllRefunds = reqData => async dispatch => {
  dispatchAllRefunds(dispatch);
  const query = _mQueryBuilder.getQueryAsString(
    'getWithdrawalDefaultRefundAmount',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchAllRefunds(dispatch, false, { data: data.data });
    } else {
      dispatchAllRefunds(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchAllRefunds(dispatch, false, null, { error: ex.message });
  }
};

export const fetchAllWithDrawalDraft = (
  filter,
  pagination = null
) => async dispatch => {
  dispatchFindAllWithDrawalDraft(dispatch);

  filter = Object.assign({}, filter);
  const filterKeys = Object.keys(filter);

  filterKeys.forEach(eachFilter => {
    if (!filter[eachFilter]) {
      delete filter[eachFilter];
    }
  });

  const reqData = { filter, pagination };
  if (!reqData.pagination) {
    delete reqData.pagination;
  }

  const query = _mQueryBuilder.getQueryAsString(
    'findAllWithdrawalDraft',
    reqData
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchFindAllWithDrawalDraft(dispatch, false, data.data);
    } else {
      dispatchFindAllWithDrawalDraft(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFindAllWithDrawalDraft(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const fetchAllWithDrawal = (
  filter,
  pagination = null
) => async dispatch => {
  dispatchFindAllWithDrawal(dispatch);

  filter = Object.assign({}, filter);
  const filterKeys = Object.keys(filter);

  filterKeys.forEach(eachFilter => {
    if (!filter[eachFilter]) {
      delete filter[eachFilter];
    }
  });

  const reqData = { filter, pagination };
  if (!reqData.pagination) {
    delete reqData.pagination;
  }

  const query = _mQueryBuilder.getQueryAsString('findAllWithdrawal', reqData);

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchFindAllWithDrawal(dispatch, false, data.data);
    } else {
      dispatchFindAllWithDrawal(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFindAllWithDrawal(dispatch, false, null, { error: ex.message });
  }
};

export const cancelWithdrawal = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString(
          'cancelWithdrawal',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const cancelWithdrawalRequest = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString(
          'cancelWithdrawalRequest',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const approveWithdrawalRequestByHQ = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString(
          'approveWithdrawalRequestByHQ',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const onAllWithdrawalDocumentedVerified = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString(
          'onAllWithdrawalDocumentedVerified',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const rejectWithdrawalRequestByPrincipal = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString(
          'rejectWithdrawalRequestByCentreStaff',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const rejectWithdrawalRequestByHQ = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString(
          'rejectWithdrawalRequestByHQ',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const approveWithdrawalByPrincipal = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationEnquiryBuilder.getMutationAsString(
          'approveWithdrawalRequestByCentreStaff',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const getAllWithdrawalDrafts = reqData => async dispatch => {
  dispatch(withdrawalDraftsLoading());
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_ALL_WITHDRAWAL_DRAFTS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(withdrawalDraftsSuccess(data.data));
    } else {
      dispatch(withdrawalDraftsError(data.error));
    }
  } catch (ex) {
    dispatch(withdrawalDraftsError(ex.ex.message));
  }
};

export const cancelCompletedWithdrawal = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CANCEL_COMPLETED_WITHDRAWAL,
        variables: reqData,
      },
      type: 'mutation',
    });

    return data;
  } catch (ex) {
    return ex;
  }
};

export const changeWithdrawalDate = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: CHANGE_WITHDRAWAL_DATE,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const handleWithdrawalManualFormComplete = (
  IDDraft,
  IDChild
) => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: WITHDRAWAL_MANUAL_FORM_COMPLETE,
        variables: { IDDraft, IDChild },
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export default approveWithdrawalByPrincipal;
