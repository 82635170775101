import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  MuiPickersUtilsProvider,
  DatePicker as MuiDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Trans } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { withTheme, makeStyles } from '@material-ui/core/styles';
import { MaskedTimeInput, StyledKeyboardDatePicker } from './style';
import FieldWrapper from './FieldWrapper';
import {
  DATE_FORMAT,
  MAX_SELECTABLE_DATE,
  MIN_SELECTABLE_DATE,
} from '../../../utils/constants';

const useStyles = makeStyles(theme => ({
  root: {
    '& h3': {
      color: theme.baseColor.neutral.white,
    },
    '& h4': {
      color: theme.baseColor.neutral.white,
    },
  },
}));

export const DateTimePicker = ({
  noFieldWrapper,
  isNormal,
  value,
  placeholder,
  name,
  style,
  disabled,
  params,
  handleDateChange = () => {},
  handleDisable,
  disablePast,
  disableFuture,
  required,
  label,
  error,
  labelStyle,
  textStyles,
  type = 'date',
  views,
  openTo,
  minDate,
  maxDate,
  clearable = false,
  variant = 'inline',
  initialFocusedDate,
  labelTextColor,
  // time
  margin = 'none',
  mask,
  inputVariant = 'standard',
  invalidFormatErrorNotNeeded,
  fullWidth = true,
  yearSelector,
  format,
  onMonthChange = () => {},
  timePickerProps,
  dismissalReport = false,
  showIcon = false,
  showMinDateMessage = true,
  onAccept = () => {},
  onYearChange = () => {},
}) => {
  const classes = useStyles();

  const handleChange = date => {
    handleDateChange({ target: { name, value: date } }, ...params);
  };
  const handleMonthChange = date => {
    onMonthChange({ target: { name, value: date } }, ...params);
  };
  const handleYearChange = date => {
    onYearChange({ target: { name, value: date } }, ...params);
  };
  const commonProps = {
    clearable,
    name,
    value: value || null,
    placeholder,
    onChange: handleChange,
    onMonthChange: handleMonthChange,
    variant,
    allowKeyboardControl: true,
    invalidDateMessage: '',
    InputProps: style,
    className: textStyles,
    fullWidth: true,
    autoOk: true,
    disablePast,
    disableFuture,
    shouldDisableDate: handleDisable,
    disabled,
    style,
    minDate,
    maxDate,
    initialFocusedDate,
    format,
    minDateMessage: showMinDateMessage ? (
      <Trans
        i18nKey="common.dateBeforeStartDateError"
        values={{
          date: minDate
            ? moment(minDate).format(DATE_FORMAT)
            : MIN_SELECTABLE_DATE,
        }}
      />
    ) : (
      ''
    ),
    maxDateMessage: (
      <Trans
        i18nKey="common.dateAfterEndDateError"
        values={{
          date: maxDate
            ? moment(maxDate).format(DATE_FORMAT)
            : MAX_SELECTABLE_DATE,
        }}
      />
    ),
    onAccept,
    onYearChange: handleYearChange,
  };

  if (type === 'date' && !views) {
    commonProps.format = 'DD/MM/YYYY';
  }

  if (views) {
    commonProps.views = views;
  }

  if (dismissalReport) {
    commonProps.error = false;
    commonProps.helperText = null;
  }

  let picker = null;
  if (type === 'date') {
    if (isNormal) {
      picker = (
        <MuiDatePicker
          openTo={openTo || 'date'}
          views={views || ['date']}
          yearSelector={yearSelector}
          PopoverProps={{
            classes,
          }}
          {...commonProps}
        />
      );
    } else {
      picker = (
        <StyledKeyboardDatePicker
          yearSelector={yearSelector}
          PopoverProps={{
            classes,
          }}
          {...commonProps}
        />
      );
    }
  } else {
    const invalidTimeErrorText = invalidFormatErrorNotNeeded ? (
      ''
    ) : (
      <Trans i18nKey="error.invalidTimeFormatError" />
    );
    let defaultPlaceholder = placeholder;
    let defaultMask = mask;
    if (!placeholder) {
      defaultPlaceholder = '00:00 AM';
    }
    if (!mask) {
      defaultMask = '__:__ _M';
    }
    let timePickerDefaultProps = {
      open: false,
      keyboardIcon: null,
    };
    // If want to show time picker open prop is true or never pass it
    if (timePickerProps) timePickerDefaultProps = timePickerProps;
    picker = (
      <MaskedTimeInput
        margin={margin}
        placeholder={defaultPlaceholder}
        mask={defaultMask}
        inputVariant={inputVariant}
        onChange={handleChange}
        name={name}
        value={value}
        disabled={disabled}
        invalidDateMessage={invalidTimeErrorText}
        fullWidth={fullWidth}
        {...timePickerDefaultProps}
        keyboardIcon={showIcon ? <AccessTimeIcon color="main" /> : null}
      />
    );
  }

  const pickerWithWrapper = (
    <MuiPickersUtilsProvider
      utils={!(type === 'date') ? DateFnsUtils : MomentUtils}
    >
      {picker}
    </MuiPickersUtilsProvider>
  );

  return (
    <Fragment>
      {noFieldWrapper ? (
        pickerWithWrapper
      ) : (
        <FieldWrapper
          required={required}
          label={label}
          name={name}
          error={error}
          styles={labelStyle}
          disabled={disabled}
          labelTextColor={labelTextColor}
        >
          {pickerWithWrapper}
        </FieldWrapper>
      )}
    </Fragment>
  );
};

DateTimePicker.defaultProps = {
  params: [],
  timePickerProps: null,
};

DateTimePicker.propTypes = {
  params: PropTypes.arrayOf(PropTypes.any),
  timePickerProps: PropTypes.objectOf(PropTypes.object),
};

export default withTheme(DateTimePicker);
