import { get } from 'lodash';

import { tokenString, tokenData } from '../../../utils';
import { isDetailsNotMatch } from '../../../utils/authUtil';

import { LOGIN, OVERVIEW, HOMEPAGE } from '../../../routes/constants';
import { DEFAULT_ERROR } from '../../../utils/constants';
import localStorage from '../../../utils/localStorage';

async function SubmitAction(data, action, t) {
  const linkpassIDToken = localStorage.getValue('linkpassIDToken');
  let token = null;
  let { formIsValid } = this.state;
  const { email, errors } = this.state;
  const { location } = this.props;
  if (location && location.search) {
    token = tokenString(this.props.location.search);
  }

  switch (action) {
    case 'login':
      this.props.loginUser(data.email, data.password).then(resp => {
        const errors = get(resp, 'error', null);
        if (!errors) {
          window.location.pathname = OVERVIEW;
        } else {
          const errCode = get(resp, 'error[0].extensions.errorCode');
          switch (errCode) {
            case 'ERR_USER_DOES_NOT_EXIST':
              errors.password = t('auth.userNotExistTryAgainMessage');
              break;
            case 'ERR_INCORRECT_PASSWORD':
            case 'ERR_INVALID_USERNAME_OR_PASSWORD':
              errors.password = t('auth.enterRightPassword');
              break;
            case 'ERR_AUTH_DEACTIVE_VENDOR_USER':
              errors.password = t('auth.deactiveVendorAccount');
              break;
            default:
              errors.password = DEFAULT_ERROR;
          }
          this.setState({
            errors,
          });
        }
      });
      break;
    case 'connectToMFSAccount': {
      const linkpassSn2UserInfoResp = await this.props.linkpassSn2UserInfo(
        data.email,
        data.password,
        linkpassIDToken
      );
      const _errors = get(linkpassSn2UserInfoResp, 'error[0].message', null);
      if (_errors) {
        this.props.showSnackBarMessage(_errors, 'error');
        return;
      }

      const isNotMatch = isDetailsNotMatch(
        get(linkpassSn2UserInfoResp, 'data.linkpassSn2UserInfo.User'),
        get(
          linkpassSn2UserInfoResp,
          'data.linkpassSn2UserInfo.LinkPassAuthTokenClaimDto'
        )
      );
      if (isNotMatch) {
        this.setState({
          isAccountDetailsNotMatch: true,
        });
        return;
      }

      this.props
        .linkUserWithLinkPassAndLogin(
          data.email,
          data.password,
          linkpassIDToken
        )
        .then(resp => {
          const errors = get(resp, 'error', null);

          if (!errors) {
            const userToken = get(resp, 'data.linkUserWithLinkPassAndLogin');
            localStorage.setValue('userToken', userToken);
            window.location.href = `${OVERVIEW}?linkedAccountSuccessfully=true`;
          } else {
            const errMsg = get(resp, 'error[0].message');
            const errorCode = get(resp, 'error[0].extensions.errorCode');

            errors.password = DEFAULT_ERROR;
            if (errMsg.includes('does not exist')) {
              errors.password = t('auth.userNotExistTryAgainMessage');
            } else if (errMsg.includes('incorrect password')) {
              errors.password = t('auth.enterRightPassword');
            } else if (
              errorCode === 'ERR_USER_LINKPASS_UID_MISMATCH' ||
              errorCode === 'ERR_ACCOUNT_CONNECTED_TO_ANOTHER_LINKPASS'
            ) {
              localStorage.setValue('linkpassIDToken', null);
              this.setState({
                isOpenLinkpassAccountAlreadyInUseModal: true,
                errorCode,
              });
            } else {
              this.props.showSnackBarMessage(errMsg, 'error');
            }
            this.setState({
              errors,
            });
          }
        });
      break;
    }
    case 'sso_url':
      this.props.getSSOURL(this.props.fkSchool).then(resp => {
        if (resp.success) {
          window.location.href = resp.data.getLoginUrl;
        } else {
          errors.SSOError = resp.error[0].message;
          this.setState({
            errors,
          });
        }
      });
      break;
    case 'register':
      this.props.registerPassword(token.token, data.password).then(resp => {
        const loginData = tokenData(token.token);
        if (resp.error) {
          this.setState(
            {
              dialogTitle: t('auth.registeredUser'),
              isOpen: true,
              dialogMsg: t('auth.clickHereLogin', {
                error: get(resp, 'error[0].message'),
              }),
              dialogPrimaryClick: () => {
                this.props.history.push(LOGIN);
              },
              dialogSecondaryClick: () => {
                this.setState({
                  isOpen: false,
                });
              },
            },
            this.theDialog && this.theDialog.openDialogBox
          );
        } else {
          this.props.loginUser(loginData.email, data.password).then(logData => {
            if (logData.error) {
              errors.password = logData.error;
              this.setState({
                errors,
              });
            } else {
              window.location.pathname = OVERVIEW;
            }
          });
        }
      });
      break;
    case 'reset':
      this.props.resetPassword(token.token, data.password).then(resp => {
        if (resp.error) {
          errors.confirmPassword = resp.error[0].message;
          this.setState({
            errors,
          });
        } else {
          this.setState(
            {
              dialogTitle: t('auth.reset'),
              isOpen: true,
              dialogMsg: t('auth.resetPassword'),
              dialogPrimaryClick: () => {
                this.setState(
                  {
                    isOpen: false,
                  },
                  () => {
                    this.props.history.push(LOGIN);
                  }
                );
              },
              dialogSecondaryClick: () => {
                this.setState({
                  isOpen: false,
                });
              },
            },
            this.theDialog && this.theDialog.openDialogBox
          );
        }
      });
      break;
    case 'otp':
      this.props.registerPassword(token.token, data.password).then(resp => {
        const loginData = tokenData(token.token);
        if (resp.error) {
          this.setState(
            {
              dialogTitle: t('auth.registeredUser'),
              isOpen: true,
              dialogMsg: t('auth.clickHereLogin', {
                error: get(resp, 'error[0].message'),
              }),
              dialogPrimaryClick: () => {
                this.setState(
                  {
                    isOpen: false,
                  },
                  () => {
                    this.props.history.push(LOGIN);
                  }
                );
              },
              dialogSecondaryClick: () => {
                this.setState({
                  isOpen: false,
                });
              },
            },
            this.theDialog && this.theDialog.openDialogBox
          );
        } else {
          this.props.loginUser(loginData.email, data.password).then(logData => {
            if (logData.error) {
              errors.password = logData.error;
              this.setState({
                errors,
              });
            } else {
              window.location.pathname = OVERVIEW;
            }
          });
        }
      });
      break;
    case 'forgot':
      formIsValid = true;
      if (!email) {
        formIsValid = false;
        errors.email = t('auth.enterYourEmail');
      }
      if (email) {
        if (typeof email !== 'undefined') {
          const lastAtPos = email.lastIndexOf('@');
          const lastDotPos = email.lastIndexOf('.');

          if (
            !(
              lastAtPos < lastDotPos &&
              lastAtPos > 0 &&
              email.indexOf('@@') === -1 &&
              lastDotPos > 2 &&
              email.length - lastDotPos > 2
            )
          ) {
            formIsValid = false;
            errors.email = t('auth.notValidEmailAuth');
          }
        }
      }
      this.setState({ errors, formIsValid }, () => {
        if (formIsValid) {
          this.props.forgotPassword(data.email).then(resp => {
            if (!resp.error) {
              this.setState(
                {
                  dialogTitle: t('auth.forgotPassword'),
                  isOpen: true,
                  dialogMsg: t('auth.emailSendTo', {
                    email: get(data, 'email'),
                  }),
                  dialogSecondaryClick: () => {
                    this.setState({
                      isOpen: false,
                    });
                  },
                  dialogPrimaryClick: () => {
                    this.setState(
                      {
                        isOpen: false,
                      },
                      () => {
                        this.props.history.push(LOGIN);
                      }
                    );
                  },
                },
                this.theDialog && this.theDialog.openDialogBox
              );
            } else {
              errors.email = resp.error[0].message;
              this.setState({
                errors,
              });
            }
          });
        }
      });

      break;
    default:
      this.props.history.push(HOMEPAGE);
  }
}

export default SubmitAction;
