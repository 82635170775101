import gql from 'graphql-tag';

const FRAGMENT_CONFIG = gql`
  fragment configData on ListCode {
    data {
      ID
      label
      description
    }
  }
`;

export const GET_CENTRE_MANAGEMENT_CONFIG = gql`
  query getCentreManagementConfig($fkSchool: FilterOnField) {
    workplace: findAllConfigByCategory(
      searchCategory: "workplace_staff"
      filter: { fkSchool: $fkSchool }
    ) {
      ...configData
    }
    govtScheme: findAllConfigByCategory(
      searchCategory: "govt_scheme"
      filter: { fkSchool: $fkSchool }
    ) {
      ...configData
    }
    centreCertification: findAllConfigByCategory(
      searchCategory: "centre_certification"
      filter: { fkSchool: $fkSchool }
    ) {
      ...configData
    }
  }
  ${FRAGMENT_CONFIG}
`;

export const CREATE_OR_UPDATE_SCHOOL_CENTRE = gql`
  mutation insertCentreForSchool(
    $schoolID: Int!
    $centreID: Int
    $centreData: CentreUpdate!
    $postcodes: [String!]
    $levels: [Int!]!
    $addressData: AddressUpdate
    $workplaces: [Int!]
    $centreAutoPromotionConfigs: [CentreAutoPromotionConfigInput!]
  ) {
    insertOrUpdateCentreForSchool(
      IDSchool: $schoolID
      centreID: $centreID
      centreData: $centreData
      workplaces: $workplaces
      postcodes: $postcodes
      levels: $levels
      addressData: $addressData
      centreAutoPromotionConfigs: $centreAutoPromotionConfigs
    ) {
      ID
    }
  }
`;

export const FIND_ALL_CENTRE_FOR_SCHOOL = gql`
  query findAllCentreForSchool(
    $schoolID: Int!
    $pagination: Pagination
    $filter: CentreFilter
  ) {
    findAllCentreForSchool(
      IDSchool: $schoolID
      pagination: $pagination
      filter: $filter
    ) {
      totalCount
      data {
        ID
        code
        ecdaCode
        costCentreCode
        fkPrimaryEmailContact
        label
        fkSchool
        fkGovtScheme
        fkCertification
        teacherRatio
        createdAt
        updatedAt
        linkBranchID
        sparkExpirationDate
        firstLicenseDate
        licenseDuration
        firstOperationDate
        licenseRenewalDate
        licenseRenewalDuration
        licensedInfantCareCapacity
        licensedChildcareCapacity
        isPublic
        enrolmentDatesSetting
        centreWorkplaces {
          totalCount
          data {
            ID
            fkCentre
            fkWorkplace
          }
        }
        addresses {
          totalCount
          data {
            ID
            name
            floor
            unit
            address
            building
            postcode
            city
            country
            lat
            lng
            building
          }
        }
        centreLevels {
          totalCount
          data {
            ID
            fkLevel
            autoPromotionFlg
            autoPromotionJobDay
            autoPromotionJobMonth
            reminderDays
            createdAt
            updatedAt
          }
        }
        centreNearbyPostcodes {
          totalCount
          data {
            ID
            fkCentre
            postcode
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const GET_MARKETING_CONSENT = gql`
  query getMarketingConsentDashboard(
    $centreIds: [Int!]
    $searchString: String
    $pagination: Pagination
  ) {
    getMarketingConsentDashboard(
      centreIds: $centreIds
      searchString: $searchString
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        documentURL
        fkMarketingConsentUser
        fkUser
        updatedAt
        marketingConsentUser {
          fullname
          email
          mobilePhone
          mobilePhoneCountryCode
        }
        user {
          firstname
          lastname
          email
          mobilePhoneCountryCode
          mobilePhone
        }
        marketingConsentBusinessEntities {
          data {
            ID
            phone
            sms
            email
            businessEntity {
              ID
              name
              type
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_MARKETING_CONSENT_UPLOAD_URL = gql`
  mutation getMarketingConsentDocumentUploadURL($file: Upload!) {
    getMarketingConsentDocumentUploadURL(file: $file) {
      Url
      Key
    }
  }
`;

export const UPDATE_MARKETING_CONSENT = gql`
  mutation updateMarketingConsentDashboard(
    $input: MarketingConsentDashboardInput!
  ) {
    updateMarketingConsentDashboard(input: $input)
  }
`;

export const GET_MARKETING_CONSENT_LOGS = gql`
  query findAllMarketingConsentAuditLog(
    $pagination: Pagination
    $filter: MarketingConsentAuditLogFilter
  ) {
    findAllMarketingConsentAuditLog(pagination: $pagination, filter: $filter) {
      totalCount
      data {
        ID
        marketingConsentJSON
        active
        remarks
        createdAt
        updatedAt
        user {
          firstname
          lastname
        }
      }
    }
  }
`;
