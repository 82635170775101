import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FieldWrapper from './FieldWrapper';
import { StyledRadioGroup } from './style';

export const RadioGroupField = ({
  label,
  name,
  value,
  onChange,
  options,
  error,
  required,
  labelStyle,
  showHorizontal,
  horizontalEquallySpaced,
  radioOptionStyle,
  disabled,
}) => (
  <FieldWrapper
    required={required}
    label={label}
    name={name}
    error={error}
    styles={labelStyle}
    disabled={disabled}
  >
    <StyledRadioGroup
      name={name}
      color="primary"
      value={value}
      onChange={onChange}
      horizontalEquallySpaced={horizontalEquallySpaced}
      showHorizontal={showHorizontal}
    >
      {options.map(item => (
        <FormControlLabel
          disabled={disabled || item.disabled}
          key={item.label}
          value={item.label}
          control={<Radio color="primary" />}
          label={item.description}
          style={radioOptionStyle}
        />
      ))}
    </StyledRadioGroup>
  </FieldWrapper>
);

export default RadioGroupField;
