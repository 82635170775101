import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import {
  createMuiTheme,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';

import Loader from './common/Loader';
import SkRequest from '../redux/actions/BaseRequest';
import localStore from '../utils/localStorage';

import {
  dispatchFetchGstConfig,
  dispatchSetSchoolConfig,
  getAppConfig,
} from '../redux/actions';
import THEME from '../theme';
import GlobalStyles from '../theme/GlobalStyles';
import '../../node_modules/w3-css/w3.css';
import '../../node_modules/video.js/dist/video-js.css';
import '../styles/index.scss';

class AppThemeWrapper extends Component {
  constructor(args) {
    super(args);
    this.state = {
      theme: null,
    };
    this.fetchThemeData = this.fetchThemeData.bind(this);
  }

  componentDidMount() {
    if (localStore.getValue('themeData')) {
      this.setState({
        theme: localStore.getValue('themeData'),
      });
    }

    const { getAppConfig, fkSchool } = this.props;
    getAppConfig(fkSchool);
    this.fetchThemeData();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    const { userDetailsData, fkSchool } = this.props;
    if (
      !isEmpty(userDetailsData?.data) &&
      !isEmpty(newProps?.userDetailsData?.data)
    ) {
      const currentAccessControls = JSON.stringify(
        get(userDetailsData, 'data.userAccessControls.data', [])
      );
      const newAccessControls = JSON.stringify(
        get(newProps, 'userDetailsData.data.userAccessControls.data', [])
      );
      if (
        fkSchool !== newProps.fkSchool ||
        currentAccessControls !== newAccessControls
      ) {
        this.fetchThemeData(newProps);
      }
    }
  }

  async fetchThemeData(props = this.props) {
    const { userDetailsData, setSchoolConfig, setGstConfig, fkSchool } = props;

    let themeData = null;
    themeData = {
      ...THEME,
    };

    try {
      const fetchTheme = await SkRequest({
        data: {
          query: `query { findAllSchoolConfig(filter: {fkSchool: ${
            fkSchool ||
            userDetailsData.data.userAccessControls.data[0].school.ID
          }}) { totalCount data{ ID fkSchool value key } } }`,
        },
        method: 'POST',
      });

      const fetchGstConfig = await SkRequest({
        data: {
          query: `
            query {
              getAllGstValues(filter: {}){
                totalCount
                data{
                  ID
                  from
                  to
                  rate
                  active
                }
              }
            }
          `,
        },
        method: 'POST',
      });

      if (
        fetchGstConfig.data &&
        fetchGstConfig.data.getAllGstValues &&
        fetchGstConfig.data.getAllGstValues.data
      ) {
        setGstConfig(get(fetchGstConfig, 'data', {}));
      }

      if (
        fetchTheme.data &&
        fetchTheme.data.findAllSchoolConfig &&
        fetchTheme.data.findAllSchoolConfig.data
      ) {
        setSchoolConfig(fetchTheme.data);
        const fetchedThemeData = fetchTheme.data.findAllSchoolConfig.data;
        fetchedThemeData.forEach(eachVal => {
          if (!eachVal.value) {
            return;
          }

          if (eachVal.key === 'primary_color') {
            themeData.palette.primary.main = eachVal.value;
            themeData.overrides.MuiInput.underline[
              '&:hover:not(.Mui-disabled):not(.Mui-error):not(.Mui.focused):before'
            ].borderBottomColor = THEME.baseColor.neutral.main;
          } else if (eachVal.key === 'secondary_color') {
            themeData.palette.secondary.main = eachVal.value;
            themeData.overrides.MuiListItem.button['&:hover'].backgroundColor =
              eachVal.value;
          } else if (eachVal.key === 'logo_url') {
            themeData.logo = eachVal.value;
          } else if (eachVal.key === 'login_banner') {
            themeData.banner = eachVal.value;
          } else if (eachVal.key === 'footer_color') {
            themeData.footerColor = eachVal.value;
          } else if (eachVal.key === 'header_color') {
            themeData.headerColor = eachVal.value;
          }
        });
        localStore.setValue('themeData', themeData);
      }

      this.setState({
        theme: themeData,
      });
    } catch (ex) {
      this.setState({
        theme: themeData,
      });
    }
  }

  render() {
    const { children, appConfig } = this.props;
    const { theme } = this.state;

    if (!theme || get(appConfig, 'inProgress')) {
      return <Loader setting="secondary" />;
    }

    let defaultTheme = createMuiTheme(theme);
    const defaultSecondaryColor = defaultTheme.palette.secondary.light
      .replace(')', ', 0.4)')
      .replace('rgb', 'rgba');
    defaultTheme.overrides.MuiListItem.button[
      '&:hover'
    ].backgroundColor = defaultSecondaryColor;
    defaultTheme = createMuiTheme({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        action: {
          ...defaultTheme.palette.action,
          hover: defaultSecondaryColor,
          selected: defaultSecondaryColor,
        },
      },
    });

    return (
      <MuiThemeProvider theme={defaultTheme}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyles />
            {children}
          </ThemeProvider>
        </StylesProvider>
      </MuiThemeProvider>
    );
  }
}

AppThemeWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

const mapStateToProps = state => ({
  userDetailsData: get(state, 'userDetails'),
  appConfig: get(state, 'appConfig'),
});

const mapDispatchToProps = {
  setSchoolConfig: dispatchSetSchoolConfig,
  setGstConfig: dispatchFetchGstConfig,
  getAppConfig,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppThemeWrapper)
);
