import { get } from 'lodash';
import SkRequest from './BaseRequest';
import { QueryBuilder, MutationBuilder } from '../queryBuilder';
import {
  LOGIN_USER,
  FORGOT_PASSWORD_REQUEST,
  REGISTER_USER_PASSWORD,
  RESET_USER_PASSWORD,
  APPROVER_ACCESS,
  FETCH_USER_DETAILS,
  ACCESSIBLE_CENTRES,
  VALIDATE_REGISTERED_USER,
  FETCH_SSO_URL,
  LINKPASS_USER_INFO_FROM_CLAIM,
  LOGIN_BY_LINKPASS,
  LINK_USER_WITH_LINKPASS,
  LINK_USER_WITH_LINKPASS_AND_LOGIN,
  REGISTER_WITH_LINKPASS,
  LINKPASS_SN2_USER_INFO,
} from './actionTypes';
import {
  ME,
  LOGIN,
  GET_APPROVER_LIST,
  LinkUserWithLinkPassAndLogin,
  LinkpassSN2UserInfo,
  LinkpassUserInfoFromClaim,
  LoginByLinkPass,
  LinkUserWithLinkPass,
  RegisterWithLinkpass,
  RegisterWithLinkPassInfo,
} from '../models/authModels';
import { SkApolloRequest } from '../../utils/apolloUtils';
import localstorage from '../../utils/localStorage';

const _mQueryBuilder = new QueryBuilder();
const _mMutationAuthBuilder = new MutationBuilder();

_mQueryBuilder.setNewQuery('getAccessibleCentresForModule', {
  data: {
    ID: 'number',
    fkSchool: 'number',
    label: 'String',
  },
});

_mQueryBuilder.setNewQuery('getLoginUrl', {});

_mMutationAuthBuilder.setNewMutation('forgetPassword', {});
_mMutationAuthBuilder.setNewMutation('register', {
  ID: 'int',
  firstname: 'string',
  lastname: 'string',
  email: 'string',
  active: 'boolean',
  createdAt: 'datestamp',
  updatedAt: 'datestamp',
});
_mMutationAuthBuilder.setNewMutation('resetPassword', {});
_mQueryBuilder.setNewQuery('validateUser', {
  email: 'string',
  registered: 'boolean',
  phoneNumber: 'number',
});

_mMutationAuthBuilder.setNewMutation('sendOTP', {});
_mMutationAuthBuilder.setNewMutation('validateOTP', {});

const dispatchApproverList = (
  dispatch,
  inProgress = true,
  data = null,
  error = null
) => {
  dispatch({
    type: APPROVER_ACCESS,
    key: 'approverList',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAcessibleCentres = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: ACCESSIBLE_CENTRES,
    key: 'approverList',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchLogin = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: LOGIN_USER,
    key: 'login',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchLinkpassUserInfoFromClaim = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: LINKPASS_USER_INFO_FROM_CLAIM,
    key: 'linkpassUserInfoFromClaim',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchRegisterWithLinkPassInfo = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: RegisterWithLinkPassInfo,
    key: 'registerWithLinkPassInfo',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchLoginByLinkpass = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: LOGIN_BY_LINKPASS,
    key: 'loginByLinkpass',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchLinkpassSn2UserInfo = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: LINKPASS_SN2_USER_INFO,
    key: 'linkpassSn2UserInfo',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchLinkUserWithLinkPassAndLogin = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: LINK_USER_WITH_LINKPASS_AND_LOGIN,
    key: 'linkUserWithLinkpassAndLogin',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchRegisterWithLinkPass = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: REGISTER_WITH_LINKPASS,
    key: 'registerWithLinkPass',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchLinkUserWithLinkpass = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: LINK_USER_WITH_LINKPASS,
    key: 'linkUserWithLinkpass',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSSOURL = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_SSO_URL,
    key: 'sso_url',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchForgot = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FORGOT_PASSWORD_REQUEST,
    key: 'forgot',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchValidateUser = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: VALIDATE_REGISTERED_USER,
    key: 'validateUser',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const validateUser = tokenString => async dispatch => {
  dispatchValidateUser(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('validateUser', {
          tokenString,
        }),
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchValidateUser(dispatch, false, data);
    } else {
      dispatchValidateUser(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchValidateUser(dispatch, false, null, { error: ex.message });
  }
};

export const fetchApproverList = module => async dispatch => {
  dispatchApproverList(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_APPROVER_LIST,
        variables: {
          module,
          accessType: 'approve',
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchApproverList(dispatch, false, data);
    } else {
      dispatchApproverList(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchApproverList(dispatch, false, null, { error: ex.message });
  }
};
const isEP = process.env.REACT_APP_ENRICHMENT_PLATFORM === 'true';

export const fetchAccessibleCentreForModule = (
  module,
  accessType = 'read',
  includeClosedCentres = false,
  pagination = {}
) => async dispatch => {
  dispatchAcessibleCentres(dispatch);
  if (isEP) {
    const correctedModule = module === 'centre' ? 'enrichment_centre' : module;
    module = correctedModule;
  }
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_APPROVER_LIST,
        variables: {
          module,
          accessType,
          includeClosedCentres,
          pagination,
        },
      },
      type: 'query',
    });

    if (data.success) {
      dispatchAcessibleCentres(dispatch, false, data);
    } else {
      dispatchAcessibleCentres(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchAcessibleCentres(dispatch, false, null, { error: ex.message });
  }
};

export const forgotPassword = email => async dispatch => {
  dispatchForgot(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: _mMutationAuthBuilder.getMutationAsString('forgetPassword', {
          email,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchForgot(dispatch, false, data);
    } else {
      dispatchForgot(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchForgot(dispatch, false, null, { error: ex.message });
  }
};

export const loginByLinkpass = linkPassIdToken => async dispatch => {
  dispatchLoginByLinkpass(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: LoginByLinkPass,
        variables: {
          linkPassIdToken,
        },
      },
      type: 'query',
    });

    if (data.success) {
      localstorage.setValue('userToken', get(data, 'data.loginByLinkPass'));
      dispatchLoginByLinkpass(dispatch, false, data, null);
    } else {
      dispatchLoginByLinkpass(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchLoginByLinkpass(dispatch, false, null, errorMessage);
    return { error: errorMessage };
  }
};

export const linkpassUserInfoFromClaim = linkPassIdToken => async dispatch => {
  dispatchLinkpassUserInfoFromClaim(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: LinkpassUserInfoFromClaim,
        variables: {
          linkPassIdToken,
        },
      },
      type: 'query',
    });

    if (data.success) {
      dispatchLinkpassUserInfoFromClaim(dispatch, false, data, null);
    } else {
      dispatchLinkpassUserInfoFromClaim(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchLinkpassUserInfoFromClaim(dispatch, false, null, errorMessage);
    return { error: errorMessage };
  }
};

export const registerWithLinkPassInfo = (
  registerToken,
  linkPassIdToken
) => async dispatch => {
  dispatchRegisterWithLinkPassInfo(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: RegisterWithLinkPassInfo,
        variables: {
          token: registerToken,
          linkPassIdToken,
        },
      },
      type: 'mutation',
    });
    if (data.success) {
      dispatchRegisterWithLinkPassInfo(dispatch, false, data, null);
    } else {
      dispatchRegisterWithLinkPassInfo(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchRegisterWithLinkPassInfo(dispatch, false, null, errorMessage);
    return { error: errorMessage };
  }
};

export const registerWithLinkPass = (
  registerToken,
  linkPassIdToken
) => async dispatch => {
  dispatchRegisterWithLinkPass(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: RegisterWithLinkpass,
        variables: {
          token: registerToken,
          linkPassIdToken,
        },
      },
      type: 'mutation',
    });
    if (data.success) {
      dispatchRegisterWithLinkPass(dispatch, false, data, null);
    } else {
      dispatchRegisterWithLinkPass(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchRegisterWithLinkPass(dispatch, false, null, errorMessage);
    return { error: errorMessage };
  }
};

export const linkUserWithLinkPass = (
  email,
  linkPassIdToken
) => async dispatch => {
  dispatchLinkUserWithLinkpass(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: LinkUserWithLinkPass,
        variables: {
          email,
          linkPassIdToken,
        },
      },
      type: 'mutation',
    });

    if (data.success) {
      dispatchLinkUserWithLinkpass(dispatch, false, data, null);
    } else {
      dispatchLinkUserWithLinkpass(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchLinkUserWithLinkpass(dispatch, false, null, errorMessage);
    return { error: errorMessage };
  }
};

export const linkUserWithLinkPassAndLogin = (
  email,
  password,
  linkPassIdToken
) => async dispatch => {
  dispatchLinkUserWithLinkPassAndLogin(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: LinkUserWithLinkPassAndLogin,
        variables: {
          email,
          password,
          linkPassIdToken,
        },
      },
      type: 'query',
    });
    if (data.success) {
      localstorage.setValue(
        'userToken',
        get(data, 'data.linkUserWithLinkPassAndLogin')
      );

      dispatchLinkUserWithLinkPassAndLogin(dispatch, false, data, null);
    } else {
      dispatchLinkUserWithLinkPassAndLogin(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchLinkUserWithLinkPassAndLogin(dispatch, false, null, errorMessage);
    return { error: errorMessage };
  }
};

export const linkpassSn2UserInfo = (
  email,
  password,
  linkPassIdToken
) => async dispatch => {
  dispatchLinkpassSn2UserInfo(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: LinkpassSN2UserInfo,
        variables: {
          email,
          password,
          linkPassIdToken,
        },
      },
      type: 'query',
    });
    if (data.success) {
      dispatchLinkpassSn2UserInfo(dispatch, false, data, null);
    } else {
      dispatchLinkpassSn2UserInfo(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchLinkpassSn2UserInfo(dispatch, false, null, errorMessage);
    return { error: errorMessage };
  }
};

export const getSSOURL = fkSchool => async dispatch => {
  dispatchSSOURL(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('getLoginUrl', {
          fkSchool,
        }),
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchSSOURL(dispatch, false, data);
    } else {
      dispatchSSOURL(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchSSOURL(dispatch, false, null, { error: ex.message });
  }
};

const dispatchRegister = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: REGISTER_USER_PASSWORD,
    key: 'register',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const registerPassword = (token, password) => async dispatch => {
  dispatchRegister(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: _mMutationAuthBuilder.getMutationAsString('register', {
          token,
          password,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchRegister(dispatch, false, data);
    } else {
      dispatchRegister(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchRegister(dispatch, false, null, { error: ex.message });
  }
};

const dispatchReset = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: RESET_USER_PASSWORD,
    key: 'reset',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchUserDetails = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_USER_DETAILS,
    key: 'userDetails',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const sendOtp = userId => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationAuthBuilder.getMutationAsString('sendOTP', userId),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const validateOtp = reqData => async () => {
  try {
    const data = await SkRequest({
      data: {
        query: _mMutationAuthBuilder.getMutationAsString(
          'validateOTP',
          reqData
        ),
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const resetPassword = (tokenString, password) => async dispatch => {
  dispatchReset(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: _mMutationAuthBuilder.getMutationAsString('resetPassword', {
          tokenString,
          password,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchReset(dispatch, false, data);
    } else {
      dispatchReset(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchReset(dispatch, false, null, { error: ex.message });
  }
};

export const fetchUserDetails = () => async dispatch => {
  dispatchUserDetails(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: ME,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchUserDetails(dispatch, false, data.data);
    } else {
      dispatchUserDetails(dispatch, false, null, data.error);
    }
    return data.data;
  } catch (ex) {
    dispatchUserDetails(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const loginUser = (email, password) => async dispatch => {
  dispatchLogin(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: LOGIN,
        variables: {
          email,
          password,
        },
      },
      type: 'query',
    });
    if (data.success) {
      localstorage.setValue('userToken', data.data.login);
      dispatchLogin(dispatch, false, data, null);
    } else {
      dispatchLogin(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchLogin(dispatch, false, null, errorMessage);
    return { error: errorMessage };
  }
};

export default {
  loginUser,
  fetchUserDetails,
  validateUser,
  fetchApproverList,
  fetchAccessibleCentreForModule,
  forgotPassword,
  loginByLinkpass,
  linkpassUserInfoFromClaim,
  registerWithLinkPass,
  linkUserWithLinkPass,
  linkUserWithLinkPassAndLogin,
  linkpassSn2UserInfo,
  getSSOURL,
  registerPassword,
  sendOtp,
  validateOtp,
  resetPassword,
};
