import {
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_LOADING,
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_SUCCESS,
  GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_ERROR,
} from '../actions/actionTypes';

const DEFAULT_STATE = {
  inProgress: false,
  data: null,
  error: null,
};

/* eslint-disable import/prefer-default-export */
export const listEnrollmentPlanningRequests = (
  state = DEFAULT_STATE,
  action
) => {
  switch (action.type) {
    case GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_LOADING:
      return {
        ...state,
        ...action.value,
      };
    case GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_SUCCESS:
      return {
        ...state,
        ...action.value,
      };
    case GET_LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS_ERROR:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};
