import { get, isEmpty, sum } from 'lodash';
import {
  FETCH_BROADCASTS,
  SEARCH_BROADCASTS,
  FETCH_BROADCAST_BY_ID,
  MARK_BROADCAST_AS_READ,
} from './actionTypes';
import { SkApolloRequest } from '../../utils/apolloUtils';
import {
  GET_BROADCASTING,
  SEARCH_BROADCASTING_SCOPE,
  DELETE_BROADCASTING_BY_ID,
  MARK_BROADCAST_READ,
  GET_BROADCASTING_PHOTO_UPLOAD_URL,
  SAVE_BROADCASTING,
} from '../models/broadastModel';

const dispatchFetchBroadcasts = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_BROADCASTS,
    key: 'broadcasts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchBroadcastById = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_BROADCAST_BY_ID,
    key: 'broadcastById',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSearchBroadcasts = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: SEARCH_BROADCASTS,
    key: 'broadcasts',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchMarkBroadcastAsRead = (dispatch, broadCastID) => {
  dispatch({
    type: MARK_BROADCAST_AS_READ,
    key: 'markBroadcast',
    value: broadCastID,
  });
};

export const fetchBroadcasts = reqData => async dispatch => {
  dispatchFetchBroadcasts(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_BROADCASTING,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatchFetchBroadcasts(dispatch, false, data.data);
    } else {
      dispatchFetchBroadcasts(dispatch, false, null, data.error);
    }
    return data;
  } catch (ex) {
    dispatchFetchBroadcasts(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const clearBroadcastSearch = () => dispatch => {
  dispatch({
    type: SEARCH_BROADCASTS,
    key: 'broadcasts',
    value: {
      inProgress: false,
      data: [],
      error: null,
      reachedEnd: false,
    },
  });
};

export const fetchBroadcastById = reqData => async dispatch => {
  dispatchFetchBroadcastById(dispatch);
  try {
    const data = await SkApolloRequest({
      params: {
        query: GET_BROADCASTING,
        variables: reqData,
      },
      type: 'query',
    });

    if (data.success) {
      dispatchFetchBroadcastById(dispatch, false, data.data);
    } else {
      dispatchFetchBroadcastById(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchFetchBroadcastById(dispatch, false, null, {
      error: ex.message,
    });
  }
};

export const updateSearchBroadcastingEnd = (reachedEnd = false) => dispatch => {
  dispatch({
    type: SEARCH_BROADCASTS,
    key: 'broadcasts',
    value: {
      reachedEnd,
    },
  });
};

export const searchBroadcastingScope = reqData => async dispatch => {
  const page = get(reqData, 'pagination.page', 1);
  const perPage = get(reqData, 'pagination.perPage', 10);
  if (page === 1) updateSearchBroadcastingEnd(false)(dispatch);

  dispatch({
    type: SEARCH_BROADCASTS,
    key: 'broadcasts',
    value: {
      inProgress: true,
      error: null,
    },
  });
  try {
    const data = await SkApolloRequest({
      params: {
        query: SEARCH_BROADCASTING_SCOPE,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      const isLoadedMore = page > 1;
      const broadcastingScopeData = get(
        data,
        'data.searchBroadcastingScope',
        {}
      );
      const listChild = get(broadcastingScopeData, 'listChild.data', []);
      const listCentre = get(broadcastingScopeData, 'listCentre.data', []);
      const listClass = get(broadcastingScopeData, 'listClass.data', []);
      const totalBroadcastingScope = sum([
        get(broadcastingScopeData, 'listChild.totalCount', 0),
        get(broadcastingScopeData, 'listCentre.totalCount', 0),
        get(broadcastingScopeData, 'listClass.totalCount', 0),
      ]);

      if (
        isLoadedMore &&
        isEmpty(listChild) &&
        isEmpty(listClass) &&
        isEmpty(listCentre)
      ) {
        dispatch({
          type: SEARCH_BROADCASTS,
          key: 'broadcasts',
          value: {
            inProgress: false,
            error: null,
            reachedEnd: true,
          },
        });
        return;
      }
      clearBroadcastSearch(dispatch);
      if (totalBroadcastingScope < perPage && page === 1)
        updateSearchBroadcastingEnd(true)(dispatch);

      dispatchSearchBroadcasts(dispatch, false, data.data);
    } else {
      dispatchSearchBroadcasts(dispatch, false, null, data.error);
    }
  } catch (ex) {
    dispatchSearchBroadcasts(dispatch, false, null, { error: ex.message });
  }
};

export const saveBroadcast = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: SAVE_BROADCASTING,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const deleteBroadcast = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: DELETE_BROADCASTING_BY_ID,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const getBroadcastPhotoUploadURL = reqData => async () => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: GET_BROADCASTING_PHOTO_UPLOAD_URL,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const markBroadcastingAsRead = reqData => async dispatch => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: MARK_BROADCAST_READ,
        variables: reqData,
      },
      type: 'mutation',
    });

    if (data.success) {
      dispatchMarkBroadcastAsRead(dispatch, reqData.IDBroadcastings[0]);
    }
  } catch (ex) {
    return ex;
  }
};
