import localStore from './localStorage';
import { getCountryCode } from './countryCodeUtils';
import { LOGIN } from '../routes/constants';

export const isEmailSame = (sn2Details = {}, linkpassDetails = {}) => {
  if (!sn2Details.email && !linkpassDetails.Email) {
    return true;
  }
  return sn2Details?.email === linkpassDetails?.Email;
};

export const isCountryCodeSame = (sn2Details = {}, linkpassDetails = {}) => {
  if (!sn2Details.mobilePhoneCountryCode && !linkpassDetails.CountryCode) {
    return true;
  }
  return (
    getCountryCode(sn2Details.mobilePhoneCountryCode) ===
    getCountryCode(linkpassDetails.CountryCode)
  );
};

export const isPhoneNumberSame = (sn2Details = {}, linkpassDetails = {}) => {
  if (
    isCountryCodeSame(sn2Details, linkpassDetails) &&
    sn2Details.mobilePhone === linkpassDetails.PhoneNumber
  ) {
    return true;
  }
  return false;
};

export const isDetailsNotMatch = (sn2Details, linkpassDetails) => {
  return (
    !isEmailSame(sn2Details, linkpassDetails) ||
    !isPhoneNumberSame(sn2Details, linkpassDetails)
  );
};

export const logoutUser = () => {
  localStore.setValue('linkpassIDToken', null);
  localStore.setValue('userToken', null);
  localStore.setValue('selectedChild', null);
  localStore.setValue('themeData', null);
  localStore.setValue('schoolID', null);
  window.location.href = LOGIN;
};
