import { MutationBuilder, QueryBuilder } from '../../queryBuilder';
import SkRequest from '../BaseRequest';

import {
  FETCH_USER_DOCUMENTS,
  FETCH_ALL_DOCUMENT_TYPE,
  FETCH_UPLOADED_DOCUMENTS,
  FETCH_DOCUMENT_TYPE_TO_UPLOAD,
} from '../actionTypes';

import {
  GET_DOCUMENT_URL,
  SEND_ENQUIRY_DOCUMENT_NOTIFICATION,
} from '../../models/documentModels';
import apolloUtils, { SkApolloRequest } from '../../../utils/apolloUtils';

const _mMutationBuilder = new MutationBuilder();
const _mQueryBuilder = new QueryBuilder();

_mMutationBuilder.setNewMutation('submitForEndorsement', {});

_mMutationBuilder.setNewMutation('setDocumentUploadAsCompleted', {});

_mMutationBuilder.setNewMutation('saveUploadedDocuments', {
  ID: 'number',
  filename: 'string',
  label: 'string',
  remark: 'string',
  active: 'bool',
});

_mMutationBuilder.setNewMutation('saveDocumentsByIDEnquiry', {
  ID: 'number',
  filename: 'string',
  label: 'string',
  remark: 'string',
  active: 'bool',
});

_mMutationBuilder.setNewMutation('removeDocumentByIDEnquiry', {
  ID: 'number',
  label: 'string',
  active: 'bool',
});

_mMutationBuilder.setNewMutation('getEnquiryDocumentUploadUrl', {
  Url: 'string',
  Key: 'string',
});

_mQueryBuilder.setNewQuery('getUploadedDocument', {
  data: {
    ID: 'number',
    filename: 'string',
    updatedAt: 'timestamp',
    fkChild: 'number',
    label: 'string',
    uRL: 'string',
    remark: 'string',
    documentTags: {
      data: {
        fkDocumentType: 'number',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('documentTypeConfigsByFkDocumentTypeConfig', {
  data: {
    code: 'int',
    label: 'string',
    fkDocumentTypeConfig: 'int',
    ID: 'int',
  },
});

_mQueryBuilder.setNewQuery('listRequiredDocuments', {
  DocumentTypeID: 'number',
  ParentID: 'number',
  ChildID: 'number',
  Fullname: 'string',
  DocumentType: 'string',
  Status: 'string',
  Remarks: 'string',
  UploadAccess: 'number',
});

const dispatchdocumentTypeConfig = (
  dispatch,
  inProgress = true,
  data = [],
  error = null
) => {
  dispatch({
    type: FETCH_ALL_DOCUMENT_TYPE,
    key: 'documentTypeConfig',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchdocumentNeedUpload = (
  dispatch,
  inProgress = true,
  data = null,
  error = null
) => {
  dispatch({
    type: FETCH_DOCUMENT_TYPE_TO_UPLOAD,
    key: 'documentTypeConfig',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchUploadedDocumentListing = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_UPLOADED_DOCUMENTS,
    key: 'uploadedDocumentList',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchDocumentListing = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_USER_DOCUMENTS,
    key: 'documentsListing',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const markDocumentUploadComplete = IDEnquiry => async () => {
  try {
    return await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'setDocumentUploadAsCompleted',
          {
            IDEnquiry,
          }
        ),
      },
      method: 'POST',
    });
  } catch (ex) {
    throw ex;
  }
};

export const submitFilesForEndorsement = IDEnquiry => async () => {
  try {
    return await SkRequest({
      data: {
        query: _mMutationBuilder.getMutationAsString('submitForEndorsement', {
          IDEnquiry,
        }),
      },
      method: 'POST',
    });
  } catch (ex) {
    throw ex;
  }
};

export const removeUploadedDocument = (IDDocument, IDEnquiry) => async () => {
  if (!IDDocument) {
    return null;
  }

  const query = _mMutationBuilder.getMutationAsString(
    'removeDocumentByIDEnquiry',
    {
      IDDocument,
      IDEnquiry,
    }
  );

  try {
    return await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
  } catch (ex) {
    throw ex;
  }
};

export const saveDocumentStep = (uploadedDocuments, IDEnquiry) => async () => {
  const query = _mMutationBuilder.getMutationAsString('saveUploadedDocuments', {
    uploadedDocuments,
    IDEnquiry,
  });

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const saveDocument = (uploadedDocuments, IDEnquiry) => async () => {
  const query = _mMutationBuilder.getMutationAsString(
    'saveDocumentsByIDEnquiry',
    {
      uploadedDocuments,
      IDEnquiry,
    }
  );

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    throw ex;
  }
};

export const getDocumentUploadURL = reqData => async () => {
  const query = _mMutationBuilder.getMutationAsString(
    'getEnquiryDocumentUploadUrl',
    reqData
  );
  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const fetchUploadedDocuments = (
  IDEnquiry = '',
  documentTypeIDs = []
) => async dispatch => {
  dispatchUploadedDocumentListing(dispatch);

  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('getUploadedDocument', {
          IDEnquiry,
          documentTypeIDs,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchUploadedDocumentListing(dispatch, false, {
        data: data.data,
      });
    } else {
      dispatchUploadedDocumentListing(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchUploadedDocumentListing(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const fetchDocumentTypeToUpload = reqData => async dispatch => {
  dispatchdocumentNeedUpload(dispatch);
  try {
    const res = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString(
          'documentTypeConfigsByFkDocumentTypeConfig',
          reqData
        ),
      },
      method: 'POST',
    });
    if (res.success) {
      dispatchdocumentNeedUpload(dispatch, false, res.data);
    } else {
      dispatchdocumentNeedUpload(dispatch, false, null, res.error);
    }
    return res;
  } catch (ex) {
    dispatchdocumentNeedUpload(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const fetchDocumentTypeConfig = (filter = {}) => async dispatch => {
  dispatchdocumentTypeConfig(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString(
          'documentTypeConfigsByFkDocumentTypeConfig',
          {
            filter: {
              listAccess: 2,
              ...filter,
            },
            pagination: {
              sort: ['createdAt'],
            },
          }
        ),
      },
      method: 'POST',
    });
    if (data.success) {
      dispatchdocumentTypeConfig(dispatch, false, data.data);
    } else {
      dispatchdocumentTypeConfig(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchdocumentTypeConfig(dispatch, false, null, {
      error: ex.message,
    });
    return ex;
  }
};

export const fetchUserDocuments = (IDEnquiry = '') => async dispatch => {
  dispatchDocumentListing(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('listRequiredDocuments', {
          IDEnquiry,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchDocumentListing(dispatch, false, { data: data.data });
    } else {
      dispatchDocumentListing(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchDocumentListing(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const getUploadedDocumentURLs = (IDDocument, IDChild) => async () => {
  const client = await apolloUtils();
  try {
    const result = await client.query({
      query: GET_DOCUMENT_URL,
      variables: {
        IDDocument,
        IDChild,
      },
    });

    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const sendEnquiryDocumentNotification = enquiryID => async () => {
  try {
    return await SkApolloRequest({
      params: {
        mutation: SEND_ENQUIRY_DOCUMENT_NOTIFICATION,
        variables: { enquiryID },
      },
      type: 'mutation',
    });
  } catch (ex) {
    throw ex;
  }
};
