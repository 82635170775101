/* eslint-disable import/no-unresolved */

const getConfig = () => {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page,
  //  if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  const schoolID = parseInt(process.env.REACT_APP_SCHOOL_ID, 10);
  const isEP = process.env.REACT_APP_ENRICHMENT_PLATFORM === 'true';
  let clientId = process.env.REACT_APP_LINKPASS_LSH_CLIENT_ID;
  let domain = process.env.REACT_APP_LINKPASS_DOMAIN;
  let appOrigin = process.env.REACT_APP_PARENT_PORTAL;
  let redirectUri = `${process.env.REACT_APP_PARENT_PORTAL}/lp-sso-callback`;

  if (schoolID === 1) {
    clientId = process.env.REACT_APP_LINKPASS_MFS_CLIENT_ID;
  }

  if (isEP) {
    domain = process.env.REACT_APP_LINKPASS_EP_DOMAIN;
    clientId = process.env.REACT_APP_LINKPASS_MFS_EP_CLIENT_ID;
    appOrigin = process.env.REACT_APP_EP_PARENT_PORTAL;
    redirectUri = `${process.env.REACT_APP_EP_PARENT_PORTAL}/ep-lp-sso-callback`;
  }

  return {
    domain,
    clientId,
    appOrigin,
    redirectUri,
  };
};

export default getConfig;
