import gql from 'graphql-tag';

export const GET_ADMISSION_FILTERS = gql`
  query getAdmissionFilters($IDSchool: Int!, $filter: EnquiryFilter) {
    getAdmissionFilters(IDSchool: $IDSchool, filter: $filter) {
      stages {
        code
        name
        statuses {
          code
          name
        }
      }
    }
  }
`;

export const GET_REGISTRATIONS_FRONTEND_STATES = gql`
  query {
    getRegistrationFrontendStates
  }
`;

export const GET_REGISTRATIONS = gql`
  query getRegistrations(
    $statuses: [String!]
    $searchString: String
    $createdFrom: Datetime
    $createdTo: Datetime
    $enrollmentDateFrom: Datetime
    $enrollmentDateTo: Datetime
    $pagination: Pagination
    $centres: [Int!]
    $levels: [Int!]
    $programs: [Int!]
    $filter: RegistrationFilter
  ) {
    getRegistrations(
      filter: $filter
      statuses: $statuses
      searchString: $searchString
      createdFrom: $createdFrom
      createdTo: $createdTo
      enrollmentDateFrom: $enrollmentDateFrom
      enrollmentDateTo: $enrollmentDateTo
      pagination: $pagination
      IDCentres: $centres
      IDLevels: $levels
      IDPrograms: $programs
    ) {
      totalCount
      data {
        ID
        currentFrontendStatus
        state
        createdAt
        updatedAt
        registrationChildMappings {
          data {
            ID
            # enrollmentPlan
            enrolmentDate
            registrationChild {
              ID
              fkChild
              lastname
              firstname
              birthCertificate
              dateOfBirth
              imageKey
              mainApplicant {
                identificationNo
                firstname
                lastname
                email
                mobilePhone
                mobilePhoneCountryCode
              }
              registrationChildParents(filter: { type: "applicant" }) {
                data {
                  ID
                  registrationParent {
                    firstname
                    lastname
                    mobilePhoneCountryCode
                    mobilePhone
                    email
                  }
                }
              }
            }
            centre {
              code
              label
            }
            level {
              label
            }
            program {
              label
            }
          }
        }
      }
    }
  }
`;

export const SEARCH_ADMISSIONS = gql`
  query searchAdmissions(
    $q: String!
    $filter: EnquiryFilter
    $pagination: Pagination
  ) {
    searchAdmissions(q: $q, filter: $filter, pagination: $pagination) {
      totalCount
      data {
        child {
          ID
          firstname
          lastname
          birthCertificate
        }
        user {
          ID
          firstname
          lastname
          mobilePhoneCountryCode
          mobilePhone
          email
        }
      }
    }
  }
`;

export const SUGGESTED_FEE_TIER = gql`
  query getSuggestedFeeTierForRegistrationChild(
    $registrationID: Int!
    $registrationChildID: Int!
    $enrolmentDate: Datetime!
  ) {
    getSuggestedFeeTierForRegistrationChild(
      registrationID: $registrationID
      registrationChildID: $registrationChildID
      enrolmentDate: $enrolmentDate
    ) {
      data {
        ID
        label
      }
    }
  }
`;

export const FIND_ALL_ADMISSION = gql`
  query findAllAdmission(
    $IDSchool: Int!
    $stageCode: String
    $statusCode: String
    $filter: EnquiryFilter
    $pagination: Pagination
  ) {
    findAllAdmission(
      IDSchool: $IDSchool
      stageCode: $stageCode
      statusCode: $statusCode
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        firstname
        lastname
        childFirstname
        pendingRegisterSince
        source
        createdAt
        updatedAt
        fkEnquiryState
        centre {
          code
          label
        }
        level {
          label
        }
        program {
          label
        }
        enrollmentPlan
        child {
          ID
          firstname
          lastname
          birthCertificate
          dateOfBirth
        }
        user {
          firstname
          lastname
          mobilePhoneCountryCode
          mobilePhone
          email
        }
        admissionStatus {
          ID
          label
        }
      }
    }
  }
`;

export const GET_EXISTING_PARENT = gql`
  query getExistingParent($nric: String!, $email: String) {
    getExistingParent(nric: $nric, email: $email) {
      parent {
        ID
        lastname
        firstname
        email
        mobilePhoneCountryCode
        mobilePhone
        workplaceStaff
        gender
        identificationNo
        nationality
        race
        monthlyIncome
        highestQualification
        maritalStatus
        birthdate
        isEmployed
        relation
        workingStatus
        occupationalTitle
      }
      regParent {
        ID
        lastname
        firstname
        email
        mobilePhoneCountryCode
        mobilePhone
        workplaceStaff
      }
    }
  }
`;

export const GET_EXISTING_PARENT_INFO = gql`
  query getParent($parentFilter: ParentFilter!, $childId: FilterOnField) {
    getParent(parentFilter: $parentFilter) {
      data {
        ID
        userByFkUser(filter: { active: [0, 1] }) {
          ID
        }
        childRelations(filter: { fkChild: $childId, active: [0, 1] }) {
          data {
            fkRelation
            fkChild
          }
        }
        lastname
        firstname
        email
        mobilePhoneCountryCode
        mobilePhone
        workplaceStaff
        gender
        identificationNo
        nationality
        race
        monthlyIncome
        highestQualification
        maritalStatus
        birthdate
        isEmployed
        relation
        workingStatus
        occupationalTitle
      }
    }
  }
`;

export const GET_EXISTING_CHILD = gql`
  query getExistingChild($birthCertificate: String!) {
    getExistingChild(birthCertificate: $birthCertificate) {
      child {
        ID
        firstname
        lastname
        birthCertificate
        dateOfBirth
        childRelations(filter: { type: "applicant" }) {
          data {
            ID
            type
            fkRelation
          }
        }
      }
      mainApplicant {
        ID
        firstname
        lastname
        email
        mobilePhoneCountryCode
        mobilePhone
        workplaceStaff
        identificationNo
      }
      regParent {
        ID
        firstname
        lastname
        email
        mobilePhoneCountryCode
        mobilePhone
        workplaceStaff
        identificationNo
      }
      regChild {
        ID
        firstname
        lastname
        birthCertificate
        dateOfBirth
        registrationChildParents(filter: { type: "applicant" }) {
          data {
            fkRelationship
          }
        }
      }
    }
  }
`;

export const CREATE_REGISTRATION = gql`
  mutation createRegistration($newRegistration: RegistrationDto!) {
    createRegistration(newRegistration: $newRegistration) {
      ID
      registrationChildMappings {
        data {
          fkRegistrationChild
        }
      }
    }
  }
`;

export const EDIT_REGISTRATION = gql`
  mutation editRegistration(
    $enquiryID: Int!
    $editRegistration: RegistrationDto!
    $remarks: String
  ) {
    editRegistration(
      IDRegistration: $enquiryID
      editRegistration: $editRegistration
      remarks: $remarks
    ) {
      ID
      registrationChildMappings {
        data {
          fkRegistrationChild
        }
      }
    }
  }
`;

export const CANCEL_REGISTRATION = gql`
  mutation cancelRegistration(
    $enquiryID: Int!
    $isReapplication: Boolean!
    $remarks: String
    $cancellationReason: Int!
  ) {
    cancelRegistration(
      IDRegistration: $enquiryID
      isReapplication: $isReapplication
      remarks: $remarks
      IDCancellationReason: $cancellationReason
    ) {
      ID
    }
  }
`;

export const REINSTATE_CANCELLED_REGISTRATION = gql`
  mutation reinstateCancelledRegistration(
    $registrationID: Int!
    $remarks: String
  ) {
    reinstateCancelledRegistration(
      IDRegistration: $registrationID
      remarks: $remarks
    )
  }
`;

export const GET_ALL_GST_VALUES = gql`
  query GetAllGstValues($filter: GstConfigurationFilter) {
    getAllGstValues(filter: $filter) {
      totalCount
      data {
        ID
        from
        to
        rate
        active
      }
    }
  }
`;
