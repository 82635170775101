import gql from 'graphql-tag';
import { HOLIDAY_FRAGMENT } from './commonFragments';

export const GET_HOILDAY_CLOSURES = gql`
  query getCentreHolidaysOfYear(
    $year: Int!
    $month: Int
    $day: Int
    $centreIDs: [Int!]
    $filter: HolidayFilter
    $pagination: Pagination
  ) {
    getCentreHolidaysOfYear(
      year: $year
      month: $month
      day: $day
      IDCentres: $centreIDs
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        title
        description
        holidayImageKey
        from
        to
        isPublic
        centreHolidays {
          totalCount
          data {
            centre {
              label
              ID
            }
          }
        }
      }
    }
  }
`;

export const EVENT_LISTING_OBJECT = `{
    totalCount
    data {
        ID,
        fkCreator,
        fkApprover,
        title,
        attendeesCount {
            CountMapping
        },
        description,
        location,
        eventImageKey,
        eventType,
        eventScopes {
            data {
                centre {
                    label
                },
                class {
                    label
                    level {
                      label
                    }
                },
                school {
                    name
                }
            }
        },
        from,
        to,
        guestCountLimit, 
        parentConsentRequired,
        status
        userByFkCreator {
          firstname
          lastname
        }
        creator {
          displayName
          user {
            firstname
            lastname
          }
        }
    }
}`;

export const EVENT_RSVP_COUNT = `{
            ChildCount
            ClassCount
            CentreCount
            GuestCount
}`;

export const EVENT_RSVP_LIST = `{
    totalCount
    data {
        ID
        rsvpAckFkUser
        rsvpRemoveFkUser
        userByRsvpAckFkUser {
            ID
            firstname
            lastname
        },
        userByRsvpRemoveFkUser {
            ID
            firstname
            lastname
        },
        child {
            ID
            firstname
            lastname
            childRelations {
                data {
                    ID
                    parent {
                        firstname
                        lastname
                    }
                }
            },
            currentClass {
                class {
                    label
                }
            }
        },
        eventRsvpGuests {
            totalCount
            data {
                ID
                name
                mobilePhone
                fkRelation
            }
        }
    }
}`;

export const EVENT_LISTING_OBJ = `
    totalCount
    data {
        ID,
        fkCreator,
        fkApprover,
        title,
        attendeesCount {
            CountMapping
        },
        description,
        location,
        eventImageKey,
        eventType,
        eventScopes {
            data {
                centre {
                    label
                },
                class {
                    label
                    level {
                      label
                    }
                },
                school {
                    name
                }
            }
        },
        from,
        to,
        guestCountLimit,
        parentConsentRequired,
        status
        userByFkCreator {
          firstname
          lastname
        }
        creator {
          displayName
          user {
            firstname
            lastname
          }
        }
}`;

export const GET_ALL_EVENTS = gql`
    query getAllEvents(
        $from: Datetime
        $to: Datetime
        $schoolID: Int!
        $fkCentre: [Int!]
        $fkLevel: [Int!]
        $fkClass: [Int!]
        $filter: EventFilter
        $pagination: Pagination
        ) {
        getAllEvents(
            from: $from,
            to: $to,
            IDSchool: $schoolID,
            fkCentre: $fkCentre,
            fkLevel: $fkLevel,
            fkClass: $fkClass,
            filter: $filter,
            pagination: $pagination
        ) {
            ${EVENT_LISTING_OBJ}
    }
}`;

export const GET_DRAFT_EVENTS = gql`
    query getDraftEvents($filter: EventFilter, $pagination: Pagination, $IDSchool: Int!) {
        getDraftEvents(filter: $filter, pagination: $pagination, IDSchool: $IDSchool) {
            ${EVENT_LISTING_OBJ}
    }
}`;

export const GET_PENDING_EVENTS = gql`
    query getPendingEvents($filter: EventFilter, $pagination: Pagination, $IDSchool: Int!,$fkCentre: [Int!], $fkLevel: [Int!], $fkClass: [Int!], $eventType: [Int!]) {
      getPendingEvents(filter: $filter, pagination: $pagination, IDSchool: $IDSchool, fkCentre: $fkCentre, fkLevel: $fkLevel, fkClass: $fkClass, eventType: $eventType) {
            ${EVENT_LISTING_OBJ}
    }
}`;

export const GET_UPCOMING_EVENTS = gql`
    query getUpcomingEvents($filter: EventFilter, $pagination: Pagination, $IDSchool: Int!,$fkCentre: [Int!], $fkLevel: [Int!], $fkClass: [Int!], $eventType: [Int!]) {
        getUpcomingEvents(filter: $filter, pagination: $pagination, IDSchool: $IDSchool, fkCentre: $fkCentre, fkLevel: $fkLevel, fkClass: $fkClass, eventType: $eventType) {
            ${EVENT_LISTING_OBJ}
    }
}`;

export const GET_PAST_EVENTS = gql`
    query getPastEvents($filter: EventFilter, $pagination: Pagination, $IDSchool: Int!,$fkCentre: [Int!], $fkLevel: [Int!], $fkClass: [Int!], $eventType: [Int!]) {
        getPastEvents(filter: $filter, pagination: $pagination, IDSchool: $IDSchool, fkCentre: $fkCentre, fkLevel: $fkLevel, fkClass: $fkClass, eventType: $eventType) {
            ${EVENT_LISTING_OBJ}
    }
}`;

export const EVENT_BY_ID = gql`
  query eventById($filter: EventFilter, $IDEvent: Int!) {
    eventById(filter: $filter, IDEvent: $IDEvent) {
      ID
      fkCreator
      fkApprover
      status
      title
      description
      eventType
      from
      to
      publishDate
      cancellationDate
      rsvpDeadline
      eventImageKey
      parentConsentRequired
      termsAndConditions
      paymentRequired
      childEventFee
      guestEventFee
      guestsAllowed
      guestsDetailsRequired
      guestCountLimit
      location
      eventScopes {
        data {
          fkEvent
          fkSchool
          fkCentre
          fkClass
          centre {
            ID
            label
          }
          class {
            ID
            label
            fkLevel
            level {
              label
            }
          }
          school {
            ID
            name
          }
        }
      }
      eventPhotos {
        totalCount
        data {
          ID
          fileKey
          fkEvent
          status
          active
        }
      }
      userByFkCreator {
        firstname
        lastname
      }
      creator {
        displayName
        user {
          firstname
          lastname
        }
      }
    }
  }
`;

export const EXPORT_RSVP_LIST = gql`
  query exportRsvpList($filter: EventRsvpFilter, $IDEvent: Int!) {
    exportRsvpList(filter: $filter, IDEvent: $IDEvent)
  }
`;

export const GET_RSVP_STATUS_COUNT_DETAILS = gql`
  query getRsvpStatusCountDetails($IDEvent: Int!) {
    getRsvpStatusCountDetails(IDEvent: $IDEvent) {
      CountMapping
    }
  }
`;

export const SUBMIT_EVENT = gql`
  mutation submitEvent(
    $IDEvent: Int
    $event: EventCreate!
    $IDSchool: Int!
    $IDCentre: Int!
    $IDClasses: [Int!]!
  ) {
    submitEvent(
      IDEvent: $IDEvent
      event: $event
      IDSchool: $IDSchool
      IDCentre: $IDCentre
      IDClasses: $IDClasses
    ) {
      ID
    }
  }
`;

export const GET_EVENT_PHOTO_UPLOAD_URL = gql`
  mutation getEventPhotoUploadUrl($IDSchool: Int!, $file: Upload!) {
    getEventPhotoUploadUrl(IDSchool: $IDSchool, file: $file) {
      Url
      Key
    }
  }
`;

export const GET_EVENT_PHOTO_ALBUM_UPLOAD_URL = gql`
  mutation getEventPhotoAlbumUploadUrl($IDEvent: Int!, $file: Upload!) {
    getEventPhotoAlbumUploadUrl(IDEvent: $IDEvent, file: $file) {
      Url
      Key
      Filename
    }
  }
`;

export const CANCEL_EVENT = gql`
  mutation cancelEvent($IDEvent: Int!, $remarks: String) {
    cancelEvent(IDEvent: $IDEvent, remarks: $remarks)
  }
`;

export const PUBLISH_EVENT = gql`
  mutation publishEvent($IDEvent: Int!) {
    publishEvent(IDEvent: $IDEvent) {
      ID
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEventDraft($IDEvent: Int!) {
    deleteEventDraft(IDEvent: $IDEvent)
  }
`;

export const CREATE_OR_SAVE_EVENT_DRAFT = gql`
  mutation createOrSaveEventDraft(
    $IDEvent: Int
    $IDCentre: Int!
    $IDSchool: Int!
    $IDClasses: [Int!]!
    $event: EventUpdate!
  ) {
    createOrSaveEventDraft(
      IDEvent: $IDEvent
      IDCentre: $IDCentre
      IDSchool: $IDSchool
      IDClasses: $IDClasses
      event: $event
    ) {
      ID
    }
  }
`;

export const UPDATE_EVENT_DETAILS = gql`
  mutation updateEventDetails(
    $IDEvent: Int!
    $IDCentre: Int!
    $IDSchool: Int!
    $IDClasses: [Int!]!
    $event: EventCreate!
  ) {
    updateEventDetails(
      IDEvent: $IDEvent
      IDCentre: $IDCentre
      IDSchool: $IDSchool
      IDClasses: $IDClasses
      event: $event
    ) {
      ID
    }
  }
`;

export const REMOVE_CHILD_FROM_RSVP_LIST = gql`
  mutation removeChildFromRsvpList($IDEvent: Int!, $IDChild: Int!) {
    removeChildFromRsvpList(IDEvent: $IDEvent, IDChild: $IDChild)
  }
`;

export const ADD_CHILD_TO_RSVP_LIST = gql`
  mutation addChildIntoRsvpList($IDEvent: Int!, $IDChild: Int!) {
    addChildIntoRsvpList(IDEvent: $IDEvent, IDChild: $IDChild)
  }
`;

export const REMOVE_EVENT_RSVP_GUEST = gql`
  mutation removeEventRsvpGuest(
    $IDEvent: Int!
    $IDEventRsvp: Int!
    $IDEventRsvpGuest: Int!
  ) {
    removeEventRsvpGuest(
      IDEvent: $IDEvent
      IDEventRsvp: $IDEventRsvp
      IDEventRsvpGuest: $IDEventRsvpGuest
    )
  }
`;

export const ADD_EVENT_PHOTOS = gql`
  mutation addEventPhotos($IDEvent: Int!, $fileKeys: [String!]!) {
    addEventPhotos(IDEvent: $IDEvent, fileKeys: $fileKeys)
  }
`;

export const DELETE_EVENT_PHOTOS = gql`
  mutation deleteEventPhotos($IDEvent: Int!, $IDPhotos: [Int!]!) {
    deleteEventPhotos(IDEvent: $IDEvent, IDPhotos: $IDPhotos)
  }
`;

export const UPDATE_EVENT_PHOTO_STATUS = gql`
  mutation updateEventPhotosStatus(
    $IDEvent: Int!
    $IDPhotos: [Int!]!
    $publish: Boolean!
  ) {
    updateEventPhotosStatus(
      IDEvent: $IDEvent
      IDPhotos: $IDPhotos
      publish: $publish
    )
  }
`;

export const PENDING_EVENT_GROUP_OBJECT = `{
  Total
  Data {
    CentreID
    CentreName
    Count
  }
}`;

export const CREATE_HOLIDAY = gql`
  mutation createHoliday(
    $holiday: HolidayCreate!
    $centreIDList: [Int!]!
    $ignoreAppNotification: Boolean
  ) {
    createHoliday(
      holiday: $holiday
      centreIDList: $centreIDList
      ignoreAppNotification: $ignoreAppNotification
    ) {
      ...Holiday_Fragment
    }
  }
  ${HOLIDAY_FRAGMENT}
`;

export const UPDATE_HOLIDAY = gql`
  mutation updateHolidayDetails(
    $holidayID: Int!
    $updateHoliday: HolidayCreate!
    $centreIDList: [Int!]!
    $ignoreAppNotification: Boolean
  ) {
    updateHolidayDetails(
      holidayID: $holidayID
      updateHoliday: $updateHoliday
      centreIDList: $centreIDList
      ignoreAppNotification: $ignoreAppNotification
    ) {
      ...Holiday_Fragment
    }
  }
  ${HOLIDAY_FRAGMENT}
`;
