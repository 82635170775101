import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import getConfig from './config';

const config = getConfig();

export const authProviderConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  appOrigin: config.appOrigin,
  redirectUri: config.redirectUri,
};

const Auth0ProviderWithHistory = ({ children }) => {
  return <Auth0Provider {...authProviderConfig}>{children}</Auth0Provider>;
};

export default Auth0ProviderWithHistory;
