import gql from 'graphql-tag';
import { ROLE_FRAGMENT } from './commonFragments';

export const FIND_ALL_STAFF = gql`
  query findAllStaff(
    $pagination: Pagination
    $filter: StaffFilter
    $role: Int
    $centre: Int
    $searchString: String
  ) {
    findAllStaff(
      pagination: $pagination
      filter: $filter
      role: $role
      centre: $centre
      searchString: $searchString
    ) {
      totalCount
      data {
        ID
        fkSchool
        staffID
        active
        createdAt
        updatedAt
        displayName
        vendorName
        fkStaffReportingOfficer
        staff {
          ID
          fkUser
          userByFkUser {
            ID
            firstname
            lastname
            email
          }
        }
        userByFkUser(filter: { active: [{ eq: [true, false] }] }) {
          userAccessControls {
            data {
              fkClass
              school {
                name
              }
              centre {
                label
                ID
              }
            }
          }
          userRoleRelations {
            data {
              role {
                label
                name
                ID
                roleTemplates {
                  data {
                    access
                  }
                }
              }
            }
          }
          ID
          firstname
          lastname
          isHQStaff
          email
          mobilePhoneCountryCode
          mobilePhone
        }
        userByLastModifiedUser {
          firstname
          lastname
        }
      }
    }
  }
`;

export const FIND_ALL_STAFF_V2 = gql`
  query findAllStaffV2(
    $pagination: Pagination
    $filter: StaffFilter
    $role: [Int!]
    $centre: Int
    $searchString: String
  ) {
    findAllStaffV2(
      pagination: $pagination
      filter: $filter
      role: $role
      centre: $centre
      searchString: $searchString
    ) {
      totalCount
      data {
        ID
        fkSchool
        staffID
        active
        createdAt
        updatedAt
        displayName
        vendorName
        fkStaffReportingOfficer
        staff {
          ID
          fkUser
          userByFkUser {
            ID
            firstname
            lastname
            email
          }
        }
        userByFkUser(filter: { active: [{ eq: [true, false] }] }) {
          userAccessControls {
            data {
              fkClass
              school {
                name
              }
              centre {
                label
                ID
              }
            }
          }
          userRoleRelations {
            data {
              role {
                label
                name
                ID
                roleTemplates {
                  data {
                    access
                  }
                }
              }
            }
          }
          ID
          firstname
          lastname
          isHQStaff
          email
          mobilePhoneCountryCode
          mobilePhone
        }
        userByLastModifiedUser {
          firstname
          lastname
        }
      }
    }
  }
`;

export const FIND_ALL_REPORTING_OFFICER = gql`
  query findAllReportingStaff(
    $pagination: Pagination
    $filter: StaffFilter
    $schoolID: Int!
    $searchString: String
  ) {
    findAllReportingStaff(
      pagination: $pagination
      filter: $filter
      schoolID: $schoolID
      searchString: $searchString
    ) {
      totalCount
      data {
        ID
        fkUser
        fkStaffReportingOfficer
        userByFkUser {
          ID
          firstname
          lastname
          email
        }
      }
    }
  }
`;

export const FIND_ROLE_BY_FK_SCHOOL = gql`
  query findRoleByFkSchool($pagination: Pagination, $filter: RoleFilter) {
    findRoleByFkSchool(pagination: $pagination, filter: $filter) {
      ...roleDetails
    }
  }
  ${ROLE_FRAGMENT}
`;

export const ROLE_FOR_CREATE_USER = gql`
  query findRoleForUserCreation($pagination: Pagination, $filter: RoleFilter) {
    findRoleForUserCreation(pagination: $pagination, filter: $filter) {
      ...roleDetails
    }
  }
  ${ROLE_FRAGMENT}
`;

export const GET_AD_USERS_BY_NAME = gql`
  query getADUsersByName($name: String!) {
    getADUsersByName(name: $name) {
      businessPhones
      email
      displayName
      GivenName
      surname
    }
  }
`;

export const GET_AD_USERS_BY_EMAIL = gql`
  query getADUserByEmail($email: String!) {
    getADUserByEmail(email: $email) {
      businessPhones
      email
      displayName
      GivenName
      surname
    }
  }
`;

export const FIND_ALL_MODULE_CATEGORY = gql`
  query findAllModuleCategory($filter: ModuleCategoryFilter) {
    findAllModuleCategory(filter: $filter) {
      totalCount
      data {
        ID
        fkSchool
        label
        moduleCategoryCode
        active
      }
    }
  }
`;

export const ADD_ROLE = gql`
  mutation addRole(
    $IDSchool: Int!
    $Role: RoleUpdate!
    $ModuleRelations: [ModuleRelation!]!
  ) {
    addRole(IDSchool: $IDSchool, Role: $Role, ModuleRelations: $ModuleRelations)
  }
`;

export const ADD_NEW_STAFF = gql`
  mutation addNewStaff(
    $IDSchool: Int!
    $email: String!
    $firstname: String!
    $lastname: String!
    $staffId: String!
    $displayName: String
    $phoneNumber: String!
    $phoneCountryCode: String!
    $centres: [Int!]!
    $roles: [Int!]!
    $reportingOfficerStaffId: Int
  ) {
    addNewStaff(
      IDSchool: $IDSchool
      email: $email
      firstname: $firstname
      lastname: $lastname
      staffId: $staffId
      phoneNumber: $phoneNumber
      phoneCountryCode: $phoneCountryCode
      centres: $centres
      roles: $roles
      displayName: $displayName
      reportingOfficerStaffId: $reportingOfficerStaffId
    )
  }
`;

export const UPDATE_STAFF = gql`
  mutation updateStaff(
    $IDSchool: Int!
    $IDStaff: Int!
    $centres: [Int!]!
    $roles: [Int!]!
    $staff: StaffUpdate!
    $loginPin: String
  ) {
    updateStaff(
      IDSchool: $IDSchool
      IDStaff: $IDStaff
      centres: $centres
      roles: $roles
      staff: $staff
      loginPin: $loginPin
    )
  }
`;

export const REMOVE_STAFF = gql`
  mutation removeStaff($IDSchool: Int!, $IDStaff: Int!) {
    removeStaff(IDSchool: $IDSchool, IDStaff: $IDStaff)
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole(
    $IDSchool: Int!
    $Role: RoleUpdate!
    $ModuleRelations: [ModuleRelation!]!
    $IDRole: Int!
  ) {
    updateRole(
      IDSchool: $IDSchool
      Role: $Role
      ModuleRelations: $ModuleRelations
      IDRole: $IDRole
    )
  }
`;

export const REMOVE_ROLE = gql`
  mutation removeRole($IDSchool: Int!, $IDRole: Int!) {
    removeRole(IDSchool: $IDSchool, IDRole: $IDRole)
  }
`;

export const ACTIVATE_ROLE = gql`
  mutation activateRole($IDSchool: Int!, $IDRole: Int!) {
    activateRole(IDSchool: $IDSchool, IDRole: $IDRole)
  }
`;

export const ACTIVATE_STAFF = gql`
  mutation activateStaff($IDSchool: Int!, $IDStaff: Int!) {
    activateStaff(IDSchool: $IDSchool, IDStaff: $IDStaff)
  }
`;
