import SkRequest from './BaseRequest';
import { MutationBuilder } from '../queryBuilder';
import { FETCH_CENTRES_FOR_MANAGEMENT } from './actionTypes';

import localstorage from '../../utils/localStorage';
import { SkApolloRequest } from '../../utils/apolloUtils';
import { FIND_ALL_CENTRE_FOR_SCHOOL } from '../models/centreManagementModel';

const _mMutationBuilder = new MutationBuilder();

_mMutationBuilder.setNewMutation('insertOrUpdateCentreForSchool', {});

_mMutationBuilder.setNewMutation('removeCentreForSchool', {});

const dispatchManagementCentreFetch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CENTRES_FOR_MANAGEMENT,
    key: 'managementCentres',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const removeCentre = async (IDSchool, centreID) => {
  try {
    const data = await SkRequest({
      authToken: localstorage.getValue('userToken'),
      data: {
        query: _mMutationBuilder.getMutationAsString('removeCentreForSchool', {
          IDSchool,
          centreID,
        }),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    throw ex;
  }
};

export const createOrUpdateCentre = async (
  IDSchool,
  centreData,
  centreID = 0
) => {
  try {
    const cData = Object.assign({}, centreData);
    const saveData = {
      IDSchool,
      centreID,
      centreData: cData,
      postcodes: cData.postcodes,
      levels: cData.levels,
      addressData: cData.addressData,
    };

    delete cData.postcodes;
    delete cData.levels;
    delete cData.addressData;

    if (!centreID) {
      delete saveData.centreID;
    }

    const data = await SkRequest({
      authToken: localstorage.getValue('userToken'),
      data: {
        query: _mMutationBuilder.getMutationAsString(
          'insertOrUpdateCentreForSchool',
          saveData
        ),
      },
      method: 'POST',
    });

    return data;
  } catch (ex) {
    throw ex;
  }
};

export const fetchManagementCentres = (
  IDSchool,
  pagination = {},
  filter = {}
) => async dispatch => {
  dispatchManagementCentreFetch(dispatch);

  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CENTRE_FOR_SCHOOL,
        variables: {
          schoolID: IDSchool,
          pagination,
          filter,
        },
        type: 'query',
      },
    });

    if (data.success) {
      dispatchManagementCentreFetch(dispatch, false, data);
    } else {
      dispatchManagementCentreFetch(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchManagementCentreFetch(dispatch, false, null, {
      error: ex.message,
    });
    throw ex;
  }
};

export default fetchManagementCentres;
