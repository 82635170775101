import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Tooltip } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import Text from '../Text';
import { StyledField } from './style';

const FieldWrapper = ({
  required,
  name,
  label,
  error,
  styles,
  children,
  hint,
  disabled,
  boldLabel,
  placement = '',
  wrapperPadding,
  labelTextColor = '',
  theme,
  extraLabelItem,
  extraLabelColSize,
  info = '',
  count = null,
  hideError,
  wrapperStyle,
  showCount = true,
  customHintIcon = false,
  tooltipArrow,
}) => (
  <StyledField
    wrapperPadding={wrapperPadding}
    required={required}
    data-field={name}
    disabled={disabled}
    textColor={
      labelTextColor
        ? theme.baseColor.status.draft
        : theme.baseColor.neutral.black
    }
    style={wrapperStyle}
  >
    {' '}
    {label && (
      <Grid justify="space-between">
        <Grid container>
          <Grid
            md={extraLabelColSize ? 12 - extraLabelColSize : 12}
            xs={extraLabelColSize ? 12 - extraLabelColSize : 12}
          >
            <InputLabel htmlFor={name} style={styles}>
              {labelTextColor ? (
                <Text label bold={boldLabel}>
                  {label}
                </Text>
              ) : (
                <Text label bold={boldLabel} style={{ color: 'black' }}>
                  {label}
                </Text>
              )}
              {hint && (
                <Tooltip
                  title={hint}
                  placement={placement}
                  arrow={tooltipArrow}
                >
                  {customHintIcon ? (
                    <InfoOutlinedIcon
                      className="s7t-hint-info-icon"
                      style={{ fontSize: 18 }}
                    />
                  ) : (
                    <InfoIcon />
                  )}
                </Tooltip>
              )}
            </InputLabel>
          </Grid>
          {extraLabelItem && extraLabelItem}
        </Grid>
      </Grid>
    )}
    {children}
    {info && (
      <Grid item xs={12} data-error>
        <Text label color={hideError && error ? 'error' : 'draft'}>
          {info}
        </Text>
        {showCount && count && (
          <Text
            label
            color={hideError && error ? 'error' : 'draft'}
            className="w3-right"
          >
            {count}
          </Text>
        )}
      </Grid>
    )}
    {showCount && !info && count && (
      <Text label className="w3-right">
        {count}
      </Text>
    )}
    <Grid item xs={12} data-error>
      <Text
        className={hideError ? 'w3-hide' : 'w3-show'}
        label
        color="error"
        data-error={name}
      >
        {error}
      </Text>
    </Grid>
  </StyledField>
);

export default withTheme(FieldWrapper);
