import {
  FETCH_NOTIFICATIONS_HISTORY,
  SHOW_SNACKBAR_MESSAGE,
  HIDE_SNACKBAR_MESSAGE,
} from './actionTypes';
import apolloUtils from '../../utils/apolloUtils';
import {
  MARK_NOTIFICATIONS,
  NOTIFICATION_HISTORY,
} from '../models/notificationModels';

const dispatchNotificationHistory = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_NOTIFICATIONS_HISTORY,
    key: 'notification_history',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const fetchNotificationHistory = reqData => async dispatch => {
  dispatchNotificationHistory(dispatch);
  const client = await apolloUtils();
  try {
    const result = await client.query({
      query: NOTIFICATION_HISTORY,
      variables: reqData,
    });

    dispatchNotificationHistory(dispatch, false, result);

    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    dispatchNotificationHistory(dispatch, false, null, {
      error: errorMessage,
    });
    return { error: errorMessage };
  }
};

export const markNotificationRead = (
  notificationIds,
  read = true
) => async () => {
  const client = await apolloUtils();
  try {
    const result = await client.mutate({
      mutation: MARK_NOTIFICATIONS,
      variables: {
        notificationIds,
        read,
      },
    });

    return result;
  } catch (ex) {
    const errorMessage = ex.message.replace(/GraphQL error./, '');
    return { error: errorMessage };
  }
};

export const showSnackBarMessage = (
  message,
  variant = 'success',
  priority = 'low',
  icon = null,
  anchor = 'top'
) => dispatch => {
  dispatch({
    type: SHOW_SNACKBAR_MESSAGE,
    key: 'snackbarMessage',
    value: {
      message,
      variant,
      priority,
      icon,
      anchor,
    },
  });
};

export const hideSnackBarMessage = () => dispatch => {
  dispatch({
    type: HIDE_SNACKBAR_MESSAGE,
    key: 'snackbarMessage',
    value: {},
  });
};

export default fetchNotificationHistory;
