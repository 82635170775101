import { get, uniqBy } from 'lodash';
import {
  FETCH_ALL_ROLES,
  ADD_NEW_ROLE,
  FETCH_ALL_STAFF,
  FETCH_ALL_MODULE_CATEGORY,
  UPDATE_ROLE,
  SEARCH_USER_BY_NAME,
  SEARCH_USER_BY_EMAIL,
  SEARCH_STAFF,
  FETCH_ROLE_FOR_CREATE_USER,
  FETCH_ALL_REPORTING_STAFF,
  SEARCH_STAFF_V2,
  FETCH_ALL_STAFF_V2,
} from '../actions/actionTypes';
import { MFS_HQ_LABEL } from '../../utils/constants';

const removeDuplicateCenters = data =>
  get(data, 'data', []).forEach(eachStaff => {
    eachStaff.userByFkUser.userAccessControls.data = uniqBy(
      get(eachStaff, 'userByFkUser.userAccessControls.data', []),
      'centre.ID'
    );
  });

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findRoleByFkSchool": {
 *       "totalCount": 17,
 *       "data": [
 *         {
 *           "ID": 13,
 *           "label": "Super Administrator",
 *           "name": "super_admin",
 *           "active": true,
 *           "numberOfUsers": 2,
 *           "createdAt": "2019-12-01 11:51:37",
 *           "updatedAt": "2019-12-01 11:51:37",
 *           "lastModifiedUser": 13,
 *           "isRemovable": false,
 *           "user": null,
 *           "roleTemplates": {
 *             "data": [
 *               {
 *                 "ID": 165,
 *                 "label": null,
 *                 "access": 7,
 *                 "moduleCategory": {
 *                   "ID": 11,
 *                   "label": "Enrolled Children",
 *                   "moduleCategoryCode": "student_management"
 *                 }
 *               },
 *             ]
 *           }
 *         },
 *       ]
 *     }
 *   }
 * }
 *
 */

export const findAllRoles = (state = {}, action) => {
  if (action.type === FETCH_ALL_ROLES) {
    const data = get(action, 'value.data.data.findRoleByFkSchool');
    if (data) {
      return Object.assign({}, state, {
        [action.value.data.key]: {
          ...action.value,
          data,
        },
      });
    }
    return Object.assign({}, state, {
      [action.value.data.key]: action.value,
    });
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllEnquiry": {
 *       "data": [
 *         {
 *           "ID": 1,
 *           "fkCentre": 1,
 *           "fkUser": 928,
 *           "fkChild": 584,
 *           "firstname": "",
 *           "lastname": "Koo Yi Jie",
 *           "fkEnquiryState": "enrolled",
 *           "active": true,
 *           "childFirstname": "",
 *           "enrollmentPlan": "2020-06-01 00:00:00",
 *           "isTransferEnquiry": false,
 *           "documentStatus": "complete",
 *           "documentNotificationSent": false,
 *           "applicationSubmitted": false,
 *           "isTransferEnquiryReadyForEnrolment": false,
 *           "centre": {
 *             "ID": 1,
 *             "label": "The Caterpillar's Cove @ Jurong East",
 *             "fkSchool": 3
 *           },
 *           "user": {
 *             "email": "sn2dummy+4001928@gmail.com",
 *             "mobilePhone": "40000928"
 *           },
 *           "visits": {
 *             "totalCount": 2,
 *             "data": [
 *               {
 *                 "ID": 1,
 *                 "status": "completed",
 *                 "visitTo": "2019-11-01 12:30:00",
 *                 "visitFrom": "2019-11-01 12:00:00",
 *                 "visitType": "centre_visit",
 *                 "centre": {
 *                   "label": "The Caterpillar's Cove @ Jurong East"
 *                 }
 *               },
 *             ]
 *           },
 *           "enrolmentStep": "payment_1",
 *           "skipCentreVisit": false,
 *           "skipTrial": true,
 *           "vacancyAvailable": true,
 *           "vacancyStatus": "available"
 *         },
 *       ]
 *     }
 *   }
 * }
 *
 */

export const findRolesForUserCreation = (state = {}, action) => {
  if (action.type === FETCH_ROLE_FOR_CREATE_USER) {
    const data = get(action, 'value.data.data.findRoleForUserCreation');
    if (data) {
      return Object.assign({}, state, {
        [action.value.data.key]: {
          ...action.value,
          data,
        },
      });
    }
    return Object.assign({}, state, {
      [action.value.data.key]: action.value,
    });
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getADUsersByName": [
 *       {
 *         "businessPhones": [],
 *         "email": "munirah-a@myfirstskool.com",
 *         "displayName": "A'isyah Munirah Abdul Rahman - MFS",
 *         "GivenName": "A'isyah Munirah",
 *         "surname": "Abdul Rahman"
 *       },
 *     ]
 *   }
 * }
 */

export const searchUserByNameResults = (state = {}, action) => {
  if (action.type === SEARCH_USER_BY_NAME) {
    const data = get(action, 'value.data.data.getADUsersByName');
    if (data) {
      return Object.assign(
        {},
        {
          ...action.value,
          data,
        }
      );
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "getADUsersByName": [
 *       {
 *         "businessPhones": [],
 *         "email": "munirah-a@myfirstskool.com",
 *         "displayName": "A'isyah Munirah Abdul Rahman - MFS",
 *         "GivenName": "A'isyah Munirah",
 *         "surname": "Abdul Rahman"
 *       },
 *     ]
 *   }
 * }
 */

export const searchUserByEmailResults = (state = {}, action) => {
  if (action.type === SEARCH_USER_BY_EMAIL) {
    const data = get(action, 'value.data.data.getADUserByEmail');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const addNewRole = (state = {}, action) => {
  if (action.type === ADD_NEW_ROLE) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign({}, state, {
        [action.key]: {
          ...action.value,
          data,
        },
      });
    }
    return Object.assign({}, state, { [action.key]: action.value });
  }
  return state;
};

export const updateRole = (state = {}, action) => {
  if (action.type === UPDATE_ROLE) {
    const data = get(action, 'value.data.data');
    if (data) {
      return Object.assign({}, state, {
        [action.key]: {
          ...action.value,
          data,
        },
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * Example data
 *
 * {
 *   "data": {
 *     "findAllStaff": {
 *       "totalCount": 93,
 *       "data": [
 *         {
 *           "ID": 64,
 *           "fkSchool": 3,
 *           "staffID": "sn2dummy+40000898@gmail.com",
 *           "active": true,
 *           "createdAt": "2019-12-01 12:36:37",
 *           "userByFkUser": {
 *             "userAccessControls": {
 *               "data": [
 *                 {
 *                   "fkClass": null,
 *                   "school": {
 *                     "name": "The Caterpillar's Cove"
 *                   },
 *                   "centre": {
 *                     "label": "The Caterpillar's Cove @ Jurong East",
 *                     "ID": 1
 *                   }
 *                 }
 *               ]
 *             },
 *             "userRoleRelations": {
 *               "data": [
 *                 {
 *                   "role": {
 *                     "label": "Staff",
 *                     "name": "default_staff",
 *                     "ID": 15,
 *                     "roleTemplates": {
 *                       "data": [
 *                         {
 *                           "access": 1
 *                         },
 *                       ]
 *                     }
 *                   }
 *                 }
 *               ]
 *             },
 *             "ID": 898,
 *             "firstname": "Ah Kiat",
 *             "lastname": "Lee",
 *             "isHQStaff": false,
 *             "email": "sn2dummy+40000898@gmail.com",
 *             "mobilePhoneCountryCode": "+65000",
 *             "mobilePhone": "40000898"
 *           },
 *           "userByLastModifiedUser": null
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const allStaff = (state = {}, action) => {
  if (action.type === FETCH_ALL_STAFF) {
    const data = get(action, 'value.data.data.findAllStaff');
    removeDuplicateCenters(data);
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const allStaffV2 = (state = {}, action) => {
  if (action.type === FETCH_ALL_STAFF_V2) {
    const data = get(action, 'value.data.data.findAllStaffV2');
    removeDuplicateCenters(data);
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * same as FETCH_ALL_STAFF
 */

export const staffSearchResults = (state = {}, action) => {
  if (action.type === SEARCH_STAFF) {
    const data = get(action, 'value.data.data.findAllStaff');
    removeDuplicateCenters(data);
    const filteredStaffSearchResults = get(data, 'data', []).filter(
      item => item.staffID !== MFS_HQ_LABEL
    );
    if (filteredStaffSearchResults) {
      return Object.assign({}, state, {
        ...action.value,
        filteredStaffSearchResults,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export const staffSearchResultsV2 = (state = {}, action) => {
  if (action.type === SEARCH_STAFF_V2) {
    const data = get(action, 'value.data.data.findAllStaffV2');
    removeDuplicateCenters(data);
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

/**
 *
 * example data
 *
 * {
 *   "data": {
 *     "findAllModuleCategory": {
 *       "totalCount": 32,
 *       "data": [
 *         {
 *           "ID": 1,
 *           "fkSchool": null,
 *           "label": "Special Handling",
 *           "moduleCategoryCode": "special_handling",
 *           "active": true
 *         },
 *       ]
 *     }
 *   }
 * }
 */

export const fetchAllModuleCategory = (state = {}, action) => {
  if (action.type === FETCH_ALL_MODULE_CATEGORY) {
    const data = get(action, 'value.data.data.findAllModuleCategory.data');
    if (data) {
      return Object.assign({}, state, {
        [action.key]: {
          ...action.value,
          data,
        },
      });
    }
    return Object.assign({}, state, { [action.key]: action.value });
  }
  return state;
};

export const allReportingStaff = (state = {}, action) => {
  if (action.type === FETCH_ALL_REPORTING_STAFF) {
    const data = get(action, 'value.data.data.findAllReportingStaff');
    if (data) {
      return Object.assign({}, state, {
        ...action.value,
        data,
      });
    }
    return Object.assign({}, state, action.value);
  }
  return state;
};

export default findAllRoles;
