/** We'll be using TCC color as a default color */
let primaryColor = '#F9A350';
let secondaryColor = '#6DB33F';

if (process.env.REACT_APP_SCHOOL_ID === '1') {
  primaryColor = '#EC6608';
  secondaryColor = '#E5D7C5';
} else if (process.env.REACT_APP_SCHOOL_ID === '2') {
  primaryColor = '#56C4C5';
  secondaryColor = '#F1BE48';
}

export const PRIMARY_COLOR = primaryColor;
export const SECONDARY_COLOR = secondaryColor;

export const BASE_COLOR = {
  text: {
    default: '#4A4A4A',
    disabled: '#959595',
    placeholder: '#dadada',
    label: '#717171',
    black: '#000000',
    linkpass: '#6A3485',
  },
  status: {
    success: '#12B886',
    error: '#C80000',
    warning: '#E7AC08',
    draft: '#A8A8A8',
    information: '#41B2F3',
  },
  snackBar: {
    greyBackground: '#505050',
  },
  neutral: {
    main: '#38576B',
    black: '#303030',
    grey: '#A8A8A8',
    darkGrey: '#6C6C6C',
    mediumGrey: '#CFCFCF',
    lightGrey: '#E4E4E4',
    lighterGrey: '#F8F8F8',
    white: '#FFFFFF',
    gridBox: '#bababa',
    divider: '#cecece',
    darkYellow: '#f9a351',
    alert: '#fb0000',
    remark: '#666666',
    green: '#00D812',
    lightOrange: '#FCF2DA',
    turquoise: '#00ced1',
  },
  observation: {
    darkBlue: '#8593E5',
    lightGreen: '#aaee88',
    greyYellow: '#d7be69',
    skyBlue: '#00bbee',
  },
  attendance: {
    late_pickup: '#ebb800',
    leave: '#bababa',
    sick: '#7155d3',
  },
  filter: {
    tab: '#334d5e',
    label: '#425968',
  },
  linkpass: {
    default: '#6A3485',
  },
};

export const FONT = {
  regular: 'Lato-Regular',
  bold: 'Lato-Semibold',
  header: 'Lato-Black',
  extrabold: 'Lato-Bold',
  dkPancake: 'DK Sugary Pancake',
};

export const ICON_SIZE = {
  xLarge: '44px',
  large: '25px',
  normal: '22px',
  medium: '16px',
  small: '12px',
};

export const BORDER = {
  classActivity: {
    stickerBorder: '3px solid',
    stickerBorderRadius: '30px',
  },
};

export const SIZE = {
  mobileL: '600px',
};

export const SPACING = {
  padding15: '15px',
};
