import gql from 'graphql-tag';

export const GET_FORM_QUERY = `{
  totalCount
  data {
    ID
    firstname
    lastname
    fullname
    currentLevel {
      estimatedPromotionDate
      centre {
        label
      }
      forms {
        totalCount
        data {
          createdAt
        }
      }
    }
    upcomingLevel {
      forms {
        data {
          formParticipants {
            data {
              fkParent
              user {
                getRole {
                  label
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export const FIND_ALL_ADMISSION_QUERY = `{
    totalCount
    data {
      ID
      firstname
      lastname
      childFirstname
      pendingRegisterSince
      source
      createdAt
      fkEnquiryState
      centre {
        code
        label
      }
      enrollmentPlan
      child {
        ID
        firstname
        lastname
        birthCertificate
      }
      user {
        firstname
        lastname
        mobilePhoneCountryCode
        mobilePhone
        email
      }
      admissionStatus {
        ID
        label
      }
    }
}`;

export const FIND_BLACKLIST_DRAFT_QUERY = `{
    totalCount
    data {
          ID
    }
}`;

export const GET_ALL_TODAY_VISITS = `{
  totalCount
  data {
    ID
    visitFrom
    visitTo
    status
    user {
      ID
      firstname
      lastname
    }
  }
}`;

export const GET_ALL_WITHDRAWAL_QUERY = `{
  totalCount
  data {
    ID
    createdAt
    updatedAt
    additionalStatus
    eConsentStatus
    withdrawalDraftItem {
      child {
        ID
        firstname
        lastname
        fullname
      }
      centre {
        label
      }
    }
  }
}`;

export const GET_WAITLIST_QUERY = `{
    totalCount
  }`;

export const GET_TRASFER_DRAFT_QUERY = `{
    totalCount
    data {
      ID
      fkChild
      additionalStatus
      destinationFkCentre
      transferDraftItem {
        ID
        fkDraft
        effectiveDate
        child {
          ID
          fullname
        },
      },
      centreByDestinationFkCentre {
        label
      }
      updatedAt
    },
  }`;

export const FIND_ALL_FEE_DRAFT_QUERY = `{
  Total
  Data {
    CentreID
    CentreName
    Count
  }
}`;

export const GET_ASSIGNED_CHILD_QUERY = `{
    totalCount
    data {
        ID
        currentLevel {
            level {
                ID
                nextLevel {
                    ID
                }
            }
        }
        upcomingLevel {
            level {
                ID
            }
        }
    }
}`;

export const GET_CENTRE_CICO_TOKEN = gql`
  query getCheckInOutToken($IDCentre: Int!) {
    getCheckInOutToken(IDCentre: $IDCentre)
  }
`;

export const CAPACITY_DRAFT_QUERY = gql`
  query capacityDraftsByFkSchool(
    $IDSchool: Int!
    $filter: CapacityDraftFilter
    $pagination: Pagination
  ) {
    capacityDraftsByFkSchool(
      IDSchool: $IDSchool
      filter: $filter
      pagination: $pagination
    ) {
      data {
        ID
      }
    }
  }
`;

export const PENDING_PROMOTION_GROUP_OBJECT = `{
  totalCount
  data {
    numberChildren
    level
    fkLevel
    fkCentre
    centreName
  }
}`;

export const ENROLMENT_PLANING_DRAFT_GROUP_BY_CENTER_QUERY = `{
  Total
  Data {
    CentreID
    CentreName
    Count
  }
}`;

export const GET_TRANSFER_DRAFTS = gql`
  query getTransferDrafts(
    $schoolID: Int!
    $filter: TransferDraftFilter
    $pagination: Pagination
  ) {
    getTransferDrafts(
      schoolID: $schoolID
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkChild
        additionalStatus
        destinationFkCentre
        transferDraftItem {
          ID
          fkDraft
          effectiveDate
          child {
            ID
            fullname
          }
        }
        centreByDestinationFkCentre {
          label
        }
        updatedAt
      }
    }
  }
`;

export const GET_TRANSFER_DRAFTS_PENDING_APPROVAL = gql`
  query getTransferDraftsPendingApproval(
    $schoolID: Int!
    $fkCentre: [Int!]
    $filter: TransferDraftFilter
    $pagination: Pagination
  ) {
    getTransferDraftsPendingApproval(
      schoolID: $schoolID
      fkCentre: $fkCentre
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkChild
        additionalStatus
        destinationFkCentre
        transferDraftItem {
          ID
          fkDraft
          effectiveDate
          child {
            ID
            fullname
          }
        }
        centreByDestinationFkCentre {
          label
        }
        updatedAt
      }
    }
  }
`;

export const GET_PENDING_PROMOTION_BY_LEVEL = gql`
  query FindAllChildPendingPromotion(
    $filter: CentreFilter
    $pagination: Pagination
  ) {
    FindAllChildPendingPromotion(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        numberChildren
        level
        fkLevel
        fkCentre
        centreName
      }
    }
  }
`;

export const GET_ALL_WITHDRAWAL = gql`
  query findAllWithdrawalDraft(
    $filter: WithdrawalDraftFilter
    $pagination: Pagination
  ) {
    findAllWithdrawalDraft(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        createdAt
        updatedAt
        withdrawalDraftItem {
          child {
            ID
            firstname
            lastname
            fullname
          }
          centre {
            label
          }
        }
      }
    }
  }
`;

export const GET_ALL_WITHDRAWAL_DRAFTS = gql`
  query getAllWithdrawalDrafts(
    $schoolID: Int!
    $IDCentres: [Int!]
    $filter: WithdrawalDraftFilter
    $pagination: Pagination
  ) {
    getAllWithdrawalDrafts(
      schoolID: $schoolID
      IDCentres: $IDCentres
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        fkChild
        child {
          firstname
          lastname
          fullname
        }
        status
        additionalStatus
        eConsentStatus
        centre {
          label
        }
        withdrawalDraftItem {
          createdAt
          effectiveDate
        }
      }
    }
  }
`;

export const GET_ALL_WITHDRAWAL_DRAFTS_OBJECT = `{
  totalCount
  data {
    ID
    fkChild
    child {
      firstname
      lastname
      fullname
    }
    status
    additionalStatus
    eConsentStatus
    centre {
      label
    }
    withdrawalDraftItem {
      createdAt
      effectiveDate
    }
  }
}`;

export const GET_ATTENDANCE_OBJECT = `{
  attendanceSummaries {
    totalAttendance
    totalPresent
    holiday {
      title
      description
    }
  }
}`;

export const GET_PENDING_PROMOTION_FORM = gql`
  query getPendingForm2ESignatureChildren(
    $pendingStatus: FormPendingStatus
    $centreIDs: [Int]
    $filter: ChildFilter
    $pagination: Pagination
  ) {
    getPendingForm2ESignatureChildren(
      pendingStatus: $pendingStatus
      centreIDs: $centreIDs
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        firstname
        lastname
        fullname
        currentLevel {
          estimatedPromotionDate
          centre {
            label
          }
          forms {
            totalCount
            data {
              createdAt
            }
          }
        }
        upcomingLevel {
          from
        }
      }
    }
  }
`;
