import gql from 'graphql-tag';
import {
  CHILD_FRAGMENT,
  CLASS_FRAGMENT,
  TEACHER_FRAGMENT,
  BASIC_CHILD_FRAGMENT,
  CHILD_HEALTH_FRAGMENT,
} from './commonFragments';

export const FIND_ALL_CHILD = gql`
  query findAllChild($filter: ChildFilter, $pagination: Pagination) {
    findAllChild(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ...ChildData
      }
    }
  }
  ${CHILD_FRAGMENT}
`;

export const GET_CHILDREN_TO_ASSIGN_CLASS = gql`
  query getChildrenToAssignToClass(
    $classIDs: [Int!]
    $centreIDs: [Int!]
    $levelIDs: [Int!]
    $programIDs: [Int!]
    $statuses: [String!]
    $retainedPromotion: Boolean
    $pendingPromotion: Boolean
    $dobFrom: Datetime
    $dobTo: Datetime
    $childFilter: ChildFilter
    $pagination: Pagination
    $promotedChild: Boolean
  ) {
    getChildrenToAssignToClass(
      classIDs: $classIDs
      centreIDs: $centreIDs
      levelIDs: $levelIDs
      programIDs: $programIDs
      statuses: $statuses
      retainedPromotion: $retainedPromotion
      pendingPromotion: $pendingPromotion
      dobFrom: $dobFrom
      dobTo: $dobTo
      childFilter: $childFilter
      pagination: $pagination
      promotedChild: $promotedChild
    ) {
      totalCount
      data {
        ...ChildData
        closestLevel {
          centre {
            ID
            label
          }
          from
          to
          program {
            ID
            label
          }
          level {
            ID
            label
            code
          }
          nextLevel {
            ID
            label
            code
          }
          moveReason
        }
        childLevels {
          data {
            program {
              label
            }
          }
        }
        childRelations {
          data {
            parent {
              ID
              firstname
              lastname
              email
              identificationNo
              mobilePhoneCountryCode
              mobilePhone
            }
          }
        }
      }
    }
  }
  ${CHILD_FRAGMENT}
`;

export const GET_CHILDREN_DATA = gql`
  query getChildrenData(
    $ftsInput: String!
    $childFilter: ChildFilter
    $pagination: Pagination
  ) {
    getChildrenData(
      ftsInput: $ftsInput
      childFilter: $childFilter
      pagination: $pagination
    ) {
      totalCount
      data {
        ...ChildData
        closestLevel {
          centre {
            ID
            label
          }
          from
          to
          program {
            ID
            label
          }
          level {
            ID
            label
            code
          }
          nextLevel {
            ID
            label
            code
          }
          moveReason
        }
      }
    }
  }
  ${CHILD_FRAGMENT}
`;

export const GET_PAST_CHILDREN_DATA = gql`
  query getPastChildrenData(
    $fkSchool: Int!
    $IDChild: Int
    $fkCentres: [Int!]
    $ftsInput: String
    $filter: ChildFilter
    $pagination: Pagination
  ) {
    getPastChildrenData(
      fkSchool: $fkSchool
      IDChild: $IDChild
      fkCentres: $fkCentres
      ftsInput: $ftsInput
      filter: $filter
      pagination: $pagination
    ) {
      data {
        child {
          ...BasicChildData
          enrolmentStatus {
            status
            label
          }
          closestLevel {
            level {
              ID
              label
              code
            }
          }
        }
        centre {
          ID
          code
          label
        }
        enrolmentDate
        withdrawnDate
      }
      totalCount
    }
  }
  ${BASIC_CHILD_FRAGMENT}
`;

export const GET_CLASS_LIST = gql`
  query getAllClassInfo($fkSchool: Int!, $filter: ClassFilter) {
    getAllClassInfo(fkSchool: $fkSchool, filter: $filter) {
      totalCount
      data {
        ...ClassData
      }
    }
  }
  ${CLASS_FRAGMENT}
`;

export const GET_CLASS_MANAGEMENT_LIST = gql`
  query getAllClassInfo(
    $fkSchool: Int!
    $filter: ClassFilter
    $onlyCurrentClass: Boolean
    $pagination: Pagination
  ) {
    getAllClassInfo(
      fkSchool: $fkSchool
      filter: $filter
      onlyCurrentClass: $onlyCurrentClass
      pagination: $pagination
    ) {
      totalCount
      data {
        ...ClassData
        currentChildClasses {
          totalCount
        }
        classTeachers {
          data {
            ...TeacherData
          }
        }
        upcomingChildClasses {
          totalCount
        }
      }
    }
  }
  ${CLASS_FRAGMENT}
  ${TEACHER_FRAGMENT}
`;

export const GET_CLASS_DETAIL = gql`
  query getAllClassInfo($fkSchool: Int!, $filter: ClassFilter) {
    getAllClassInfo(fkSchool: $fkSchool, filter: $filter) {
      data {
        ...ClassData
        coverPhotoKey
        profilePhotoKey
        currentChildClasses(alphabeticalSort: true) {
          totalCount
          data {
            child {
              ...BasicChildData
            }
          }
        }
        upcomingChildClasses(alphabeticalSort: true) {
          totalCount
          data {
            child {
              ...BasicChildData
            }
          }
        }
        classTeachers {
          data {
            ...TeacherData
          }
        }
      }
    }
  }
  ${CLASS_FRAGMENT}
  ${BASIC_CHILD_FRAGMENT}
  ${TEACHER_FRAGMENT}
`;

/* ============================== */

export const ASSIGN_CHILD_TO_CLASS = gql`
  mutation assignChildrenToClass(
    $classID: Int!
    $children: [AssignChildClass!]!
  ) {
    assignChildrenToClass(toClassId: $classID, assignChildren: $children)
  }
`;

export const PROMOTE_AND_ASSIGN_CHILD = gql`
  mutation promoteChildren($childrenDetail: [ChildPromotionDTO!]!) {
    promoteChildrenAndAssignClass(childPromotionDTOs: $childrenDetail)
  }
`;

export const RETAIN_CHILD = gql`
  mutation addOrUpdateRetainChildren(
    $childID: Int!
    $nextExpectedDOP: Datetime!
    $reason: String!
    $remark: String
  ) {
    addOrUpdateRetainChildren(
      childID: $childID
      nextExpectedDOP: $nextExpectedDOP
      reason: $reason
      remark: $remark
    )
  }
`;

export const CANCEL_RETAIN_CHILD = gql`
  mutation cancelRetainChild($childID: Int!) {
    cancelRetainChild(childID: $childID)
  }
`;

export const gqlGetUploadedDocument = gql`
  query listAllEnrolledChildDocuments(
    $childID: Int!
    $documentTypeIDs: [Int!]!
  ) {
    listAllEnrolledChildDocuments(
      childID: $childID
      documentTypeConfigIDs: $documentTypeIDs
    ) {
      data {
        ID
        fkDocumentTypeConfig
        fkChild
        createdAt
        updatedAt
        label
        fkRegistration
        fkParent
        fkRegistrationParent
        fkRegistrationFamilyMember
        reviewedBy
        reviewedAt
        status
        remarks
        parent {
          ID
          lastname
          firstname
          identificationNo
        }
        registrationParent {
          ID
          fkParent
          lastname
          firstname
          identificationNo
        }
        registrationFamilyMember {
          ID
          lastname
          firstname
          identificationNo
        }
        userByReviewedBy {
          ID
          lastname
          firstname
        }
        documentTypeConfig {
          label
          code
        }
        documentTags {
          data {
            fkDocumentType
            document {
              ID
              createdAt
              updatedAt
              filename
              uRL
              uploadedBy
              user {
                firstname
                lastname
              }
            }
          }
        }
      }
    }
  }
`;

export const gqlListRequiredDocuments = gql`
  query listRequiredDocuments($IDEnquiry: Int!) {
    listRequiredDocuments(IDEnquiry: $IDEnquiry) {
      DocumentTypeID
      ParentID
      ChildID
      Fullname
      DocumentType
      Status
      Remarks
    }
  }
`;

export const gqlgetDocumentTypeByDocumentCategory = gql`
  query getDocumentTypeByDocumentCategory(
    $IDChild: Int!
    $IDDocumentCategory: Int!
    $IDSchool: Int!
  ) {
    getDocumentTypeByDocumentCategory(
      IDChild: $IDChild
      IDDocumentCategory: $IDDocumentCategory
      IDSchool: $IDSchool
    ) {
      DocumentTypeConfigID
      DocumentTypeID
      ParentID
      ChildID
      Fullname
      DocumentType
      Status
      Remarks
    }
  }
`;

export const gqlgetEnrolmentDocumentURL = gql`
  query getEnrolmentDocumentURL($IDDocument: Int!, $IDEnquiry: Int!) {
    getEnrolmentDocumentURL(IDDocument: $IDDocument, IDEnquiry: $IDEnquiry)
  }
`;

export const gqlgetTeacherListOnInput = gql`
  query getTeacherListOnInput(
    $fkSchool: Int!
    $ftsInput: String!
    $IDCentre: Int
    $isGetSeniorTeacher: Boolean
  ) {
    getTeacherListOnInput(
      fkSchool: $fkSchool
      ftsInput: $ftsInput
      IDCentre: $IDCentre
      isGetSeniorTeacher: $isGetSeniorTeacher
    ) {
      totalCount
      data {
        user {
          ID
          firstname
          lastname
        }
      }
    }
  }
`;

export const PENDING_PROMOTION_FORM = gql`
  query getPendingForm2ESignatureChildren($pendingStatus: FormPendingStatus) {
    getPendingForm2ESignatureChildren(pendingStatus: $pendingStatus) {
      totalCount
      data {
        ID
        firstname
        lastname
      }
    }
  }
`;

export const PENDING_WITHDRAWAL_FORM = gql`
  query getPendingWithdrawalForm3ESignatureChildren(
    $pendingStatus: FormPendingStatus
  ) {
    getPendingWithdrawalForm3ESignatureChildren(pendingStatus: $pendingStatus) {
      totalCount
      data {
        ID
        firstname
        lastname
      }
    }
  }
`;

export const gqlchildByID = gql`
  query childByID($IDChild: Int!) {
    childByID(IDChild: $IDChild) {
      firstname
      lastname
      ID
      fkSubsidy
      race
      nationality
      gender
      isRetained
      childLevels {
        data {
          level {
            ID
            label
          }
          fkCentre
          centre {
            ID
            label
          }
          from
          to
          moveReason
          expectedPromotionDate
        }
      }
      childActivityLogs {
        data {
          remarks
          fkCodePurpose
          action
          createdAt
        }
      }
      sibling {
        RegistrationChildren {
          data {
            ID
            fkChild
            firstname
            lastname
            registrationChildMappings {
              data {
                ID
                fkRegistrationChild
                centre {
                  label
                }
                program {
                  label
                }
                registration {
                  currentFrontendStatus
                }
              }
            }
          }
          totalCount
        }
        EnrolledChildren {
          data {
            ID
            firstname
            lastname
            currentLevel {
              program {
                label
              }
              centre {
                label
              }
            }
            upcomingLevel {
              program {
                label
              }
              centre {
                label
              }
            }
            enrolmentStatus {
              status
            }
          }
          totalCount
        }
      }
      tradeDebtNotificationConfig {
        notificatioEnabled
      }
      fkCodeKidstartFundedBy
      birthCertificate
      dateOfBirth
      active
      supportScheme
      createdAt
      updatedAt
      imageKey
      hasEmergencyContact
      enrolmentDate
      firstEnrolmentDate
      isRetained
      childCurrentEstimatedPromotionDate
      nationalityUpdateEffectiveFrom
      programTypeUpdateEffectiveFrom
      supportSchemaUpdateEffectiveFrom
      householdIncomeRange
      numFamilyMembers
      videoConsentBy
      enrolmentStatus {
        status
        label
      }
      closestLevel {
        centre {
          ID
          label
        }
        from
        to
        program {
          ID
          label
        }
        level {
          ID
          label
          code
        }
        nextLevel {
          ID
          label
          code
        }
        moveReason
      }
      bankAccounts {
        data {
          isCda
          payerAccountType
          status
        }
      }
      enquiries {
        data {
          documentStatus
          documentNotificationSent
          applicationSubmitted
          enrolmentStartDate
          skipCentreVisit
          skipTrial
          fkEnquiryState
          visits {
            data {
              ID
              fkUser
              status
              fkEnquiry
              fkCentre
              visitTo
              visitFrom
              visitType
              trialPeriodFee
              trialPeriodPaymentStatus
              comment
              centre {
                ID
                label
              }
            }
          }
          child {
            firstname
            lastname
            dateOfBirth
            enquiries {
              data {
                enrollmentPlan
                fkEnquiryState
                centre {
                  ID
                  label
                }
              }
            }
          }
          centre {
            label
            ID
          }
          ID
          firstname
          lastname
          childFirstname
          childLastname
          active
          fkEnquiryState
          waitlistStatus
          documentStatus
          applicationSubmission
          enrollmentPlan
          vacancyAvailable
          privateVacancyAvailable
          isTransferEnquiry
          level {
            label
            ID
          }
          program {
            ID
            label
          }
          householdIncomeRange
          admissionStatus {
            ID
            label
            stateCode
            enquiryFrontendState {
              label
              stateCode
            }
          }
        }
      }
      withdrawalDraftItems {
        data {
          ID
          active
        }
      }
      temporaryWithdrawals {
        totalCount
        data {
          ID
          status
          effectiveTo
          effectiveFrom
          remark
          withdrawalReason
          withdrawalOtherReason
          active
          hasProcessed
          submissionDate
        }
      }
      withdrawals {
        data {
          ID
          effectiveDate
          isGraduation
          centre {
            ID
          }
        }
      }
      familyMembers: childRelations(filter: { type: family_member }) {
        totalCount
        data {
          parent {
            firstname
            lastname
            relation
            identificationNo
            birthdate
            isEmployed
            monthlyIncome
          }
        }
      }
      mainApplicant: childRelations(filter: { type: applicant }) {
        data {
          ID
          fkUser
          fkChild
          fkRelation

          user {
            ID
            firstname
            lastname
            homePhone
            homePhoneCountryCode
            mobilePhone
            email
            birthdate
            gender
            parent {
              firstname
              lastname
              gender
              email
              birthdate
              identificationNo
              nationality
              race
              monthlyIncome
              highestQualification
              maritalStatus
            }
            addresses {
              data {
                name
                postcode
                address
                building
                floor
                unit
                city
                country
              }
            }
          }
          parent {
            ID
            firstname
            lastname
            mobilePhoneCountryCode
            mobilePhone
            email
            birthdate
            gender
            identificationNo
            isParent
            monthlyIncome
            nationality
            highestQualification
            maritalStatus
            workingStatus
            occupationalTitle
            isEmployed
            selfEmployedMonthlyIncome
            nationalityUpdateEffectiveFrom
            maritalStatusUpdateEffectiveFrom
            workingStatusUpdateEffectiveFrom
            reasonOfUnemployment
            addresses {
              data {
                name
                postcode
                address
                building
                floor
                unit
                city
                country
              }
            }
            createdAt
            hasNoa
            hasCpf
            isRecentlyEmployed
            commencementDate
          }
        }
      }
      secondApplicant: childRelations(filter: { type: second_applicant }) {
        data {
          ID
          fkUser
          fkChild
          fkRelation

          user {
            ID
            firstname
            lastname
            homePhone
            homePhoneCountryCode
            mobilePhone
            email
            birthdate
            gender
            parent {
              firstname
              lastname
              gender
              email
              birthdate
              identificationNo
              nationality
              race
              monthlyIncome
              highestQualification
              maritalStatus
            }
            addresses {
              data {
                postcode
                address
                building
                floor
                unit
                city
                country
              }
            }
          }
          parent {
            ID
            firstname
            lastname
            mobilePhoneCountryCode
            mobilePhone
            email
            birthdate
            gender
            identificationNo
            isParent
            monthlyIncome
            nationality
            highestQualification
            maritalStatus
            workingStatus
            occupationalTitle
            isEmployed
            selfEmployedMonthlyIncome
            nationalityUpdateEffectiveFrom
            maritalStatusUpdateEffectiveFrom
            workingStatusUpdateEffectiveFrom
            reasonOfUnemployment
            addresses {
              data {
                postcode
                address
                building
                floor
                unit
                city
                country
              }
            }
            createdAt
            hasNoa
            hasCpf
            isRecentlyEmployed
            commencementDate
          }
        }
      }
      currentLevel {
        centre {
          ID
          label
        }
        from
        to
        program {
          ID
          label
        }
        level {
          ID
          label
          code
        }
        nextLevel {
          ID
          label
          code
        }
        moveReason
      }
      upcomingLevel {
        centre {
          ID
          label
        }
        from
        to
        program {
          ID
          label
        }
        level {
          ID
          label
        }
        moveReason
        forms {
          data {
            formParticipants {
              data {
                ID
                fkParent
                fkUser
                status
                order
                updatedAt
                user {
                  ID
                  firstname
                  lastname
                  getRole {
                    label
                  }
                }
                parent {
                  ID
                  firstname
                  lastname
                }
              }
            }
          }
        }
      }
      closestLevel {
        centre {
          ID
          label
        }
        from
        to
        program {
          ID
          label
        }
        level {
          ID
          label
          code
        }
        nextLevel {
          ID
          label
          code
        }
        moveReason
      }
      currentClass {
        class {
          ID
          label
          level {
            ID
            label
          }
          centre {
            ID
            label
          }
        }
        from
        to
      }
      upcomingClass {
        class {
          ID
          label
          level {
            ID
            label
          }
          centre {
            ID
            label
          }
        }
        from
        to
      }
      childAuthorisedPersonals {
        data {
          ID
          authorisedPersonal {
            ID
            identificationNo
            firstname
            lastname
            countryCode
            phone
            email
            passcode
            validFrom
            validTo
          }
        }
      }
      childAuthorisedPersonals {
        data {
          authorisedPersonal {
            ID
            firstname
            lastname
          }
        }
      }
    }
  }
`;

export const gqlCreateNewClass = gql`
  mutation createNewClass($newClass: ClassCreate!, $teacherList: [Int!]!) {
    createNewClass(newClass: $newClass, teacherList: $teacherList) {
      ID
    }
  }
`;

export const gqlGetClassPhotoUploadURL = gql`
  mutation getClassPhotoUploadURL($classId: Int!, $file: Upload!) {
    getClassPhotoUploadURL(classId: $classId, file: $file) {
      Url
      Key
    }
  }
`;

export const gqlGetChildPhotoUploadURL = gql`
  mutation getChildPhotoUploadURL($IDChild: Int!, $file: Upload!) {
    getChildPhotoUploadURL(IDChild: $IDChild, file: $file) {
      Url
      Key
    }
  }
`;

export const gqlUpdateClassDetails = gql`
  mutation updateClassDetails(
    $classId: Int!
    $updateClass: ClassUpdate!
    $teacherList: [Int!]!
  ) {
    updateClassDetails(
      classId: $classId
      updateClass: $updateClass
      teacherList: $teacherList
    ) {
      ID
      label
      description
      coverPhotoKey
      profilePhotoKey
    }
  }
`;

export const gqlUpdateChildByFields = gql`
  mutation updateChildByFields($IDChild: Int!, $data: ChildUpdate!) {
    updateChildByFields(IDChild: $IDChild, data: $data) {
      ID
    }
  }
`;

export const gqlDeleteClassAndTeachers = gql`
  mutation deleteClassAndTeachers($classId: Int!) {
    deleteClassAndTeachers(classId: $classId)
  }
`;

export const gqlApproveEnquiryDocument = gql`
  mutation approveEnquiryDocument($IDEnquiry: Int!, $IDDocumentType: Int!) {
    approveEnquiryDocument(
      IDEnquiry: $IDEnquiry
      IDDocumentType: $IDDocumentType
    )
  }
`;

export const gqlRejectEnquiryDocument = gql`
  mutation rejectEnquiryDocument(
    $IDEnquiry: Int!
    $IDDocumentType: Int!
    $remark: String!
  ) {
    rejectEnquiryDocument(
      IDEnquiry: $IDEnquiry
      IDDocumentType: $IDDocumentType
      remark: $remark
    )
  }
`;

export const gqlMarkEnquiryDocumentAsNotRequired = gql`
  mutation markEnquiryDocumentAsNotRequired(
    $IDEnquiry: Int!
    $IDDocumentType: Int!
    $remark: String!
  ) {
    markEnquiryDocumentAsNotRequired(
      IDEnquiry: $IDEnquiry
      IDDocumentType: $IDDocumentType
      remark: $remark
    )
  }
`;

export const gqlGetDocumentUploadURLByIDDocumentTypeConfig = gql`
  mutation getDocumentUploadURLByIDDocumentTypeConfig(
    $IDDocumentTypeConfig: Int!
    $IDChild: Int!
    $IDEnquiry: Int
    $file: Upload!
  ) {
    getDocumentUploadURLByIDDocumentTypeConfig(
      IDDocumentTypeConfig: $IDDocumentTypeConfig
      IDChild: $IDChild
      IDEnquiry: $IDEnquiry
      file: $file
    ) {
      Url
      Key
    }
  }
`;

export const gqlSaveDocument = gql`
  mutation saveDocument(
    $uploadedDocuments: [DocumentUpload!]!
    $IDChild: Int!
    $IDEnquiry: Int
  ) {
    saveDocument(
      uploadedDocuments: $uploadedDocuments
      IDChild: $IDChild
      IDEnquiry: $IDEnquiry
    ) {
      ID
      filename
      documentTags {
        data {
          fkDocumentType
        }
      }
    }
  }
`;

export const UPDATE_NATIONALITY = gql`
  mutation updateChildNationality(
    $IDChild: Int!
    $nationality: Nationality!
    $effectiveFrom: Datetime!
  ) {
    updateChildNationality(
      IDChild: $IDChild
      nationality: $nationality
      effectiveFrom: $effectiveFrom
    ) {
      ID
      nationality
    }
  }
`;

export const UPDATE_PROGRAM_TYPE = gql`
  mutation updateChildProgramType(
    $IDChild: Int!
    $IDProgram: Int!
    $effectiveFrom: Datetime!
  ) {
    updateChildProgramType(
      IDChild: $IDChild
      IDProgram: $IDProgram
      effectiveFrom: $effectiveFrom
    )
  }
`;

export const FIND_ALL_PROGRAM_LIST = gql`
  query findAllProgram($filter: ProgramFilter, $pagination: Pagination) {
    findAllProgram(filter: $filter, pagination: $pagination) {
      totalCount
      data {
        ID
        label
        type
      }
    }
  }
`;

export const FIND_SUGGEST_PROGRAM_LIST = gql`
  query suggestProgram($centreID: Int!, $levelID: Int!) {
    suggestProgram(IDCentre: $centreID, IDLevel: $levelID) {
      totalCount
      data {
        ID
        label
      }
    }
  }
`;

export const FIND_CLASS_ACTIVITIES = gql`
  query findClassActivities(
    $filterDto: ClassActivityFilterDto!
    $filter: ClassActivityFilter
    $pagination: Pagination
  ) {
    findClassActivities(
      filterDto: $filterDto
      filter: $filter
      pagination: $pagination
    ) {
      totalCount
      data {
        ID
        status
        title
        description
        link
        allowComment
        remarks
        createdAt
        updatedAt
        publishedAt
        type
        sticker
        notifyParent
        likesCount
        stickerIndex
        pinned
        qqlClassSpaceID
        interpretation
        classActivityImages {
          data {
            ID
            uRL
            status
            caption
            type
            format
            statusProcess
          }
        }
        classActivityLogs {
          data {
            ID
            action
            updatedAt
            createdAt
            remarks
            user(filter: { active: [true, false] }) {
              ID
              firstname
              lastname
              imageKey
            }
          }
        }
        classActivityParentInteractions {
          data {
            action
          }
        }
        classActivityComments {
          data {
            comment
            user {
              firstname
              lastname
            }
          }
        }
        child {
          data {
            ID
            firstname
            lastname
          }
        }
        classActivityLearningGoals {
          data {
            learningGoal {
              title
              ID
              fkLearningGoal
            }
          }
        }
        classActivityTagRelations {
          data {
            classActivityTag {
              name
              ID
            }
          }
        }
        stickerIndex
        lessonPlan {
          ID
          label
          title
          termCode
        }
      }
    }
  }
`;

export const FETCH_CHILDREN_LIST_FOR_CLASS = gql`
  query getChildrenData(
    $ftsInput: String!
    $date: Datetime!
    $pagination: Pagination
  ) {
    getChildrenData(ftsInput: $ftsInput, pagination: $pagination) {
      data {
        imageKey
        lastname
        firstname
        ID
        currentClass {
          class {
            ID
            label
          }
        }
        currentLevel {
          level {
            label
          }
        }
        childLevelAt(time: $date) {
          level {
            label
            ID
          }
          fkLevel
        }
        childClassAt(time: $date) {
          class {
            ID
            label
          }
          fkClass
        }
      }
    }
  }
`;

export const GET_ACTIVITY_IMAGE_UPLOAD_URL = gql`
  mutation getClassActivityImageUploadURL(
    $classActivityID: Int!
    $file: Upload!
  ) {
    getClassActivityImageUploadURL(
      IDClassActivity: $classActivityID
      file: $file
    ) {
      Url
      Key
      Filename
    }
  }
`;

export const ADD_CLASS_ACTIVITY_TAG = gql`
  mutation createClassActivityTag($tagName: String!) {
    createClassActivityTag(tagName: $tagName) {
      ID
      name
    }
  }
`;

export const FIND_CLASS_ACTIVITY_TAGS = gql`
  query searchClassActivityTag($tagName: String!) {
    searchClassActivityTag(tagName: $tagName) {
      totalCount
      data {
        ID
        name
      }
    }
  }
`;

export const SUBMIT_CLASS_ACTIVITY = gql`
  mutation submitClassActivity($classActivityID: Int!) {
    submitClassActivity(IDClassActivity: $classActivityID) {
      ID
      status
    }
  }
`;

export const PUBLISH_CLASS_ACTIVITY = gql`
  mutation publishClassActivity($classActivityID: Int!) {
    publishClassActivity(IDClassActivity: $classActivityID) {
      ID
      status
      type
    }
  }
`;

export const UPDATE_CLASS_ACTIVITY_STATUS = gql`
  mutation updateClassActivityStatus(
    $IDClassActivity: Int!
    $status: ClassActivityStatus!
  ) {
    updateClassActivityStatus(
      IDClassActivity: $IDClassActivity
      status: $status
    ) {
      ID
      status
    }
  }
`;

export const CREATE_OR_UPDATE_CLASS_ACTIVITY = gql`
  mutation createOrUpdateClassActivity(
    $classActivityID: Int
    $dto: ClassActivityCreateUpdateDto!
  ) {
    createOrUpdateClassActivity(IDClassActivity: $classActivityID, dto: $dto) {
      ID
      status
      classActivityImages {
        data {
          uRL
          statusProcess
        }
      }
    }
  }
`;

export const REJECT_CLASS_ACTIVITY = gql`
  mutation rejectClassActivity($classActivityID: Int!, $remarks: String!) {
    rejectClassActivity(IDClassActivity: $classActivityID, remarks: $remarks)
  }
`;

export const DELETE_CLASS_ACTIVITIES = gql`
  mutation deleteClassActivities($IDClassActivities: [Int!]!) {
    deleteClassActivities(IDClassActivities: $IDClassActivities)
  }
`;

export const FIND_ALL_STICKER = gql`
  query getAllStickers($schoolID: Int!) {
    getAllStickersByIDSchool(IDSchool: $schoolID) {
      data {
        index
        imageUrl
      }
    }
  }
`;

export const FIND_ALL_CLASS_RESOURCES = gql`
  query findAllClassResources($filter: ClassResourceFilter) {
    findAllClassResources(
      filter: $filter
      pagination: { sort: ["-pinned", "index", "-updated_at"] }
    ) {
      totalCount
      data {
        ID
        title
        pinned
        index
        classResources(
          filter: { type: "resource" }
          pagination: { sort: "index" }
        ) {
          totalCount
          data {
            ID
            title
            index
            filename
            uRL
          }
        }
      }
    }
  }
`;

export const GET_RESOURCE_FILE_UPLOAD_URL = gql`
  mutation getClassResourceFileUploadURL(
    $classResourceID: Int!
    $file: Upload!
  ) {
    getClassResourceFileUploadURL(
      IDClassResource: $classResourceID
      file: $file
    ) {
      Url
      Key
      Filename
    }
  }
`;

export const UPDATE_CLASS_RESOURCE_URL = gql`
  mutation updateClassResourceUrl(
    $classResourceID: Int!
    $url: String!
    $fileName: String!
  ) {
    updateClassResourceUrl(
      IDClassResource: $classResourceID
      url: $url
      fileName: $fileName
    ) {
      ID
      uRL
      description
      title
      filename
    }
  }
`;

export const GET_PENDING_LATEPICKUP_COUNT = gql`
  query getNumberOfLatePickupPendingForAction($centreID: Int!) {
    getNumberOfLatePickupPendingForAction(centreID: $centreID) {
      totalCount
    }
  }
`;

export const UPDATE_CHILD_SUPPORT_SCHEME = gql`
  mutation updateChildSupportScheme(
    $IDChild: Int!
    $supportScheme: String!
    $effectiveFrom: Datetime!
  ) {
    updateChildSupportScheme(
      IDChild: $IDChild
      supportScheme: $supportScheme
      effectiveFrom: $effectiveFrom
    ) {
      ID
      supportScheme
    }
  }
`;

export const GET_CHILDREN_OF_CLASS = gql`
  query getAllClassChildren($schoolID: Int!, $filter: ClassFilter) {
    getAllClassInfo(fkSchool: $schoolID, filter: $filter) {
      data {
        currentChildClasses {
          data {
            class {
              ID
            }
            child {
              ID
              firstname
              lastname
              currentLevel {
                level {
                  ID
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LEARNING_GOALS_OPTIONS_LIST_WITH_DOMAIN = gql`
  query getListDomain($classID: Int!, $version: FilterOnField) {
    getListDomain(classID: $classID) {
      totalCount
      data {
        ID
        title
        learningGoalsByClass(classID: $classID, filter: { version: $version }) {
          totalCount
          data {
            ID
            title
            fkLearningGoal
            version
            learningGoalsByClass(
              classID: $classID
              filter: { version: $version }
            ) {
              data {
                ID
                title
                description
                fkLearningGoal
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LEARNING_GOALS_OPTIONS_LIST = gql`
  query getLearningGoals($classID: Int!) {
    getLearningGoals(IDClass: $classID) {
      totalCount
      data {
        ID
        title
        fkLearningGoal
        learningGoals {
          data {
            ID
            title
            description
            fkLearningGoal
          }
        }
      }
    }
  }
`;

export const GET_MEASUREMENT_TEMPLATE = gql`
  query getMeasurementTemplate($classID: Int!, $level: Int!) {
    getMeasurementTemplate(classID: $classID, level: $level)
  }
`;

export const UPLOAD_MEASUREMENT_FILE = gql`
  mutation getClassMeasurementUploadURL(
    $classID: Int!
    $level: Int!
    $file: Upload!
  ) {
    getClassMeasurementUploadURL(
      classID: $classID
      level: $level
      file: $file
    ) {
      Url
      Key
      Filename
    }
  }
`;

export const SAVE_UPLOADED_MEASUREMENTS = gql`
  mutation saveHealthDetailsFromFile(
    $schoolID: Int!
    $classID: Int!
    $level: Int!
    $fileLoc: String!
  ) {
    saveHealthDetailsFromFile(
      schoolID: $schoolID
      classID: $classID
      level: $level
      fileLoc: $fileLoc
    )
  }
`;

export const GET_FEE_TIER_FOR_CHILD = gql`
  query getFeeTierForChild($childID: Int!) {
    getFeeTierForChild(childID: $childID) {
      ID
      label
    }
  }
`;

export const EMERGENCY_CONTACT_INFO = gql`
  query getEmergencyContact($childID: Int!, $schoolID: Int!) {
    getEmergencyContact(IDChild: $childID, IDSchool: $schoolID) {
      firstName
      lastName
      relationship
      identificationNo
      postalCode
      address
      floorNo
      unitNo
      mobilePhone
      mobilePhoneCC
      blockNo
      email
    }
  }
`;

export const ADD_EDIT_CONTACT_INFO = gql`
  mutation createOrUpdateEmergencyContact(
    $childID: Int!
    $emergencyContact: EmergencyContact!
  ) {
    createOrUpdateEmergencyContact(
      IDChild: $childID
      emergencyContact: $emergencyContact
    )
  }
`;

export const GET_CHILD_HEALTH_INFO = gql`
  query getChildHealthInfoByChildID($childId: Int!) {
    getChildHealthInfoByChildID(IDChild: $childId) {
      ...ChildHealthFragment
    }
  }
  ${CHILD_HEALTH_FRAGMENT}
`;

export const UPDATE_CHILD_HEALTH_INFO = gql`
  mutation updateChildHealthInfoByChildID(
    $childId: Int!
    $healthInfo: ChildHealthInformation!
  ) {
    updateChildHealthInfoByChildID(IDChild: $childId, healthInfo: $healthInfo) {
      ...ChildHealthFragment
    }
  }
  ${CHILD_HEALTH_FRAGMENT}
`;

export const CHECK_BANK_ACCOUNT = gql`
  mutation checkBankAccountForPreEnrolmentWithdrawal($IDChild: Int!) {
    checkBankAccountForPreEnrolmentWithdrawal(IDChild: $IDChild)
  }
`;

export const WITHDRAW_CHILD_AT_PRE_ENROLMENT = gql`
  mutation withdrawChildAtPreEnrolment(
    $IDChild: Int!
    $reason: Int!
    $otherReason: String
  ) {
    withdrawChildAtPreEnrolment(
      IDChild: $IDChild
      reason: $reason
      otherReason: $otherReason
    ) {
      ID
    }
  }
`;

export const SAVE_ENROLLED_CHILD_DOCUMENT = gql`
  mutation saveEnrolledChildDocument(
    $childID: Int!
    $parentID: Int
    $documentTypeConfigID: Int!
    $uploadedDocuments: [DocumentUpload!]!
  ) {
    saveEnrolledChildDocument(
      childID: $childID
      parentID: $parentID
      documentTypeConfigID: $documentTypeConfigID
      uploadedDocuments: $uploadedDocuments
    )
  }
`;

export const REMOVE_ENROLLED_CHILD_DOCUMENT_ITEM = gql`
  mutation removeEnrolledChildDocumentItem(
    $childID: Int!
    $parentID: Int
    $documentTypeID: Int!
    $reason: String!
  ) {
    removeEnrolledChildDocumentItem(
      childID: $childID
      parentID: $parentID
      documentTypeID: $documentTypeID
      reason: $reason
    )
  }
`;

export const REMOVE_ENROLLED_CHILD_DOCUMENT = gql`
  mutation removeEnrolledChildDocument(
    $childID: Int!
    $parentID: Int
    $documentID: Int!
    $reason: String!
  ) {
    removeEnrolledChildDocument(
      childID: $childID
      parentID: $parentID
      documentID: $documentID
      reason: $reason
    )
  }
`;

export const UPDATE_MARKETING_CONSENT = gql`
  mutation updateMarketingConsent($IDParent: Int!, $consent: Boolean!) {
    updateMarketingConsent(IDParent: $IDParent, consent: $consent)
  }
`;

export const UPDATE_AUDIO_CONSENT = gql`
  mutation updateAudioConsentForChild($IDChild: Int!, $consent: Boolean!) {
    updateAudioConsentForChild(IDChild: $IDChild, consent: $consent)
  }
`;

export const GET_APPLICABLE_FEE_TIERS_FOR_CHILD = gql`
  query getApplicableFeeTiersForChild(
    $childID: Int!
    $centreID: Int
    $date: Datetime
  ) {
    getApplicableFeeTiersForChild(
      childID: $childID
      centreID: $centreID
      date: $date
    ) {
      data {
        ID
        label
      }
    }
  }
`;

export const GET_CHILD_FEE_TIER_FOR_CHILD = gql`
  query getChildFeeTierForChild(
    $childID: Int!
    $filter: FeeTierFilter
    $date: Datetime
  ) {
    getChildFeeTierForChild(childID: $childID, filter: $filter, date: $date) {
      ID
      feeTier {
        ID
        label
      }
      from
    }
  }
`;

export const GET_UPCOMING_FEE_TIERS_FOR_CHILD = gql`
  query getUpcomingChildFeeTierForChild(
    $childID: Int!
    $filter: FeeTierFilter
    $date: Datetime
  ) {
    getUpcomingChildFeeTierForChild(
      childID: $childID
      filter: $filter
      date: $date
    ) {
      ID
      feeTier {
        ID
        label
      }
      from
    }
  }
`;

export const UPDATE_UPCOMING_FEE_TIER = gql`
  mutation updateUpcomingChildFeeTier(
    $childID: Int!
    $feeTierID: Int!
    $date: Datetime!
  ) {
    updateUpcomingChildFeeTier(
      childID: $childID
      feeTierID: $feeTierID
      date: $date
    ) {
      ID
    }
  }
`;

export const GET_LESSON_PLAN = gql`
  query getLessonPlan($levelID: Int!, $filter: LessonPlanFilter) {
    getLessonPlan(levelID: $levelID, filter: $filter) {
      data {
        ID
        label
        title
        termCode
        fkLevel
        lessonPlanLearningGoals {
          data {
            ID
            fkLearningGoal
            fkLessonPlan
            learningGoal {
              ID
              title
              fkLearningGoal
              code
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const GET_MARKETING_CONSENT_BY_PARENT = gql`
  query getMarketingConsentBusinessEntityByParentAndSchool(
    $parentID: Int!
    $schoolID: Int!
  ) {
    getMarketingConsentBusinessEntityByParentAndSchool(
      parentID: $parentID
      schoolID: $schoolID
    ) {
      ID
      phone
      sms
      email
    }
  }
`;

export const GET_ALL_CLASS_FOR_PROMOTION_CHILD = gql`
  query getAllClassInfoForChild($childID: Int!, $effectiveDate: Datetime!) {
    getAllClassInfoForChild(childID: $childID, effectiveDate: $effectiveDate) {
      totalCount
      data {
        ...ClassData
      }
    }
  }
  ${CLASS_FRAGMENT}
`;

export const CANCEL_PROMOTION = gql`
  mutation cancelPromotion($IDChild: Int!) {
    cancelPromotion(IDChild: $IDChild)
  }
`;
