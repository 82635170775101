import gql from 'graphql-tag';

// Will add more actions so disable eslint for this file temporary
/* eslint-disable import/prefer-default-export */
export const GET_ENROLLMENT_PLANS_BY_YEAR = gql`
  query getEnrollmentPlansByYear(
    $centreID: Int!
    $year: Int!
    $levelID: Int!
    $programID: Int
  ) {
    getEnrollmentPlansByYear(
      centreID: $centreID
      year: $year
      levelID: $levelID
      programID: $programID
    ) {
      Program {
        ID
        label
        fkLevel
        type
        placementOrder
      }
      TargetEnrollment
      ConsumedCapacity
      VacanciesOffered
      RegistrationsInProcess
      RegistrationsCompleted
      Enrollments
      Available
      Waitlisted
      EstPromotionIn
      EstPromotionOut
      PromotionIn
      PromotionOut
      TransferIn
      TransferOut
      Withdrawal
    }
  }
`;

export const CREATE_ENROLLMENT_PLANNING_REQUEST = gql`
  mutation createEnrollmentPlanningRequest(
    $centreID: Int!
    $levelID: Int!
    $programID: Int!
    $update: CapacityEditDTO!
  ) {
    createEnrollmentPlanningRequest(
      centreID: $centreID
      levelID: $levelID
      programID: $programID
      update: $update
    )
  }
`;

export const LIST_ENROLLMENT_PLANNING_CHANGE_REQUESTS = gql`
  query listEnrollmentPlanningChangeRequests($filter: CapacityDraftFilter) {
    listEnrollmentPlanningChangeRequests(capacityDraftFilter: $filter) {
      Draft {
        ID
        fkCentre
        fkLevel
        fkReason
        fkUser
        year
        status
        fkProgramType
        fkReason
        remark
        isRollover
        createdAt
        updatedAt
        centre {
          ID
          label
        }
        level {
          ID
          label
        }
        userByFkUser {
          ID
          firstname
          lastname
        }
        userByFkApprover {
          ID
          firstname
          lastname
        }
      }
      Changes
    }
  }
`;

export const APPROVE_ENROLLMENT_PLAN = gql`
  mutation approveEnrollmentPlanningRequest($draftID: Int!) {
    approveEnrollmentPlanningRequest(draftID: $draftID)
  }
`;

export const REJECT_ENROLLMENT_PLAN = gql`
  mutation rejectEnrollmentPlanningRequest(
    $draftID: Int!
    $rejectRemark: String!
  ) {
    rejectEnrollmentPlanningRequest(
      draftID: $draftID
      rejectRemark: $rejectRemark
    )
  }
`;
