import { withStyles } from '@material-ui/styles';
import FONT_FACES from './fontFaces';

import THEME from './index';

import { FONT } from './variables';

const globalStyle = theme => ({
  // @global is handled by jss-plugin-global.
  '@global': {
    '@font-face': FONT_FACES,
    '.MuiPickersSlideTransition-transitionContainer.MuiPickersCalendarHeader-transitionContainer': {
      order: -1,
    },
    body: {
      fontFamily: FONT.regular,
      fontSize: theme.fonts.body.fontSize,
      color: THEME.baseColor.text.default,
      letterSpacing: '0.025em',
    },
    'h1, .MuiTypography-h1': {
      fontFamily: THEME.fonts.h1.fontFamily,
      fontSize: THEME.fonts.h1.fontSize,
      color: THEME.fonts.h1.color,
      lineHeight: 2,

      '@media screen and (max-width: 600px)': {
        fontSize: THEME.fonts.h1.fontSizeMobile,
        lineHeight: '25px',
      },
    },

    'h2, .MuiTypography-h2': {
      fontFamily: THEME.fonts.h2.fontFamily,
      fontSize: THEME.fonts.h2.fontSize,
      color: THEME.fonts.h2.color,
      lineHeight: 2,
    },

    'h3, .MuiTypography-h3': {
      fontFamily: THEME.fonts.h3.fontFamily,
      fontSize: THEME.fonts.h3.fontSize,
      color: THEME.fonts.h3.color,
      lineHeight: 1.75,
    },

    'h4, .MuiTypography-h4': {
      fontFamily: THEME.fonts.h4.fontFamily,
      color: THEME.fonts.h4.color,
      fontSize: THEME.fonts.h4.fontSize,
    },
    strong: {
      fontFamily: FONT.bold,
    },
    '.box-shadow-none': {
      boxShadow: 'none',
    },
    '.s7t-text-capitalize': {
      textTransform: 'capitalize',
    },
    '.s7t-padding-bottom-4px': {
      paddingBottom: '4px',
    },
    'table a.MuiTableRow-root': {
      textDecoration: 'none',
    },

    '.sn2-text-right': {
      textAlign: 'right !important',
    },

    '.sn2-float-right': {
      float: 'right !important',
    },

    '.sn2-align-baseline': {
      alignItems: 'baseline !important',
    },

    '.s7t-dark-yellow': {
      color: THEME.baseColor.neutral.darkYellow,
      fontFamily: THEME.fonts.h2.fontFamily,
      fontSize: THEME.fonts.body.fontSize,
    },

    '.s7t-position-icon': {
      width: '24px',
      height: '24px',
      position: 'relative',
      top: '3px',
    },
    '.s7t-stepper-label': {
      float: 'left',
      whiteSpace: 'pre',
      marginTop: '10px',
    },

    '.sn2-dot': {
      padding: '6px',
      margin: '0 6px',
      backgroundColor: 'red',
      borderRadius: '50%',
      display: 'inline-block',
    },

    '.sn2-hide-input': {
      display: 'none',
    },

    // Calender styles

    '.rbc-time-header': {
      overflowY: 'scroll',
      maxHeight: '30vh',
      marginRight: '0 !important',
    },
    '.rbc-overlay': {
      maxHeight: '40vh',
      overflowY: 'scroll',
    },

    '.s7t-class-container': {
      '.s7t-class-tags': {
        boxShadow: '0px 0px 0px 0px rgba(0, 0, 0, 0.2) !important',
      },
      '.s7t-class-appbar': {
        boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.2) !important',
      },
      '.s7t-class-iconstyle': {
        padding: '15px 0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      },
      '.s7t-class-fonttab': {
        fontFamily: FONT.regular,
        fontWeight: 500,
        fontSize: THEME.fonts.body.fontSize,
        color: THEME.baseColor.text.label,
      },

      '.s7t-table-style': {
        boxShadow: 'none',
        borderRadius: 0,
      },

      '.s7t-table-style thead': {
        whiteSpace: 'pre',
      },

      '.s7t-class-attendance-container': {
        backgroundColor: THEME.baseColor.neutral.white,
      },
    },

    '.content-wrapper': {
      padding: '25px',
      backgroundColor: THEME.baseColor.neutral.white,
      borderRadius: '4px',
    },

    '.sn2-decalaration-box': {
      background: THEME.baseColor.neutral.lightGrey,
    },

    '.sn2-fixed-grid': {
      position: 'absolute',
      right: '12px',
      width: '41%',
    },
    '.sn2-cursor': {
      cursor: 'pointer',
    },

    '.sn2-list-hover': {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '.sn2-buttonHover': {
      '&:hover': {
        backgroundColor: THEME.baseColor.neutral.main,
      },
    },

    '.online-dot': {
      height: '10px',
      width: '10px',
      backgroundColor: 'green',
      borderRadius: '50%',
      marginRight: '5px',
      display: 'inline-block',
    },

    '.sn2-text-inline': {
      wordBreak: 'normal',
    },
    '.sn2-overflow-hidden': {
      overflow: 'hidden !important',
    },

    '.sn2-appbar-shadow': {
      boxShadow: `0 2px 0 0 ${THEME.baseColor.text.placeholder}`,
    },
    '.sn2-formHelpherStyle': {
      fontSize: '14px',
    },
    '.sn2-yellowText': {
      background: 'yellow',
    },
    '.sn2-formControlLabel': {
      alignItems: 'flex-start',
    },
    '.sn2-checkbox': {
      padding: '1px 6px',
    },
    '.sn2-table0padding': {
      padding: '0px',
    },
    '.sn2-flex-column': {
      display: 'flex',
      flexdirection: 'column',
    },
    '.sn2-videoViewer': {
      minHeight: '60vh',
      maxHeight: '70vh',
    },

    '.sn2-enrichment-parent-class-info': {
      '.sn2-program-details': {
        '.sn2-image': {
          width: 'auto',
          maxHeight: '230px',
          boxShadow: '4px 4px darkgrey',
          borderRadius: '15px',
        },
      },
      '.sn2-tab-change-style': {
        boxShadow: 'none',
        borderBottom: '1px solid lightgrey',
      },
      '.sn2-full-height': {
        height: '100%',
      },
      '.box-shadow-none': {
        boxShadow: 'none',
      },
      '.sn2-min-height': {
        minHeight: '80vh',
      },
    },

    '.sn2-flex-end': {
      justifyContent: 'flex-end !important',
    },

    '.sn2-enrichment-created-date': {
      position: 'absolute',
      zIndex: 10,
      right: '80px',
      top: '290px',
    },

    '.sn2-marginTop-negative-16-px': {
      marginTop: '-16px',
    },

    '.sn2-no-post-message': {
      position: 'relative',
      left: '125px',
    },

    '.sn2-text-left': {
      textAlign: 'left',
    },

    '.sn2-universal-alert': {
      position: 'fixed',
      width: '85%',
      left: '10%',
      top: '15%',
      zIndex: 10000,
    },
    '.sn2-universal-toast': {
      position: 'fixed',
      width: '85%',
      left: '10%',
      bottom: '0%',
      zIndex: 10000,
    },

    '.sn2-min-height-40': {
      minHeight: '40px',
    },

    '.sn2-line-height-35': {
      lineHeight: '35px',
    },

    '.sn2-marginTop-bottom': {
      margin: '50px 0',
    },

    '.sn2-margin-bottom-20px': {
      marginBottom: '20px',
    },
    '.sn2-common-table-header-padding': {
      padding: '4px 0px 4px 16px !important',
    },
    '.sn2-icon-btn-margin-right': {
      marginRight: '10px',
    },
    '.s7t-space-evently': {
      'div > span': {
        height: '4px',
        borderTopLeftTadius: '2px',
        borderTopRightRadius: '2px',
        borderBottomLeftRadius: '2px',
        borderBottomRightRadius: '2px',
      },
    },
    '.sn2-backdrop': {
      zIndex: 100,
      color: '#fff',
    },
    '.sn2-download-backdrop': {
      zIndex: 100,
      color: theme.palette.primary.main,
    },
    '.sn2-drop-down-list': {
      width: '20px',
      marginRight: '5px',
    },
    '.sn2-dropdown-list': {
      justifyContent: 'center',
      paddingVertical: '10px',
    },
    '.sn2-popper-list': {
      position: 'relative',
      top: '-50px',
    },
    '.sn2-center-alignment': {
      justifyContent: 'center',
    },
    '.sn2-class-img-selector': {
      position: 'relative',
      top: '0px',
      height: '200px',
    },
    '.sn2-icon-class': {
      position: 'relative',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      backgroundColor: '#ffffff',
    },
    '.background-transparent': {
      backgroundColor: 'transparent !important',
    },
    '.sn2-popup-scroll-container': {
      maxHeight: '300px',
      overflow: 'auto',
    },
    '.sn2-padding-large': {
      padding: '0px 24px !important',
    },
    '.sn2-padding-right-large': {
      padding: '0px 50px !important',
    },
    '.sn2-border-radius': {
      borderRadius: '8px',
    },
    '.sn2-lato-bold': {
      fontFamily: 'Lato-Bold',
    },
    '.sn2-color-card-mobile': {
      backgroundColor: '#FAF7F3',
      padding: 10,
    },
    '.font-24': {
      fontSize: '24px !important',
    },
    '.modal-footer-container': {
      padding: '35px 8px 16px 8px !important',
      position: 'relative',
    },
    '.sn2-feeContentWrapper': {
      padding: '25px',
      borderRadius: '8px',
    },
  },
});
const GlobalCss = withStyles(globalStyle)(() => null);

export default GlobalCss;
