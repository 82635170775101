import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { get, isEqual } from 'lodash';
import Cookies from 'js-cookie';
import { fetchUserDetails } from '../redux/actions';
import { EP_EXCHANGE_TOKEN, LOGIN } from '../routes/constants';
import localStore from '../utils/localStorage';
import DatadogHandler from '../utils/datadog';
import { defineAbilityFor } from '../utils/ability/index';
import { AbilityContext } from '../utils/ability/abilityContext';
import { VENDOR_ADMIN, VENDOR_TRAINER } from '../utils/constants';

class GetUser extends Component {
  componentDidMount() {
    const { fetchUserDetailsData, userDetailsData, fetchUser } = this.props;

    if (
      fetchUser &&
      userDetailsData &&
      typeof userDetailsData === 'object' &&
      !Object.keys(userDetailsData).length
    ) {
      fetchUserDetailsData();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, userDetailsData } = this.props;

    if (
      location.pathname !== prevProps.location.pathname &&
      userDetailsData.data
    ) {
      DatadogHandler.setUserContextOnRouteChange(
        location.pathname,
        userDetailsData.data
      );
    }
  }

  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(newProps) {
    const { userDetailsData } = this.props;
    try {
      const existFreshChatData = await window.fcWidget.user.isExists();
      if (get(existFreshChatData, 'data')) {
        const freshChatUserRes = await window.fcWidget.user.get();
        if (
          window.fcWidget &&
          !isEqual(newProps.userDetailsData, userDetailsData) &&
          newProps.userDetailsData.data &&
          get(freshChatUserRes, 'data.firstName') !==
            get(newProps.userDetailsData, 'data.firstname')
        ) {
          const {
            firstname,
            lastname,
            email,
            mobilePhoneCountryCode,
            mobilePhone,
          } = newProps.userDetailsData.data;
          // set freshchat user
          window.fcWidget.user.setProperties({
            firstName: firstname,
            lastName: lastname,
            email,
            phoneCountry: mobilePhoneCountryCode,
            phone: mobilePhone,
          });
        }
      }
    } catch (ex) {
      console.error('error while setting freshchat user', ex);
    }
  }

  render() {
    const {
      WrappedComponent,
      currentProps,
      userDetailsData,
      location,
    } = this.props;
    let userRole = null;
    let isPrincipal = false;
    let DispComp = WrappedComponent.default;
    const epCookie = Cookies.get('epUserToken');

    if (
      !userDetailsData ||
      !Object.keys(userDetailsData).length ||
      (userDetailsData && userDetailsData.inProgress)
    ) {
      return <Fragment />;
    }

    const role = get(userDetailsData, 'data.userRoleRelations.data[0].role');
    const isHQStaff = get(userDetailsData, 'data.isHQStaff');
    const hasCentreAccess = get(userDetailsData, 'data.hasCentreScopeAccess');
    if (role) {
      userRole = role.isStaff ? 'staff' : 'user';
      isPrincipal = isHQStaff || hasCentreAccess;

      if (role.label === VENDOR_ADMIN) {
        userRole = 'vendor';
      } else if (role.label === VENDOR_TRAINER) {
        userRole = 'trainer';
      } else if (isPrincipal) {
        userRole = 'principal';
      }
    }

    const fkSchool = get(
      userDetailsData,
      'data.userAccessControls.data[0].school.ID'
    );
    const uriComponent = encodeURIComponent(
      `${get(location, 'pathname')}${get(location, 'search')}`
    );

    if (
      get(location, 'pathname').includes('enrichment') &&
      epCookie &&
      (!fkSchool || !userRole)
    ) {
      return <Redirect to={`${EP_EXCHANGE_TOKEN}?redirect=${uriComponent}`} />;
    }

    if (!fkSchool || !userRole) {
      localStore.setValue('userToken', null);
      localStore.setValue('selectedChild', null);
      localStore.setValue('themeData', null);
      return <Redirect to={LOGIN} />;
    }

    if (userRole && WrappedComponent[userRole]) {
      DispComp = WrappedComponent[userRole] || WrappedComponent.default;
    }

    const newProps = Object.assign({}, currentProps, {
      userDetails: userDetailsData.data,
      fkSchool,
      pathname: location.pathname,
    });

    return (
      <AbilityContext.Provider
        value={defineAbilityFor(
          get(userDetailsData, 'data.userRoleRelations.data[0].role')
        )}
      >
        <DispComp {...newProps} />
      </AbilityContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  userDetailsData: get(state, 'userDetails'),
  schoolID: get(state, 'schoolID.data'),
});

const mapDispatchToProps = {
  fetchUserDetailsData: fetchUserDetails,
};

const WithUserDetails = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GetUser)
);

const withUser = (wrappedComponent, props = null, fetchUser = false) => (
  <WithUserDetails
    WrappedComponent={wrappedComponent}
    currentProps={props}
    fetchUser={fetchUser}
  />
);

export default withUser;
