/* eslint-disable no-console */
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import get from 'lodash/get';

const SCHOOL_ID = process.env.REACT_APP_SCHOOL_ID;
const checkList = ['password'];

const maskInfo = (key, value) => {
  let maskedValue = value;
  if (checkList.indexOf(key) > -1) {
    maskedValue = '[REDACTED]';
  }
  return maskedValue;
};

export default class DatadogHandler {
  static init() {
    this.initDatadog();
  }

  static initDatadog() {
    // only initialise Datadog on dev, staging, and production
    if (process.env.REACT_APP_ENVIRONMENT) {
      datadogLogs.init({
        clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
        site: 'datadoghq.com',
        forwardErrorsToLogs: true,
        isCollectingError: true,
        silentMultipleInit: true,
        sampleRate: 100,
        trackInteractions: true,
        useCrossSiteSessionCookie: true,
        useSecureSessionCookie: true,
        trackSessionAcrossSubdomains: true,
        env: process.env.REACT_APP_DEPLOY_ENV,
        version: process.env.REACT_APP_BITBUCKET_BRANCH,
        beforeSend: log => {
          if (
            log.origin === 'console' &&
            log.message.includes('console error:')
          ) {
            return false;
          }
        },
      });

      datadogRum.init({
        applicationId: process.env.REACT_APP_DD_APP_ID,
        clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN,
        site: 'datadoghq.com',
        //  service: 'my-web-application',
        env: process.env.REACT_APP_DEPLOY_ENV,
        version: process.env.REACT_APP_BITBUCKET_BRANCH,
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
        beforeSend: (event, context) => {
          if (
            event.type === 'error' &&
            event.error.message.includes('console error:')
          ) {
            return false;
          }
          // collect a RUM resource's response headers
          if (event.type === 'resource' && event.resource.type === 'fetch') {
            event.context = {
              ...event.context,
              responseHeaders: context.response.headers,
            };
          }
          // remove email from view url
          event.view.url = event.view.url.replace(
            /email=[^&]*/,
            'email=REDACTED'
          );
        },
      });
    }
  }

  static setUserContextOnRouteChange(route, userDetails) {
    const extra = {
      userRole: get(userDetails, 'userRoleRelations.data[0].role.label'),
      schoolID: SCHOOL_ID,
    };
    this.setUserContext(userDetails.ID, extra);
  }

  static setUserContext(userId, extra) {
    datadogLogs.setGlobalContext({
      id: userId,
      ...extra,
    });
  }

  static sendLog(message, context, type) {
    const maskedData = JSON.stringify(context, maskInfo);
    datadogLogs.logger.log(message, JSON.parse(maskedData), type);
  }
}
