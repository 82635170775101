import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  TextField,
  InputAdornment,
  CircularProgress,
  Popper,
} from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import FieldWrapper from './FieldWrapper';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiAutocomplete-listbox': {
      '& li': {
        backgroundColor: theme.baseColor.neutral.white,
      },
      '& :hover': {
        backgroundColor: theme.baseColor.filter.tab,
        color: theme.baseColor.neutral.white,
      },
    },
  },
}));

const CustomPopper = props => {
  const classes = useStyles();
  return (
    <Popper
      {...props}
      style={{
        width: 'fit-content',
      }}
      className={classes.root}
      placement="bottom"
    />
  );
};

const commonLabelStyle = {
  marginBottom: '-19px',
};
export const AutoCompleteField = ({
  options,
  onChange,
  label,
  name,
  error,
  labelStyle = commonLabelStyle,
  disabled,
  labelTextColor,
  required,
  value,
  searchVal,
  handleSearchChange,
  showLoader,
  autoHighlight,
  defaultValue,
  textFieldStyles,
}) => (
  <Autocomplete
    options={options}
    value={value}
    onChange={onChange}
    getOptionLabel={option => option.description}
    PopperComponent={CustomPopper}
    autoHighlight={autoHighlight}
    defaultValue={defaultValue}
    renderInput={params => {
      return (
        <FieldWrapper
          required={required}
          label={label}
          name={name}
          error={error}
          styles={labelStyle}
          disabled={disabled}
          labelTextColor={labelTextColor}
        >
          {showLoader ? (
            <TextField
              name={name}
              value={searchVal}
              onChange={handleSearchChange}
              {...params}
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CircularProgress disableShrink size={20} />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <TextField
              name={name}
              value={searchVal}
              onChange={handleSearchChange}
              {...params}
              margin="normal"
              style={textFieldStyles}
            />
          )}
        </FieldWrapper>
      );
    }}
  />
);
export default withTheme(AutoCompleteField);
